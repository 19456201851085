import React, { useState } from "react";
import Chart from "react-apexcharts";
import { apexBar, apexBar2, apexPie } from "../ApexOptions";

import "./Dash1.css";

function Dash1() {
  const [active, setActive] = useState(false);
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);
  const [active6, setActive6] = useState(false);
  const [active7, setActive7] = useState(false);
  const [active8, setActive8] = useState(false);
  const [active9, setActive9] = useState(false);
  const [active10, setActive10] = useState(false);
  const [active11, setActive11] = useState(false);
  const [active12, setActive12] = useState(false);
  const [active13, setActive13] = useState(false);
  const [active14, setActive14] = useState(false);

  const handleClick = (id) => {
    const click = document.getElementById("add_tl_data");
    const add_click = document.getElementById("add_tl");
    if (click.style.display === "none") {
      click.style.display = "block";
      add_click.style.display = "none";
    }
  };
  const handleClick1 = () => {
    const click = document.getElementById("add_tl_data");
    const add_click = document.getElementById("add_tl");
    if (add_click.style.display === "none") {
      add_click.style.display = "flex";
      click.style.display = "none";
    }
  };

  const handleClick2 = () => {
    const click = document.getElementById("add_tl_total");
    const add_click_but = document.getElementById("add_tl_buttons");
    const add_tl_data_click = document.getElementById("add_tl_data");
    const add_click = document.getElementById("add_tl");
    const up_tr_click = document.getElementById("update_traine_data");

    if (click.style.display === "none") {
      add_click.style.display = "none";
      add_click_but.style.display = "none";
      up_tr_click.style.display = "none";
      click.style.display = "block";
      add_tl_data_click.style.display = "block";
    }
  };
  const handleClick3 = () => {
    setActive(true);
    setActive11(true);
    setActive6(false)

    const add_t_pop_click = document.getElementById("set_trainer_pop");
    const add_pop_click = document.getElementById("add_tl_pop");
    const my_team_click = document.getElementById("my_team_data");
    const up_tr_click = document.getElementById("update_traine_data");
    const click = document.getElementById("add_tl_data");
    const add_click = document.getElementById("add_tl");
    const add_t_click = document.getElementById("add_tl_total");
    const up_tr1_click = document.getElementById("update_training");

    if (add_t_pop_click.style.display === "flex") {
      add_t_pop_click.style.display = "none";
      add_pop_click.style.display = "none";
      my_team_click.style.display = "block";
      add_click.style.display = "flex";
      click.style.display = "none ";
      add_t_click.style.display = "none ";
    }
    if (active === false) {
      up_tr1_click.style.display = "none ";
      up_tr_click.style.display = "block";
    }
  };
  // const handleClick3 = () => {
  //   setActive(true);
  //   const click = document.getElementById("add_tl_data");
  //   const add_click = document.getElementById("add_tl");
  //   const add_tl_click = document.getElementById("add_tl_total");
  //   const update_click = document.getElementById("update_trainer");
  //   const up_tr_click = document.getElementById("update_traine_data");
  //   const up_tr1_click = document.getElementById("update_training");
  //   const update_t_total_click = document.getElementById("update_trainer");

  //   if (up_tr_click.style.display === "none") {
  //     click.style.display = "none";
  //     add_tl_click.style.display = "none";
  //     add_click.style.display = "flex";
  //     update_click.style.display = "block";
  //     up_tr_click.style.display = "block";
  //     update_t_total_click.style.display = "none";
  //     up_tr1_click.style.display = "none";
  //   }
  // };

  const handleClick4 = () => {
    setActive1(!active1);

    const click = document.getElementById("setup_traine_data");
    const update_click = document.getElementById("update_trainer");
    const up_tr_click = document.getElementById("update_traine_data");
    const up_tr1_click = document.getElementById("update_training");
    const setup_click = document.getElementById("setup_training");

    if (click.style.display === "none") {
      click.style.display = "block";
      update_click.style.display = "none";
      up_tr_click.style.display = "none";
      up_tr1_click.style.display = "flex";
      setup_click.style.display = "none";
    }
  };
  const handleClick5 = () => {
    const set_click = document.getElementById("setup_trainer_total");
    const set_prev_click = document.getElementById("setup_preview_total");

    if (set_click.style.display === "block") {
      set_click.style.display = "none";
      set_prev_click.style.display = "block";
    }
  };
  const handleClick6 = () => {
    setActive2(!active2);

    const set_prev_click = document.getElementById("setup_preview_total");
    const setup_click = document.getElementById("setup_training");
    const set_data_click = document.getElementById("setup_traine_data");
    const launch_p_click = document.getElementById("launch_project");
    const launch_p_d_click = document.getElementById("launch_project_data");

    if (set_prev_click.style.display === "block") {
      set_prev_click.style.display = "none";
      setup_click.style.display = "flex";
      set_data_click.style.display = "none";
      launch_p_click.style.display = "none";
      launch_p_d_click.style.display = "block";
    }
  };

  // const handleClick7 = () => {
  //   setActive4(true);
  //   const update_t_data_click = document.getElementById("update_traine_data");
  //   const update_t_click = document.getElementById("update_training");
  //   const setup_t_data_click = document.getElementById("setup_traine_data");
  //   const setup_click = document.getElementById("setup_training");

  //   if (update_t_data_click.style.display === "block") {
  //     update_t_data_click.style.display = "none";
  //     setup_click.style.display = "none";
  //     update_t_click.style.display = "flex";
  //     setup_t_data_click.style.display = "block";
  //   }
  // };
  const handleClick8 = () => {
    setActive11(false);

    const update_click = document.getElementById("update_trainer");
    const update_but_click = document.getElementById("update_buttons");
    const my_team_click = document.getElementById("my_team_data");

    if (update_click.style.display === "none") {
      update_but_click.style.display = "none";
      my_team_click.style.display = "none";
      update_click.style.display = "block";
    }
  };
  const handleClick9 = () => {
    const launch_p_t_click = document.getElementById("launch_project_total");
    const up_t_next_click = document.getElementById(
      "update_tainer_next_buttons"
    );
    const launch_p_click = document.getElementById("launch_project");

    if (launch_p_t_click.style.display === "none") {
      launch_p_t_click.style.display = "block";
      up_t_next_click.style.display = "none";
      launch_p_click.style.display = "none";
    }
  };

  // const handleClick10 = () => {
  //   setActive5(true);
  //   const set_t_d_click = document.getElementById("setup_traine_data");
  //   const setup_click = document.getElementById("setup_training");
  //   const launch_p_click = document.getElementById("launch_project");

  //   if (set_t_d_click.style.display === "block") {
  //     set_t_d_click.style.display = "none";
  //     launch_p_click.style.display = "flex";
  //     setup_click.style.display = "flex";
  //   }
  // };

  const handleClick11 = () => {
    const set_but_click = document.getElementById("set_train_buttons");
    const set_total_click = document.getElementById("setup_trainer_total");

    if (set_total_click.style.display === "none") {
      set_but_click.style.display = "none";
      set_total_click.style.display = "block";
    }
  };
  const handleClick12 = () => {
    const run_p_click = document.getElementById("run_payroll");
    const run_p_d_click = document.getElementById("run_payroll_data");

    if (run_p_d_click.style.display === "none") {
      run_p_d_click.style.display = "block";
      run_p_click.style.display = "none";
    }
  };
  const handleClick21 = () => {
    const run_p_t_click = document.getElementById("run_payroll_total");
    const up_t_next_click = document.getElementById(
      "update_tainer_next_buttons"
    );
    const run_payroll_d_click = document.getElementById("run_payroll_data");
    const run_p_click = document.getElementById("run_payroll");

    if (run_p_t_click.style.display === "none") {
      run_p_t_click.style.display = "block";
      up_t_next_click.style.display = "none";
      run_p_click.style.display = "none";
      run_payroll_d_click.style.display = "block";
    }
  };
  const handleClick22 = () => {
    const run_p_click = document.getElementById("run_payroll");
    const run_p_d_click = document.getElementById("run_payroll_data");

    if (run_p_d_click.style.display === "block") {
      run_p_d_click.style.display = "none";
      run_p_click.style.display = "flex";
    }
  };

  const handleClick13 = () => {
    setActive3(!active3);

    const launch_p_click = document.getElementById("launch_project");
    const launch_p_d_click = document.getElementById("launch_project_data");
    const launch_p_t_click = document.getElementById("launch_project_total");

    if (launch_p_click.style.display === "none") {
      launch_p_click.style.display = "flex";
      launch_p_d_click.style.display = "none";
      launch_p_t_click.style.display = "none";
    }
  };
  const handleClick14 = () => {
    setActive8(!active8);

    const run_p_click = document.getElementById("run_payroll");
    const run_p_t_click = document.getElementById("run_payroll_total");
    const run_p_d_click = document.getElementById("run_payroll_data");
    const upload_r_click = document.getElementById("upload_report");
    const upload_r_d_click = document.getElementById("upload_report_data");
    const upload_r_t_click = document.getElementById("upload_report_total");

    if (upload_r_d_click.style.display === "none") {
      run_p_t_click.style.display = "none";
      run_p_d_click.style.display = "none";
      upload_r_t_click.style.display = "block";
      upload_r_click.style.display = "none";
      upload_r_d_click.style.display = "block";
      run_p_click.style.display = "flex";
    }
  };

  const handleClick15 = () => {
    setActive9(!active9);

    const upload_r_click = document.getElementById("upload_report");
    const upload_r_d_click = document.getElementById("upload_report_data");
    const upload_r_t_click = document.getElementById("upload_report_total");

    if (upload_r_t_click.style.display === "block") {
      upload_r_t_click.style.display = "none";
      upload_r_click.style.display = "flex";
      upload_r_d_click.style.display = "none";
    }
  };

  const handleClick16 = () => {
    setActive10(!active10);
    setActive11(false);
    setActive12(false);
    setActive14(false);

    const project_detail_click = document.getElementById("project_detail_card");
    const my_statics_click = document.getElementById("my_statics_data");
    const setting_data_click = document.getElementById("setting_data");
    const my_team_click = document.getElementById("my_team_data");

    if (project_detail_click.style.display === "none") {
      project_detail_click.style.display = "block";
      my_statics_click.style.display = "none";
      setting_data_click.style.display = "none";

      my_team_click.style.display = "none";
    } else {
      project_detail_click.style.display = "none";
    }
  };
  const handleClick17 = () => {
    setActive11(!active11);
    setActive10(false);
    setActive14(false);
    setActive12(false);

    const my_team_click = document.getElementById("my_team_data");
    const my_statics_click = document.getElementById("my_statics_data");
    const setting_data_click = document.getElementById("setting_data");
    const project_detail_click = document.getElementById("project_detail_card");
    const add_click = document.getElementById("add_tl");
    const add_t_data_click = document.getElementById("add_tl_data");
    const up_tr_click = document.getElementById("update_traine_data");
    const up_tr1_click = document.getElementById("update_training");

    if (my_team_click.style.display === "none") {
      my_team_click.style.display = "block";
      my_statics_click.style.display = "none";
      project_detail_click.style.display = "none";
      setting_data_click.style.display = "none";
      add_t_data_click.style.display = "none";
      up_tr_click.style.display = "none";
      add_click.style.display = "flex";
      up_tr1_click.style.display = "flex";
    } else {
      my_team_click.style.display = "none";
    }
  };
  const handleClick18 = () => {
    setActive12(!active12);
    setActive11(false);
    setActive14(false);
    setActive10(false);

    const my_statics_click = document.getElementById("my_statics_data");
    const my_team_click = document.getElementById("my_team_data");
    const setting_data_click = document.getElementById("setting_data");
    const project_detail_click = document.getElementById("project_detail_card");

    if (my_statics_click.style.display === "none") {
      my_statics_click.style.display = "block";
      my_team_click.style.display = "none";
      project_detail_click.style.display = "none";
      setting_data_click.style.display = "none";
    } else {
      my_statics_click.style.display = "none";
    }
  };

  const handleClick19 = () => {
    setActive13(!active13);

    const my_t_c_click = document.getElementById("my_t_c_row2");

    if (my_t_c_click.style.display === "none") {
      my_t_c_click.style.display = "block";
    } else {
      my_t_c_click.style.display = "none";
    }
  };
  const handleClick20 = () => {
    setActive14(!active14);
    setActive11(false);
    setActive12(false);
    setActive10(false);

    const my_statics_click = document.getElementById("my_statics_data");
    const my_team_click = document.getElementById("my_team_data");
    const project_detail_click = document.getElementById("project_detail_card");
    const setting_data_click = document.getElementById("setting_data");

    if (setting_data_click.style.display === "none") {
      setting_data_click.style.display = "block";
      my_statics_click.style.display = "none";
      my_team_click.style.display = "none";
      project_detail_click.style.display = "none";
    } else {
      setting_data_click.style.display = "none";
    }
  };

  const handleClick23 = () => {
    const add_t_pop_click = document.getElementById("add_tl_pop");

    if (add_t_pop_click.style.display === "none") {
      add_t_pop_click.style.display = "flex";
    }
  };

  const handleClick24 = () => {
    const add_t_pop_click = document.getElementById("set_trainer_pop");
    const add_pop_click = document.getElementById("add_tl_pop");
    add_pop_click.style.display = "none";

    if (add_t_pop_click.style.display === "none") {
      add_t_pop_click.style.display = "flex";
    }
  };
  const handleClick25 = () => {
    const add_t_pop_click = document.getElementById("add_tl_pop");

    if (add_t_pop_click.style.display === "flex") {
      add_t_pop_click.style.display = "none";
    }
  };

  const handleClick26 = () => {
    setActive6(true);
    setActive(true)
    const add_t_pop_click = document.getElementById("set_trainer_pop");
    const click = document.getElementById("add_tl_total");
    const add_tl_data_click = document.getElementById("add_tl_data");
    const add_click = document.getElementById("add_tl");



    if (add_t_pop_click.style.display === "flex") {
      add_t_pop_click.style.display = "none";
      click.style.display = "none";
      add_tl_data_click.style.display = "none";
      add_click.style.display = "flex";
    }
  };
  const handleClick27 = () => {
    setActive7(!active7);
    const location_click = document.getElementById("location_card");

    if (location_click.style.display === "none") {
      location_click.style.display = "block";
    } else {
      location_click.style.display = "none";
    }
  };
  const handleClick28 = () => {
    setActive7(!active7);
    const dots_click = document.getElementById("dots_card");

    if (dots_click.style.display === "none") {
      dots_click.style.display = "block";
    } else {
      dots_click.style.display = "none";
    }
  };
  const handleClick29 = () => {
    const add_t_pop_click = document.getElementById("set_trainer_pop");
    const my_team_click = document.getElementById("my_team_data");
    const dots_click = document.getElementById("dots_card");



    if (active6) {
      add_t_pop_click.style.display = "flex";
    } else {
      my_team_click.style.display = "block";
      dots_click.style.display = "none";
    }
  };

  return (
    <div className="dashboard1">
      <div className="dash_row1">
        <div className="dash_width">
          <div className="profile_box">
            <div className="pro_col3">
              <img
                src={window.location.origin + "/images/arrow back.svg"}
                alt="profile"
                className="back_but"
              />
              <p className="back">back</p>
            </div>
          </div>
          <div className="dash1_head">
            <h1 className="dash1_head_name">my projects</h1>
          </div>
          <div className="pro_col1">
            <img
              src={window.location.origin + "/images/profile image.svg"}
              alt="profile"
              className="dash_profile"
            />
            <div className="pro_col2">
              <h1 className="pro_name">Abhijith m</h1>
              <p className="d1_p1">project associate</p>
            </div>
          </div>
        </div>
      </div>

      <div className="dash1_row2">
        <div className="add_tl_pop" id="add_tl_pop" style={{ display: "none" }}>
          <div className="add_pop_card">
            <div className="close_row">
              <img
                src={window.location.origin + "/images/CROSS mark.svg"}
                alt="profile"
                onClick={() => handleClick25()}
                className="close_but"
              />
            </div>
            <p className="add_pop_p1">Add Team Lead</p>
            <p className="add_pop_p2">
              Are you sure you want to add team lead for project JioMart
              project?
            </p>
            <p className="add_pop_p3">Abhijit M</p>
            <div className="add_pop_row1">
              <input
                type="button"
                value="Add TL"
                className="up_t_save_button"
                onClick={() => handleClick24()}
              />
            </div>
          </div>
        </div>
        <div
          className="add_tl_pop"
          id="set_trainer_pop"
          style={{ display: "none" }}
        >
          <div className="add_pop_card">
            <p className="add_pop_p1" style={{ marginTop: "0" }}>
              Setup Training updates
            </p>
            <div className="set_date_inner_card">
              <p className="set_date_p1">Update date and time for trainer</p>
              <div className="set_date_row2">
                <div className="set_date_inner_card2">
                  <p className="set_date_p2">Enter Date</p>
                  <input
                    type="text"
                    name="date"
                    id="date"
                    className="set_date_d"
                    placeholder="DD/MM/YYYY"
                  />
                </div>
                <div className="set_date_inner_card2">
                  <p className="set_date_p2">Enter Time</p>
                  <input
                    type="text"
                    name="date"
                    id="date"
                    className="set_date_d"
                    placeholder="00:00 am"
                  />
                </div>
              </div>
            </div>{!active6 ?
            <div className="add_pop_row2">
              <div className="buttons1" onClick={() => handleClick26()}>
                Not now
              </div>
              <div className="buttons2" onClick={() => handleClick3()}>
                Request for trainer
              </div>
            </div>:
            <div className="add_pop_row2">
              <div className="buttons2" onClick={() => handleClick3()}>
                Update
              </div>
            </div>}
          </div>
        </div>
        <div className="d1_col1">
          <div className="d1_card">
            <div className="d1_row1">
              <div className="d1_col5">
                <div className="d1_col6">
                  <img
                    src={window.location.origin + "/images/jio logo.svg"}
                    alt="profile"
                    className="company_pro"
                  />
                </div>
                <div className="d1_col7">
                  <div className="d1_row11">
                    <p className="d1_p3">company name:</p>
                    <p className="d1_p2">Jio</p>
                  </div>
                  <div className="d1_row11">
                    <p className="d1_p3">project name:</p>
                    <p className="d1_p2">Jio-Mart</p>
                  </div>
                </div>
              </div>
              <div className="d1_col3">
                <div className="d_date">
                  <p className="d1_p3">Start date:</p>
                  <p className="d1_p2">02/05/2021</p>
                </div>
                <img
                  src={window.location.origin + "/images/horizonal line.svg"}
                  alt="profile"
                  className="hr_line"
                />
                <div className="d_date1">
                  <p className="d1_p3">End date:</p>
                  <p className="d1_p2">02/06/2021</p>
                </div>
              </div>
              <div className="d1_col5">
                <div className="d1_col3">
                  <div className="d_date1">
                    <p className="d1_p3">SOW id:</p>
                    <p className="d1_p2">123456782</p>
                  </div>
                </div>
                <div className="d1_col8">
                  <p className="d1_p4">
                    Bangalore
                    {/* onClick={() => handleClick27()}
                    {active7 ? (
                      <img
                        src={
                          window.location.origin + "/images/arrow_drop_UP.svg"
                        }
                        alt="drop down"
                        className="drop_down"
                      />
                    ) : (
                      <img
                        src={
                          window.location.origin + "/images/arrow_drop_down.svg"
                        }
                        alt="drop down"
                        className="drop_down"
                      />
                    )} */}
                  </p>
                  <div
                    className="location_card"
                    id="location_card"
                    style={{ display: "none" }}
                  >
                    <p className="d1_p5">Hyderbad</p>
                    <p className="d1_p5">Shimoga</p>
                    <p className="d1_p5">Mysore</p>
                    <p className="d1_p5">Mandya</p>
                    <p className="d1_p5">Ramnagar</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d1__row2">
              <div className="d1_col21">
                <p className="d1_p21">Onboarding Merchant:</p>
                <p className="d1_p22">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ut
                  quasi laborum laudantium a, ullam, maxime illo, natus odio
                  aperiam fugiat consequatur! Laudantium molestias iure itaque
                  quo et omnis, obcaecati blanditiis. Optio cupiditate nam
                  inventore officia repellendus aperiam est hic et dignissimos
                  ratione, numquam minima ut placeat eaque voluptatibus ipsum
                  voluptatem enim debitis perspiciatis dolores nesciunt. Maiores
                  perferendis voluptas deleniti. Nesciunt!
                </p>
              </div>
              <div className="d1_col22">
                <div className="d1_card1">
                  <img
                    src={window.location.origin + "/images/revenue box.svg"}
                    alt="profile"
                    className="img_d1_card"
                  />
                  <div className="content">
                    <p className="d1_p23">Total Revenue</p>
                    <div className="content_d">
                      <img
                        src={window.location.origin + "/images/reupees.svg"}
                        alt="profile"
                        className="rupee_svg"
                      />
                      <p className="d1_p24">25000</p>
                    </div>
                  </div>
                </div>
                <div className="d1_card1">
                  <img
                    src={window.location.origin + "/images/today's lead.svg"}
                    alt="profile"
                    className="img_d1_card"
                  />
                  <div className="content">
                    <p className="d1_p23">Today’s leads</p>
                    <div className="content_d">
                      <p className="d1_p24">200</p>
                    </div>
                  </div>
                </div>
                <div className="d1_card1">
                  <img
                    src={
                      window.location.origin + "/images/total attendance.svg"
                    }
                    alt="profile"
                    className="img_d1_card"
                  />
                  <div className="content">
                    <p className="d1_p25">Today’s Attendence</p>
                    <div className="content_row1">
                      <div className="content_col1">
                        <p className="d1_p26">Absent</p>
                        <p className="d1_p27">03</p>
                      </div>
                      <div className="content_col1">
                        <div className="content_col1">
                          <p className="d1_p26">Present</p>
                          <p className="d1_p27">17</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="add_tl_total"
            id="add_tl_total"
            style={{ display: "none" }}
          >
            <div className="add_tl_card1">
              <div className="add_tl_row1">
                <h1 className="add_tl_col1">Team Leader List</h1>
                <div className="add_tl_search">
                  <img
                    src={window.location.origin + "/images/search.svg"}
                    alt="search"
                    className="search_svg"
                  />
                  <p className="add_tl_p1">Search TL</p>
                </div>
              </div>
              <div className="add_tl_row2">
                <div className="add_tl_card2">
                  <div className="add_tl_col2">
                    <div className="add_tl_col3">
                      <p className="add_tl_p2">Abhijit Mohanty</p>
                      <p className="add_tl_p3">TL name</p>
                    </div>
                    <div className="add_tl_col3">
                      <p className="add_tl_p4">Jio Mart</p>
                      <p className="add_tl_p3">Project name</p>
                    </div>
                    <div className="add_tl_col3">
                      <p className="add_tl_p5">18/03/2021</p>
                      <p className="add_tl_p3">Start date</p>
                    </div>
                    <div className="add_tl_col3">
                      <p className="add_tl_p6">21/03/2021</p>
                      <p className="add_tl_p3">End date</p>
                    </div>
                    <div className="add_tl_col3">
                      <p className="add_tl_p7">Available</p>
                    </div>
                  </div>
                  <div className="add_tl_col2">
                    <div className="buttons2" onClick={() => handleClick23()}>
                      <img
                        src={window.location.origin + "/images/add TL.svg"}
                        alt="profile"
                        className="add_img"
                      />
                      <p className="d1_p42">Add TL</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="add_tl_card2">
                <div className="add_tl_col2">
                  <div className="add_tl_col3">
                    <p className="add_tl_p2">Abhijit Mohanty</p>
                    <p className="add_tl_p3">TL name</p>
                  </div>
                  <div className="add_tl_col3">
                    <p className="add_tl_p4">Jio Mart</p>
                    <p className="add_tl_p3">Project name</p>
                  </div>
                  <div className="add_tl_col3">
                    <p className="add_tl_p5">18/03/2021</p>
                    <p className="add_tl_p3">Start date</p>
                  </div>
                  <div className="add_tl_col3">
                    <p className="add_tl_p6">21/03/2021</p>
                    <p className="add_tl_p3">End date</p>
                  </div>
                  <div className="add_tl_col3">
                    <p className="add_tl_p8">Assigned</p>
                  </div>
                </div>
                <div className="add_tl_col2">
                  <div className="buttons2" onClick={() => handleClick23()}>
                    <img
                      src={window.location.origin + "/images/add TL.svg"}
                      alt="profile"
                      className="add_img"
                    />
                    <p className="d1_p42">Add TL</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="add_tl_row3">
              <input
                type="button"
                value="save"
                className="add_tl_save_button"
                onClick={() => handleClick3()}
              />
            </div> */}
          </div>
          <div
            className="update_trainer"
            id="update_trainer"
            style={{ display: "none" }}
          >
            <div className="update_train_card">
              <div className="up_train_inner_card">
                <img
                  src={window.location.origin + "/images/document.svg"}
                  alt="profile"
                  className="document_svg"
                />
                <p className="up_t_p1">
                  Choose a file/ link to update the document
                </p>
                <p className="up_t_p2">
                  Click here to
                  <span>
                    <a href="#"> Browse</a>
                  </span>
                </p>
              </div>
            </div>
            <div className="add_tl_row3">
              <input
                type="button"
                value="save"
                className="up_t_save_button"
                onClick={() => handleClick4()}
              />
            </div>
          </div>
          <div
            className="setup_trainer_total"
            id="setup_trainer_total"
            style={{ display: "none" }}
          >
            <div className="set_train_row1">
              <div className="setup_trainer_Card">
                <p className="set_t_p1">Setup training questions</p>
                <div className="set_t_inner_card">
                  <div className="set_inner_row1">
                    <input
                      type="text"
                      placeholder="Type question here"
                      name="question"
                      id="question1"
                    />
                  </div>
                  <div className="set_inner_row1">
                    <input
                      type="text"
                      placeholder="Option 1"
                      name="question"
                      id="question"
                    />
                  </div>
                  <div className="set_inner_row1">
                    <input
                      type="text"
                      placeholder="Option 2"
                      name="question"
                      id="question"
                    />
                  </div>
                  <div className="set_inner_row1">
                    <input
                      type="text"
                      placeholder="Option 3"
                      name="question"
                      id="question"
                    />
                  </div>
                  <div className="set_inner_row1">
                    <input
                      type="text"
                      placeholder="Option 4"
                      name="question"
                      id="question"
                    />
                  </div>
                  <div className="set_inner_row1">
                    <input
                      type="text"
                      placeholder="Expected answer"
                      name="question"
                      id="question2"
                    />
                  </div>
                </div>
                <div className="set_done">
                  <input
                    type="button"
                    value="Done"
                    className="set_done_button"
                  />
                </div>
                <div className="set_add_q">
                  <input
                    type="button"
                    value="+ Add new question"
                    className="set_add_q_button"
                  />
                </div>
              </div>
            </div>
            <div className="add_tl_row3">
              <input
                type="button"
                value="Preview"
                className="up_t_save_button"
                onClick={() => handleClick5()}
              />
            </div>
          </div>
          <div
            className="setup_preview_total"
            id="setup_preview_total"
            style={{ display: "none" }}
          > 
            <div className="set_train_row1">
              <div className="setup_trainer_Card">
                <p className="set_t_p1">Training questions preview</p>
                <div className="s_t_inner_row">
                  <div className="set_t_inner_card">
                    <div className="set_inner_row2">
                      <div className="s_t_box">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Distinctio asperiores maxime nisi sapiente,
                        perferendis deserunt, aperiam porro ipsum quae beatae a,
                        laboriosam accusantium ex alias eius laborum
                        perspiciatis quam. Aut.
                      </div>
                      <div className="set_inner_row3">
                        <div className="s_t_small_box">Option 1</div>
                        <div className="s_t_small_box">Option 2</div>
                        <div className="s_t_small_box">Option 3</div>
                        <div className="s_t_small_box">Option 4</div>
                        <div className="s_t_small_box">Expected answer</div>
                      </div>
                    </div>
                  </div>
                  <div className="set_inner_row4">
                    <img
                      src={window.location.origin + "/images/EDIT.svg"}
                      alt="profile"
                      className="edit_pro"
                    />
                    <img
                      src={window.location.origin + "/images/delete.svg"}
                      alt="profile"
                      className="edit_pro"
                    />
                  </div>
                </div>
                <div className="s_t_inner_row">
                  <div className="set_t_inner_card">
                    <div className="set_inner_row2">
                      <div className="s_t_box">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Distinctio asperiores maxime nisi sapiente,
                        perferendis deserunt, aperiam porro ipsum quae beatae a,
                        laboriosam accusantium ex alias eius laborum
                        perspiciatis quam. Aut.
                      </div>
                      <div className="set_inner_row3">
                        <div className="s_t_small_box">Option 1</div>
                        <div className="s_t_small_box">Option 2</div>
                        <div className="s_t_small_box">Option 3</div>
                        <div className="s_t_small_box">Option 4</div>
                        <div className="s_t_small_box">Expected answer</div>
                      </div>
                    </div>
                  </div>
                  <div className="set_inner_row4">
                    <img
                      src={window.location.origin + "/images/EDIT.svg"}
                      alt="profile"
                      className="edit_pro"
                    />
                    <img
                      src={window.location.origin + "/images/delete.svg"}
                      alt="profile"
                      className="edit_pro"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="add_tl_row3">
              <input
                type="button"
                value="save"
                className="up_t_save_button"
                onClick={() => handleClick6()}
              />
            </div>
          </div>
          {/* <div
            className="d1_update_card"
            id="d1_update_card"
            style={{ display: "none" }}
          >
            <div className="d1_up_col1">
              <p className="d1_up_p1">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Eligendi, temporibus hic. Eaque perferendis maiores maxime enim
                voluptatem temporibus magni quae nostrum ex quasi deleniti
                nobis, rem sit praesentium sint? Eveniet.
              </p>
              <p className="d1_up_p1">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Aliquid veritatis laborum eligendi corporis perspiciatis ab
                animi, praesentium veniam esse deleniti, optio ipsam illo
                laudantium quod molestiae voluptas soluta impedit nostrum?
              </p>
            </div>
            <div className="d1_up_col2">
              <div className="d1_req_train">
                <p className="d1_req_p1">Requested for trainer</p>
              </div>
            </div>
          </div> */}
          {/* <div
            className="setup_date_total"
            id="setup_date_total"
            style={{ display: "none" }}
          >
            <div className="set_date_row1">
              <div className="set_date_inner_card">
                <p className="set_date_p1">Update date and time for trainer</p>
                <div className="set_date_row2">
                  <div className="set_date_inner_card2">
                    <p className="set_date_p2">Enter Date</p>
                    <input
                      type="text"
                      name="date"
                      id="date"
                      className="set_date_d"
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                  <div className="set_date_inner_card2">
                    <p className="set_date_p2">Enter Time</p>
                    <input
                      type="text"
                      name="date"
                      id="date"
                      className="set_date_d"
                      placeholder="00:00 am"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="set_date_row3">
              <input
                type="button"
                value="save"
                className="up_t_save_button"
                onClick={() => handleClick()}
              />
            </div>
          </div> */}
          <div
            className="launch_project_total"
            id="launch_project_total"
            style={{ display: "none" }}
          >
            <div className="launch_row1">
              <p className="lch_p1">SOW</p>
            </div>
            <div className="launch_row2">
              <p className="lch_p2">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Culpa
                quos harum consequatur quasi sapiente hic nam blanditiis amet
                distinctio voluptatibus, ullam nesciunt at voluptas aspernatur!
                Magnam labore nulla nemo culpa?
                <br />
                Facilis unde nesciunt ratione? Libero, numquam ipsa sapiente at
                asperiores aliquam ducimus ex repellat? Neque adipisci
                praesentium velit aut ipsum voluptatum quaerat aperiam odio
                blanditiis pariatur. Sit alias quos quisquam.
                <br />
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Obcaecati temporibus nemo eaque consequuntur totam? Excepturi
                nostrum nobis culpa delectus quam cum maiores at voluptatem
                voluptatibus vero repellendus aspernatur, ipsum ea.
              </p>
            </div>
            <div className="launch_row3">
              <div className="lch_col1">
                <div className="launch_row1">
                  <p className="lch_p1">Project date</p>
                  <div className="set_date_inner_card2">
                    <p className="set_date_p2">Enter Start Date</p>
                    <input
                      type="text"
                      name="date"
                      id="date"
                      className="set_date_d"
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                </div>
              </div>
              <div className="lch_col2">
                <input
                  type="button"
                  value="Active"
                  className="up_t_save_button"
                  onClick={() => handleClick13()}
                />
              </div>
            </div>
          </div>
          <div
            className="run_payroll_total"
            id="run_payroll_total"
            style={{ display: "none" }}
          >
            <div className="launch_row1">
              <p className="lch_p1">Run Payroll</p>
            </div>
            <div className="launch_row2">
              <p className="lch_p2">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Culpa
                quos harum consequatur quasi sapiente hic nam blanditiis amet
                distinctio voluptatibus, ullam nesciunt at voluptas aspernatur!
                Magnam labore nulla nemo culpa?
                <br />
                Facilis unde nesciunt ratione? Libero, numquam ipsa sapiente at
                asperiores aliquam ducimus ex repellat? Neque adipisci
                praesentium velit aut ipsum voluptatum quaerat aperiam odio
                blanditiis pariatur. Sit alias quos quisquam.
                <br />
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Obcaecati temporibus nemo eaque consequuntur totam? Excepturi
                nostrum nobis culpa delectus quam cum maiores at voluptatem
                voluptatibus vero repellendus aspernatur, ipsum ea.
              </p>
            </div>
            <div className="launch_row3">
              <div className="lch_col1">
                <div className="launch_row1">
                  <p className="lch_p1">Project date</p>
                  <div className="set_date_inner_card2">
                    <p className="set_date_p2">Enter Today Date</p>
                    <input
                      type="text"
                      name="date"
                      id="date"
                      className="set_date_d"
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                </div>
              </div>
              <div className="lch_col2">
                <input
                  type="button"
                  value="Download payroll"
                  className="payroll_button"
                  onClick={() => handleClick14()}
                />
              </div>
            </div>
          </div>
          <div
            className="upload_report_total"
            id="upload_report_total"
            style={{ display: "none" }}
          >
            <div className="upload_report_card">
              <div className="upl_re_row1">
                <p className="up_re_p1">Upload final report</p>
                <p className="up_re_p2">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam
                  aperiam molestias sit, quod eligendi debitis nemo perspiciatis
                  suscipit hic nesciunt assumenda numquam, fuga maxime qui
                  exercitationem odio incidunt temporibus ex?
                  <br />
                  Fugiat consequuntur cupiditate itaque ea magnam excepturi?
                  Nihil ab quidem laborum provident vitae molestiae unde eius
                  maiores qui, reprehenderit soluta consequuntur praesentium
                  impedit voluptates. Harum iusto laboriosam asperiores voluptas
                  sequi?
                </p>
              </div>
              <div className="upl_re_row2">
                <div className="up_train_inner_card">
                  <img
                    src={window.location.origin + "/images/document.svg"}
                    alt="profile"
                    className="document_svg"
                  />
                  <p className="up_t_p1">
                    Choose a file/ link to update the document
                  </p>
                  <p className="up_t_p2">
                    Click here to
                    <span>
                      <a href="#"> Browse</a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="add_tl_row3">
              <input
                type="button"
                value="save"
                className="up_t_save_button"
                onClick={() => handleClick15()}
              />
            </div>
          </div>

          <div className="dash1_row3">
            <div
              className={!active12 ? "d1_small_card" : "d1_small_card1"}
              onClick={() => handleClick18()}
            >
              <p className="d1_p31">My statics</p>
            </div>
            <div
              className={!active11 ? "d1_small_card" : "d1_small_card1"}
              onClick={() => handleClick17()}
            >
              <p className="d1_p31">My team</p>
            </div>
            <div
              className={!active10 ? "d1_small_card" : "d1_small_card1"}
              onClick={() => handleClick16()}
            >
              <p className="d1_p31">Project Details</p>
            </div>
            <div
              className={!active14 ? "d1_small_card" : "d1_small_card1"}
              onClick={() => handleClick20()}
            >
              <p className="d1_p31">Settings</p>
            </div>
            <div
              className={true ? "d1_small_card" : "d1_small_card1"}
              onClick={() => handleClick1()}
            >
              <p className="d1_p31">Support</p>
            </div>
          </div>
          <div
            className="project_detail_card"
            id="project_detail_card"
            style={{ display: "none" }}
          >
            <div className="proj_d_row1">
              <p className="d1_p21">Project details:</p>
              <p className="d1_p22">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto
                quos, at voluptates architecto aliquid, nesciunt neque, enim
                quisquam recusandae vel adipisci inventore non. Laborum
                voluptates sed aut doloremque enim sapiente. Soluta vitae magnam
                facilis saepe ipsa odit a in delectus, eveniet eius dignissimos
                earum totam cupiditate quia voluptas facere natus velit tempore,
                assumenda adipisci. Eos laudantium repellendus, animi corporis
                cupiditate dolor pariatur expedita ducimus, aspernatur ut
                blanditiis sit accusamus enim totam tempore voluptas recusandae
                est minus perspiciatis! Quam tempore quo dolore, eligendi
                deserunt aspernatur doloribus velit ullam dicta? Ut deleniti in
                ab harum eos quas, quod numquam. Facere quisquam veritatis nisi
                qui placeat ratione odio ullam voluptas rerum. Nesciunt ab
                praesentium sapiente inventore possimus numquam commodi eaque
                perferendis dicta sequi consequuntur accusantium aliquam beatae
                veritatis quas, at corporis doloribus dolor esse expedita
                necessitatibus quos. Corporis alias non aut harum maxime
                deserunt sequi ea impedit. Asperiores ad debitis sunt temporibus
                sapiente corrupti, obcaecati, odio qui, iste voluptatem quis
                libero fugiat corporis nostrum excepturi? Aut ea laudantium
                blanditiis ducimus distinctio ipsum doloremque atque possimus,
                ab, consequatur minima sed esse ullam, debitis a id
                exercitationem natus maxime fugiat sapiente aliquam! Fugit aut
                magni sunt voluptate nihil pariatur laboriosam placeat nisi
                ducimus quos illum quisquam possimus, fuga praesentium,
                provident, eaque quo quasi! Sint, tenetur id iure consequuntur
                repellat dolore ratione facilis a et, aperiam explicabo omnis
                nemo quasi sed unde eveniet minima est nulla! Aspernatur at
                itaque nesciunt quisquam, cum ex. Minus facere fugiat maiores
                quidem rem saepe iste voluptatum perferendis alias consectetur
                hic, corrupti, dolorum dolores atque? Corporis error non
                asperiores ducimus consequuntur dolorum similique numquam hic
                rerum porro doloremque, placeat ad quaerat minus dicta. Dolore,
                aut ea eos explicabo delectus veritatis sapiente eaque corporis
                adipisci temporibus consequuntur architecto, dolor minus porro,
                ab consequatur voluptate. Ad at eum voluptatem sint perferendis.
                Perferendis, officiis? Qui sed laboriosam enim amet molestiae
                optio, eos, eligendi sint pariatur rerum magni ipsa ea aliquid
                quaerat deserunt sunt, repellat quia velit est quae? Doloribus
                veritatis similique laborum eaque dolore mollitia eum vitae modi
                consectetur dolores, placeat reprehenderit. Eligendi quod
                debitis alias ipsa perferendis eaque expedita officiis nulla
                doloremque ea. Perspiciatis sed, dolores ut iusto similique
                neque dolorum corporis vitae nemo sint rem tempora provident
                aliquam expedita minus quas aperiam quod suscipit. Esse,
                necessitatibus? Nostrum ex dicta nisi beatae facilis officiis
                magni, saepe earum animi adipisci asperiores quam fugit nulla
                exercitationem? Illum voluptatem rerum fugiat nemo sunt
                necessitatibus velit, aut maiores? A officia, impedit nobis
                earum eaque saepe tenetur ab eligendi amet nisi quo ipsum,
                perspiciatis ipsa dolor tempore assumenda id deleniti. Mollitia
                id dolorum autem. Culpa quos, dolore quasi labore numquam
                dolores fugit repudiandae temporibus architecto est! Sequi totam
                neque maxime nesciunt aspernatur. Neque necessitatibus
                distinctio vitae, fuga velit tempora. Vitae corporis, qui iusto
                porro, esse ad ex eaque molestiae voluptates quidem autem
                voluptate sunt optio ratione placeat magni ut harum eum, facilis
                quaerat laboriosam consectetur odit. Cupiditate enim iure vitae
                maiores necessitatibus. Iusto, sequi facilis? Officia,
                distinctio exercitationem! Hic alias incidunt officiis optio
                distinctio. Voluptate recusandae ut id.
              </p>
            </div>
            <div className="proj_d_row1">
              <p className="d1_p21">Project details:</p>
              <p className="d1_p22">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto
                quos, at voluptates architecto aliquid, nesciunt neque, enim
                quisquam recusandae vel adipisci inventore non. Laborum
                voluptates sed aut doloremque enim sapiente. Soluta vitae magnam
                facilis saepe ipsa odit a in delectus, eveniet eius dignissimos
                earum totam cupiditate quia voluptas facere natus velit tempore,
                assumenda adipisci. Eos laudantium repellendus, animi corporis
                cupiditate dolor pariatur expedita ducimus, aspernatur ut
                blanditiis sit accusamus enim totam tempore voluptas recusandae
                est minus perspiciatis! Quam tempore quo dolore, eligendi
                deserunt aspernatur doloribus velit ullam dicta? Ut deleniti in
                ab harum eos quas, quod numquam. Facere quisquam veritatis nisi
                qui placeat ratione odio ullam voluptas rerum. Nesciunt ab
                praesentium sapiente inventore possimus numquam commodi eaque
                perferendis dicta sequi consequuntur accusantium aliquam beatae
                veritatis quas, at corporis doloribus dolor esse expedita
                necessitatibus quos. Corporis alias non aut harum maxime
                deserunt sequi ea impedit. Asperiores ad debitis sunt temporibus
                sapiente corrupti, obcaecati, odio qui, iste voluptatem quis
                libero fugiat corporis nostrum excepturi? Aut ea laudantium
                blanditiis ducimus distinctio ipsum doloremque atque possimus,
                ab, consequatur minima sed esse ullam, debitis a id
                exercitationem natus maxime fugiat sapiente aliquam! Fugit aut
                magni sunt voluptate nihil pariatur laboriosam placeat nisi
                ducimus quos illum quisquam possimus, fuga praesentium,
                provident, eaque quo quasi! Sint, tenetur id iure consequuntur
                repellat dolore ratione facilis a et, aperiam explicabo omnis
                nemo quasi sed unde eveniet minima est nulla! Aspernatur at
                itaque nesciunt quisquam, cum ex. Minus facere fugiat maiores
                quidem rem saepe iste voluptatum perferendis alias consectetur
                hic, corrupti, dolorum dolores atque? Corporis error non
                asperiores ducimus consequuntur dolorum similique numquam hic
                rerum porro doloremque, placeat ad quaerat minus dicta. Dolore,
                aut ea eos explicabo delectus veritatis sapiente eaque corporis
                adipisci temporibus consequuntur architecto, dolor minus porro,
                ab consequatur voluptate. Ad at eum voluptatem sint perferendis.
                Perferendis, officiis? Qui sed laboriosam enim amet molestiae
                optio, eos, eligendi sint pariatur rerum magni ipsa ea aliquid
                quaerat deserunt sunt, repellat quia velit est quae? Doloribus
                veritatis similique laborum eaque dolore mollitia eum vitae modi
                consectetur dolores, placeat reprehenderit. Eligendi quod
                debitis alias ipsa perferendis eaque expedita officiis nulla
                doloremque ea. Perspiciatis sed, dolores ut iusto similique
                neque dolorum corporis vitae nemo sint rem tempora provident
                aliquam expedita minus quas aperiam quod suscipit. Esse,
                necessitatibus? Nostrum ex dicta nisi beatae facilis officiis
                magni, saepe earum animi adipisci asperiores quam fugit nulla
                exercitationem? Illum voluptatem rerum fugiat nemo sunt
                necessitatibus velit, aut maiores? A officia, impedit nobis
                earum eaque saepe tenetur ab eligendi amet nisi quo ipsum,
                perspiciatis ipsa dolor tempore assumenda id deleniti. Mollitia
                id dolorum autem. Culpa quos, dolore quasi labore numquam
                dolores fugit repudiandae temporibus architecto est! Sequi totam
                neque maxime nesciunt aspernatur. Neque necessitatibus
                distinctio vitae, fuga velit tempora. Vitae corporis, qui iusto
                porro, esse ad ex eaque molestiae voluptates quidem autem
                voluptate sunt optio ratione placeat magni ut harum eum, facilis
                quaerat laboriosam consectetur odit. Cupiditate enim iure vitae
                maiores necessitatibus. Iusto, sequi facilis? Officia,
                distinctio exercitationem! Hic alias incidunt officiis optio
                distinctio. Voluptate recusandae ut id.
              </p>
            </div>
            <div className="proj_d_row1">
              <p className="d1_p21">Project details:</p>
              <p className="d1_p22">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto
                quos, at voluptates architecto aliquid, nesciunt neque, enim
                quisquam recusandae vel adipisci inventore non. Laborum
                voluptates sed aut doloremque enim sapiente. Soluta vitae magnam
                facilis saepe ipsa odit a in delectus, eveniet eius dignissimos
                earum totam cupiditate quia voluptas facere natus velit tempore,
                assumenda adipisci. Eos laudantium repellendus, animi corporis
                cupiditate dolor pariatur expedita ducimus, aspernatur ut
                blanditiis sit accusamus enim totam tempore voluptas recusandae
                est minus perspiciatis! Quam tempore quo dolore, eligendi
                deserunt aspernatur doloribus velit ullam dicta? Ut deleniti in
                ab harum eos quas, quod numquam. Facere quisquam veritatis nisi
                qui placeat ratione odio ullam voluptas rerum. Nesciunt ab
                praesentium sapiente inventore possimus numquam commodi eaque
                perferendis dicta sequi consequuntur accusantium aliquam beatae
                veritatis quas, at corporis doloribus dolor esse expedita
                necessitatibus quos. Corporis alias non aut harum maxime
                deserunt sequi ea impedit. Asperiores ad debitis sunt temporibus
                sapiente corrupti, obcaecati, odio qui, iste voluptatem quis
                libero fugiat corporis nostrum excepturi? Aut ea laudantium
                blanditiis ducimus distinctio ipsum doloremque atque possimus,
                ab, consequatur minima sed esse ullam, debitis a id
                exercitationem natus maxime fugiat sapiente aliquam! Fugit aut
                magni sunt voluptate nihil pariatur laboriosam placeat nisi
                ducimus quos illum quisquam possimus, fuga praesentium,
                provident, eaque quo quasi! Sint, tenetur id iure consequuntur
                repellat dolore ratione facilis a et, aperiam explicabo omnis
                nemo quasi sed unde eveniet minima est nulla! Aspernatur at
                itaque nesciunt quisquam, cum ex. Minus facere fugiat maiores
                quidem rem saepe iste voluptatum perferendis alias consectetur
                hic, corrupti, dolorum dolores atque? Corporis error non
                asperiores ducimus consequuntur dolorum similique numquam hic
                rerum porro doloremque, placeat ad quaerat minus dicta. Dolore,
                aut ea eos explicabo delectus veritatis sapiente eaque corporis
                adipisci temporibus consequuntur architecto, dolor minus porro,
                ab consequatur voluptate. Ad at eum voluptatem sint perferendis.
                Perferendis, officiis? Qui sed laboriosam enim amet molestiae
                optio, eos, eligendi sint pariatur rerum magni ipsa ea aliquid
                quaerat deserunt sunt, repellat quia velit est quae? Doloribus
                veritatis similique laborum eaque dolore mollitia eum vitae modi
                consectetur dolores, placeat reprehenderit. Eligendi quod
                debitis alias ipsa perferendis eaque expedita officiis nulla
                doloremque ea. Perspiciatis sed, dolores ut iusto similique
                neque dolorum corporis vitae nemo sint rem tempora provident
                aliquam expedita minus quas aperiam quod suscipit. Esse,
                necessitatibus? Nostrum ex dicta nisi beatae facilis officiis
                magni, saepe earum animi adipisci asperiores quam fugit nulla
                exercitationem? Illum voluptatem rerum fugiat nemo sunt
                necessitatibus velit, aut maiores? A officia, impedit nobis
                earum eaque saepe tenetur ab eligendi amet nisi quo ipsum,
                perspiciatis ipsa dolor tempore assumenda id deleniti. Mollitia
                id dolorum autem. Culpa quos, dolore quasi labore numquam
                dolores fugit repudiandae temporibus architecto est! Sequi totam
                neque maxime nesciunt aspernatur. Neque necessitatibus
                distinctio vitae, fuga velit tempora. Vitae corporis, qui iusto
                porro, esse ad ex eaque molestiae voluptates quidem autem
                voluptate sunt optio ratione placeat magni ut harum eum, facilis
                quaerat laboriosam consectetur odit. Cupiditate enim iure vitae
                maiores necessitatibus. Iusto, sequi facilis? Officia,
                distinctio exercitationem! Hic alias incidunt officiis optio
                distinctio. Voluptate recusandae ut id.
              </p>
            </div>
          </div>
          <div
            className="my_team_data"
            id="my_team_data"
            style={{ display: "none" }}
          >
            <div className="my_t_row1">
              <p className="my_t_p1">Team leads(TL) List</p>
              <div className="buttons2" onClick={() => handleClick2()}>
                <img
                  src={window.location.origin + "/images/add TL.svg"}
                  alt="profile"
                  className="add_img"
                />
                <p className="d1_p42">Add TL</p>
              </div>
            </div>
            <div className="my_t_row2">
              <div className="my_t_card">
                <div className="my_t_c_row1">
                  <div className="my_t_col01">
                    <img
                      src={window.location.origin + "/images/profile image.svg"}
                      alt="profile"
                      className="prof_img"
                    />
                    <div className="my_t_col02">
                      <div className="my_t_row01">
                        <p className="my_tl_p01">Yash</p>
                        <p className="my_tl_p02">JM1212</p>
                      </div>
                    </div>
                  </div>
                  <div className="my_t_col03">
                    <div className="my_t_row02">
                      <img
                        src={window.location.origin + "/images/call.svg"}
                        alt="profile"
                        className="cal_svg"
                      />
                      <img
                        src={window.location.origin + "/images/message.svg"}
                        alt="profile"
                        className="cal_svg"
                      />
                      <img
                        src={window.location.origin + "/images/delete.svg"}
                        alt="profile"
                        className="cal_svg"
                      />
                    </div>
                    <div className="my_t_row04" onClick={() => handleClick19()}>
                      <div className="my_t_boxes">
                        <img
                          src={window.location.origin + "/images/profile-o.svg"}
                          alt="profile"
                          className="my_t_svg"
                        />
                        <div className="my_t_box">
                          <p className="my_t_p02">ASM</p>
                          <p className="my_t_p03">Nikhil</p>
                        </div>
                      </div>
                      <div className="my_t_boxes">
                        <img
                          src={
                            window.location.origin + "/images/location-o.svg"
                          }
                          alt="profile"
                          className="my_t_svg"
                        />
                        <div className="my_t_box">
                          <p className="my_t_p02">Location</p>
                          <p className="my_t_p03">Bangalore</p>
                        </div>
                      </div>
                      <div className="my_t_boxes">
                        <img
                          src={window.location.origin + "/images/leads-o.svg"}
                          alt="profile"
                          className="my_t_svg"
                        />
                        <div className="my_t_box">
                          <p className="my_t_p02">Total Lead</p>
                          <p className="my_t_p03">200</p>
                        </div>
                      </div>
                      <div className="my_t_boxes">
                        <img
                          src={window.location.origin + "/images/qc-o.svg"}
                          alt="profile"
                          className="my_t_qc_svg"
                        />
                        <div className="my_t_box">
                          <p className="my_t_p02">QC Conversion</p>
                          <p className="my_t_p03">108</p>
                        </div>
                      </div>
                      <div className="my_t_boxes">
                        <img
                          src={
                            window.location.origin + "/images/attendance-o.svg"
                          }
                          alt="profile"
                          className="my_t_svg"
                        />
                        <div className="my_t_box">
                          <p className="my_t_p02">Attendance</p>
                          <p className="my_t_p03">60%</p>
                        </div>
                      </div>
                      <div className="my_t_boxes">
                        <img
                          src={window.location.origin + "/images/points-o.svg"}
                          alt="profile"
                          className="my_t_svg"
                        />
                        <div className="my_t_box">
                          <p className="my_t_p02">Achieved points</p>
                          <p className="my_t_p03">1345</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="my_t_col04">
                    <img
                      src={window.location.origin + "/images/dots3.svg"}
                      alt="profile"
                      className="cal_svg"
                      onClick={() => handleClick28()}
                    />
                    <div
                      className="dots_card"
                      id="dots_card"
                      style={{ display: "none" }}
                    >
                      <div className="dots_row1">
                        <p
                          className="dots_p1"
                          style={
                            !active6
                              ? 
                             { color: "#18AA1B" }:{ color: " #FF4D4D" }
                          }
                          onClick={() => handleClick29()}
                        >
                          Update training date & time
                        </p>
                        <img
                          src={
                            window.location.origin + "/images/FRONT arrow.svg"
                          }
                          alt="profile"
                          className="dots_front"
                        />
                      </div>

                      <div className="dots_row2">
                        <p className="dots_p2">Training status</p>
                        <p className="dots_p3">Completed</p>
                      </div>
                    </div>
                    <div class="f_arrow" onClick={() => handleClick19()}>
                      <img
                        src={
                          !active13
                            ? window.location.origin + "/images/FRONT arrow.svg"
                            : window.location.origin + "/images/down arrow.svg"
                        }
                        alt="profile"
                        className="f_arrow_svg"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="my_t_c_row2"
                  id="my_t_c_row2"
                  style={{ display: "none" }}
                >
                  <div className="my_t_c_row3">
                    <div className="my_t_c_col1">
                      <div className="my_t_c_row4">
                        <p className="my_t_c_p1">Today’s Attendance</p>
                        <div className="my_t_c_boxes">
                          <div className="my_t_c_box1">
                            <p className="my_t_c_p2">Present</p>
                            <p className="my_t_c_p3">17</p>
                          </div>
                          <div className="my_t_c_box2">
                            <p className="my_t_c_p2">Absent</p>
                            <p className="my_t_c_p3">03</p>
                          </div>
                          <div className="my_t_c_box3">
                            <p className="my_t_c_p2">Today’s Attendance</p>
                            <p className="my_t_c_p3">70%</p>
                          </div>
                        </div>
                      </div>
                      <div className="my_t_c_row4">
                        <p className="my_t_c_p1">Lead to QC Conversion</p>
                        <div className="my_t_c_boxes">
                          <div className="my_t_c_box4">
                            <p className="my_t_c_p2">Leads</p>
                            <p className="my_t_c_p3">250</p>
                          </div>
                          <div className="my_t_c_box5">
                            <p className="my_t_c_p2">QC Conversion</p>
                            <p className="my_t_c_p3">200</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="my_t_c_col2">
                      <p className="my_stats_p5">Today’s achieved points</p>
                      <Chart
                        options={apexBar2.options}
                        series={apexBar2.series}
                        type="bar"
                        height={350}
                      />
                    </div>
                  </div>
                  <div className="my_t_c_row5">
                    <p className="my_stats_p5">FSE List</p>
                    <div className="my_t_c_card">
                      <div className="my_t_col0">
                        <img
                          src={
                            window.location.origin + "/images/profile image.svg"
                          }
                          alt="profile"
                          className="prof_img"
                        />
                      </div>
                      <div className="my_t_col11">
                        <div className="my_t_row3">
                          <p className="my_t_p2">Ateeq</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">FSE</p>
                        </div>
                      </div>
                      <div className="my_t_col12">
                        <div className="my_t_row3">
                          <p className="my_t_p2">fse105</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">FSE Id</p>
                        </div>
                      </div>
                      <div className="my_t_col13">
                        <div className="my_t_row3">
                          <p className="my_t_p4">Bangalore</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">Location</p>
                        </div>
                      </div>
                      <div className="my_t_col14">
                        <div className="my_t_row3">
                          <p className="my_t_p5">Ankita</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">TL name</p>
                        </div>
                      </div>
                      <div className="my_t_col15">
                        <div className="my_t_row3">
                          <p className="my_t_p6">100</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">Leads</p>
                        </div>
                      </div>
                      <div className="my_t_col16">
                        <div className="my_t_row3">
                          <p className="my_t_p8">80</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">QC Conversion</p>
                        </div>
                      </div>
                      <div className="my_t_col18">
                        <div className="my_t_row3">
                          <p className="my_t_p10">03/04</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p11">WR Meet</p>
                        </div>
                      </div>
                      <div className="my_t_col17">
                        <div className="my_t_row3">
                          <p className="my_t_p9">60%</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">Attendance</p>
                        </div>
                      </div>

                      <div className="my_t_col19">
                        <div className="my_t_row4">
                          <input
                            type="button"
                            value="View Roaster"
                            className="add_tl_save_button"
                            onClick={() => handleClick1()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="my_t_c_card">
                      <div className="my_t_col0">
                        <img
                          src={
                            window.location.origin + "/images/profile image.svg"
                          }
                          alt="profile"
                          className="prof_img"
                        />
                      </div>
                      <div className="my_t_col11">
                        <div className="my_t_row3">
                          <p className="my_t_p2">Ateeq</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">FSE</p>
                        </div>
                      </div>
                      <div className="my_t_col12">
                        <div className="my_t_row3">
                          <p className="my_t_p2">fse105</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">FSE Id</p>
                        </div>
                      </div>
                      <div className="my_t_col13">
                        <div className="my_t_row3">
                          <p className="my_t_p4">Bangalore</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">Location</p>
                        </div>
                      </div>
                      <div className="my_t_col14">
                        <div className="my_t_row3">
                          <p className="my_t_p5">Ankita</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">TL name</p>
                        </div>
                      </div>
                      <div className="my_t_col15">
                        <div className="my_t_row3">
                          <p className="my_t_p6">100</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">Leads</p>
                        </div>
                      </div>
                      <div className="my_t_col16">
                        <div className="my_t_row3">
                          <p className="my_t_p8">80</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">QC Conversion</p>
                        </div>
                      </div>
                      <div className="my_t_col18">
                        <div className="my_t_row3">
                          <p className="my_t_p10">03/04</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p11">WR Meet</p>
                        </div>
                      </div>
                      <div className="my_t_col17">
                        <div className="my_t_row3">
                          <p className="my_t_p9">60%</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">Attendance</p>
                        </div>
                      </div>

                      <div className="my_t_col19">
                        <div className="my_t_row4">
                          <input
                            type="button"
                            value="View Roaster"
                            className="add_tl_save_button"
                            onClick={() => handleClick1()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="my_t_c_card">
                      <div className="my_t_col0">
                        <img
                          src={
                            window.location.origin + "/images/profile image.svg"
                          }
                          alt="profile"
                          className="prof_img"
                        />
                      </div>
                      <div className="my_t_col11">
                        <div className="my_t_row3">
                          <p className="my_t_p2">Ateeq</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">FSE</p>
                        </div>
                      </div>
                      <div className="my_t_col12">
                        <div className="my_t_row3">
                          <p className="my_t_p2">fse105</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">FSE Id</p>
                        </div>
                      </div>
                      <div className="my_t_col13">
                        <div className="my_t_row3">
                          <p className="my_t_p4">Bangalore</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">Location</p>
                        </div>
                      </div>
                      <div className="my_t_col14">
                        <div className="my_t_row3">
                          <p className="my_t_p5">Ankita</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">TL name</p>
                        </div>
                      </div>
                      <div className="my_t_col15">
                        <div className="my_t_row3">
                          <p className="my_t_p6">100</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">Leads</p>
                        </div>
                      </div>
                      <div className="my_t_col16">
                        <div className="my_t_row3">
                          <p className="my_t_p8">80</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">QC Conversion</p>
                        </div>
                      </div>
                      <div className="my_t_col18">
                        <div className="my_t_row3">
                          <p className="my_t_p10">03/04</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p11">WR Meet</p>
                        </div>
                      </div>
                      <div className="my_t_col17">
                        <div className="my_t_row3">
                          <p className="my_t_p9">60%</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">Attendance</p>
                        </div>
                      </div>

                      <div className="my_t_col19">
                        <div className="my_t_row4">
                          <input
                            type="button"
                            value="View Roaster"
                            className="add_tl_save_button"
                            onClick={() => handleClick1()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my_t_card">
                <div className="my_t_c_row1" onClick={() => handleClick19()}>
                  <div className="my_t_col01">
                    <img
                      src={window.location.origin + "/images/profile image.svg"}
                      alt="profile"
                      className="prof_img"
                    />
                    <div className="my_t_col02">
                      <div className="my_t_row01">
                        <p className="my_tl_p01">Yash</p>
                        <p className="my_tl_p02">JM1212</p>
                      </div>
                    </div>
                  </div>
                  <div className="my_t_col03">
                    <div className="my_t_row02">
                      <img
                        src={window.location.origin + "/images/call.svg"}
                        alt="profile"
                        className="cal_svg"
                      />
                      <img
                        src={window.location.origin + "/images/message.svg"}
                        alt="profile"
                        className="cal_svg"
                      />
                      <img
                        src={window.location.origin + "/images/delete.svg"}
                        alt="profile"
                        className="cal_svg"
                      />
                    </div>
                    <div className="my_t_row04">
                      <div className="my_t_boxes">
                        <img
                          src={window.location.origin + "/images/profile-o.svg"}
                          alt="profile"
                          className="my_t_svg"
                        />
                        <div className="my_t_box">
                          <p className="my_t_p02">ASM</p>
                          <p className="my_t_p03">Nikhil</p>
                        </div>
                      </div>
                      <div className="my_t_boxes">
                        <img
                          src={
                            window.location.origin + "/images/location-o.svg"
                          }
                          alt="profile"
                          className="my_t_svg"
                        />
                        <div className="my_t_box">
                          <p className="my_t_p02">Location</p>
                          <p className="my_t_p03">Bangalore</p>
                        </div>
                      </div>
                      <div className="my_t_boxes">
                        <img
                          src={window.location.origin + "/images/leads-o.svg"}
                          alt="profile"
                          className="my_t_svg"
                        />
                        <div className="my_t_box">
                          <p className="my_t_p02">Total Lead</p>
                          <p className="my_t_p03">200</p>
                        </div>
                      </div>
                      <div className="my_t_boxes">
                        <img
                          src={window.location.origin + "/images/qc-o.svg"}
                          alt="profile"
                          className="my_t_qc_svg"
                        />
                        <div className="my_t_box">
                          <p className="my_t_p02">QC Conversion</p>
                          <p className="my_t_p03">108</p>
                        </div>
                      </div>
                      <div className="my_t_boxes">
                        <img
                          src={
                            window.location.origin + "/images/attendance-o.svg"
                          }
                          alt="profile"
                          className="my_t_svg"
                        />
                        <div className="my_t_box">
                          <p className="my_t_p02">Attendance</p>
                          <p className="my_t_p03">60%</p>
                        </div>
                      </div>
                      <div className="my_t_boxes">
                        <img
                          src={window.location.origin + "/images/points-o.svg"}
                          alt="profile"
                          className="my_t_svg"
                        />
                        <div className="my_t_box">
                          <p className="my_t_p02">Achieved points</p>
                          <p className="my_t_p03">1345</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="my_t_col04">
                    <img
                      src={window.location.origin + "/images/dots3.svg"}
                      alt="profile"
                      className="cal_svg"
                    />
                    <img
                      src={
                        !active13
                          ? window.location.origin + "/images/FRONT arrow.svg"
                          : window.location.origin + "/images/down arrow.svg"
                      }
                      alt="profile"
                      className="f_arrow_svg"
                    />
                  </div>
                </div>
                <div
                  className="my_t_c_row2"
                  id="my_t_c_row2"
                  style={{ display: "none" }}
                >
                  <div className="my_t_c_row3">
                    <div className="my_t_c_col1">
                      <div className="my_t_c_row4">
                        <p className="my_t_c_p1">Today’s Attendance</p>
                        <div className="my_t_c_boxes">
                          <div className="my_t_c_box1">
                            <p className="my_t_c_p2">Present</p>
                            <p className="my_t_c_p3">17</p>
                          </div>
                          <div className="my_t_c_box2">
                            <p className="my_t_c_p2">Absent</p>
                            <p className="my_t_c_p3">03</p>
                          </div>
                          <div className="my_t_c_box3">
                            <p className="my_t_c_p2">Today’s Attendance</p>
                            <p className="my_t_c_p3">70%</p>
                          </div>
                        </div>
                      </div>
                      <div className="my_t_c_row4">
                        <p className="my_t_c_p1">Lead to QC Conversion</p>
                        <div className="my_t_c_boxes">
                          <div className="my_t_c_box4">
                            <p className="my_t_c_p2">Leads</p>
                            <p className="my_t_c_p3">250</p>
                          </div>
                          <div className="my_t_c_box5">
                            <p className="my_t_c_p2">QC Conversion</p>
                            <p className="my_t_c_p3">200</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="my_t_c_col2">
                      <p className="my_stats_p5">Today’s achieved points</p>
                    </div>
                  </div>
                  <div className="my_t_c_row5">
                    <p className="my_stats_p5">FSE List</p>
                    <div className="my_t_c_card">
                      <div className="my_t_col0">
                        <img
                          src={
                            window.location.origin + "/images/profile image.svg"
                          }
                          alt="profile"
                          className="prof_img"
                        />
                      </div>
                      <div className="my_t_col11">
                        <div className="my_t_row3">
                          <p className="my_t_p2">Ateeq</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">FSE</p>
                        </div>
                      </div>
                      <div className="my_t_col12">
                        <div className="my_t_row3">
                          <p className="my_t_p2">fse105</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">FSE Id</p>
                        </div>
                      </div>
                      <div className="my_t_col13">
                        <div className="my_t_row3">
                          <p className="my_t_p4">Bangalore</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">Location</p>
                        </div>
                      </div>
                      <div className="my_t_col14">
                        <div className="my_t_row3">
                          <p className="my_t_p5">Ankita</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">TL name</p>
                        </div>
                      </div>
                      <div className="my_t_col15">
                        <div className="my_t_row3">
                          <p className="my_t_p6">100</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">Leads</p>
                        </div>
                      </div>
                      <div className="my_t_col16">
                        <div className="my_t_row3">
                          <p className="my_t_p8">80</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">QC Conversion</p>
                        </div>
                      </div>
                      <div className="my_t_col18">
                        <div className="my_t_row3">
                          <p className="my_t_p10">03/04</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p11">WR Meet</p>
                        </div>
                      </div>
                      <div className="my_t_col17">
                        <div className="my_t_row3">
                          <p className="my_t_p9">60%</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">Attendance</p>
                        </div>
                      </div>

                      <div className="my_t_col19">
                        <div className="my_t_row4">
                          <input
                            type="button"
                            value="View Roaster"
                            className="add_tl_save_button"
                            onClick={() => handleClick1()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="my_t_c_card">
                      <div className="my_t_col0">
                        <img
                          src={
                            window.location.origin + "/images/profile image.svg"
                          }
                          alt="profile"
                          className="prof_img"
                        />
                      </div>
                      <div className="my_t_col11">
                        <div className="my_t_row3">
                          <p className="my_t_p2">Ateeq</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">FSE</p>
                        </div>
                      </div>
                      <div className="my_t_col12">
                        <div className="my_t_row3">
                          <p className="my_t_p2">fse105</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">FSE Id</p>
                        </div>
                      </div>
                      <div className="my_t_col13">
                        <div className="my_t_row3">
                          <p className="my_t_p4">Bangalore</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">Location</p>
                        </div>
                      </div>
                      <div className="my_t_col14">
                        <div className="my_t_row3">
                          <p className="my_t_p5">Ankita</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">TL name</p>
                        </div>
                      </div>
                      <div className="my_t_col15">
                        <div className="my_t_row3">
                          <p className="my_t_p6">100</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">Leads</p>
                        </div>
                      </div>
                      <div className="my_t_col16">
                        <div className="my_t_row3">
                          <p className="my_t_p8">80</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">QC Conversion</p>
                        </div>
                      </div>
                      <div className="my_t_col18">
                        <div className="my_t_row3">
                          <p className="my_t_p10">03/04</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p11">WR Meet</p>
                        </div>
                      </div>
                      <div className="my_t_col17">
                        <div className="my_t_row3">
                          <p className="my_t_p9">60%</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">Attendance</p>
                        </div>
                      </div>

                      <div className="my_t_col19">
                        <div className="my_t_row4">
                          <input
                            type="button"
                            value="View Roaster"
                            className="add_tl_save_button"
                            onClick={() => handleClick1()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="my_t_c_card">
                      <div className="my_t_col0">
                        <img
                          src={
                            window.location.origin + "/images/profile image.svg"
                          }
                          alt="profile"
                          className="prof_img"
                        />
                      </div>
                      <div className="my_t_col11">
                        <div className="my_t_row3">
                          <p className="my_t_p2">Ateeq</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">FSE</p>
                        </div>
                      </div>
                      <div className="my_t_col12">
                        <div className="my_t_row3">
                          <p className="my_t_p2">fse105</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">FSE Id</p>
                        </div>
                      </div>
                      <div className="my_t_col13">
                        <div className="my_t_row3">
                          <p className="my_t_p4">Bangalore</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">Location</p>
                        </div>
                      </div>
                      <div className="my_t_col14">
                        <div className="my_t_row3">
                          <p className="my_t_p5">Ankita</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">TL name</p>
                        </div>
                      </div>
                      <div className="my_t_col15">
                        <div className="my_t_row3">
                          <p className="my_t_p6">100</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">Leads</p>
                        </div>
                      </div>
                      <div className="my_t_col16">
                        <div className="my_t_row3">
                          <p className="my_t_p8">80</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">QC Conversion</p>
                        </div>
                      </div>
                      <div className="my_t_col18">
                        <div className="my_t_row3">
                          <p className="my_t_p10">03/04</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p11">WR Meet</p>
                        </div>
                      </div>
                      <div className="my_t_col17">
                        <div className="my_t_row3">
                          <p className="my_t_p9">60%</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">Attendance</p>
                        </div>
                      </div>

                      <div className="my_t_col19">
                        <div className="my_t_row4">
                          <input
                            type="button"
                            value="View Roaster"
                            className="add_tl_save_button"
                            onClick={() => handleClick1()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my_t_card">
                <div className="my_t_c_row1" onClick={() => handleClick19()}>
                  <div className="my_t_col01">
                    <img
                      src={window.location.origin + "/images/profile image.svg"}
                      alt="profile"
                      className="prof_img"
                    />
                    <div className="my_t_col02">
                      <div className="my_t_row01">
                        <p className="my_tl_p01">Yash</p>
                        <p className="my_tl_p02">JM1212</p>
                      </div>
                    </div>
                  </div>
                  <div className="my_t_col03">
                    <div className="my_t_row02">
                      <img
                        src={window.location.origin + "/images/call.svg"}
                        alt="profile"
                        className="cal_svg"
                      />
                      <img
                        src={window.location.origin + "/images/message.svg"}
                        alt="profile"
                        className="cal_svg"
                      />
                      <img
                        src={window.location.origin + "/images/delete.svg"}
                        alt="profile"
                        className="cal_svg"
                      />
                    </div>
                    <div className="my_t_row04">
                      <div className="my_t_boxes">
                        <img
                          src={window.location.origin + "/images/profile-o.svg"}
                          alt="profile"
                          className="my_t_svg"
                        />
                        <div className="my_t_box">
                          <p className="my_t_p02">ASM</p>
                          <p className="my_t_p03">Nikhil</p>
                        </div>
                      </div>
                      <div className="my_t_boxes">
                        <img
                          src={
                            window.location.origin + "/images/location-o.svg"
                          }
                          alt="profile"
                          className="my_t_svg"
                        />
                        <div className="my_t_box">
                          <p className="my_t_p02">Location</p>
                          <p className="my_t_p03">Bangalore</p>
                        </div>
                      </div>
                      <div className="my_t_boxes">
                        <img
                          src={window.location.origin + "/images/leads-o.svg"}
                          alt="profile"
                          className="my_t_svg"
                        />
                        <div className="my_t_box">
                          <p className="my_t_p02">Total Lead</p>
                          <p className="my_t_p03">200</p>
                        </div>
                      </div>
                      <div className="my_t_boxes">
                        <img
                          src={window.location.origin + "/images/qc-o.svg"}
                          alt="profile"
                          className="my_t_qc_svg"
                        />
                        <div className="my_t_box">
                          <p className="my_t_p02">QC Conversion</p>
                          <p className="my_t_p03">108</p>
                        </div>
                      </div>
                      <div className="my_t_boxes">
                        <img
                          src={
                            window.location.origin + "/images/attendance-o.svg"
                          }
                          alt="profile"
                          className="my_t_svg"
                        />
                        <div className="my_t_box">
                          <p className="my_t_p02">Attendance</p>
                          <p className="my_t_p03">60%</p>
                        </div>
                      </div>
                      <div className="my_t_boxes">
                        <img
                          src={window.location.origin + "/images/points-o.svg"}
                          alt="profile"
                          className="my_t_svg"
                        />
                        <div className="my_t_box">
                          <p className="my_t_p02">Achieved points</p>
                          <p className="my_t_p03">1345</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="my_t_col04">
                    <img
                      src={window.location.origin + "/images/dots3.svg"}
                      alt="profile"
                      className="cal_svg"
                    />
                    <img
                      src={
                        !active13
                          ? window.location.origin + "/images/FRONT arrow.svg"
                          : window.location.origin + "/images/down arrow.svg"
                      }
                      alt="profile"
                      className="f_arrow_svg"
                    />
                  </div>
                </div>
                <div
                  className="my_t_c_row2"
                  id="my_t_c_row2"
                  style={{ display: "none" }}
                >
                  <div className="my_t_c_row3">
                    <div className="my_t_c_col1">
                      <div className="my_t_c_row4">
                        <p className="my_t_c_p1">Today’s Attendance</p>
                        <div className="my_t_c_boxes">
                          <div className="my_t_c_box1">
                            <p className="my_t_c_p2">Present</p>
                            <p className="my_t_c_p3">17</p>
                          </div>
                          <div className="my_t_c_box2">
                            <p className="my_t_c_p2">Absent</p>
                            <p className="my_t_c_p3">03</p>
                          </div>
                          <div className="my_t_c_box3">
                            <p className="my_t_c_p2">Today’s Attendance</p>
                            <p className="my_t_c_p3">70%</p>
                          </div>
                        </div>
                      </div>
                      <div className="my_t_c_row4">
                        <p className="my_t_c_p1">Lead to QC Conversion</p>
                        <div className="my_t_c_boxes">
                          <div className="my_t_c_box4">
                            <p className="my_t_c_p2">Leads</p>
                            <p className="my_t_c_p3">250</p>
                          </div>
                          <div className="my_t_c_box5">
                            <p className="my_t_c_p2">QC Conversion</p>
                            <p className="my_t_c_p3">200</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="my_t_c_col2">
                      <p className="my_stats_p5">Today’s achieved points</p>
                    </div>
                  </div>
                  <div className="my_t_c_row5">
                    <p className="my_stats_p5">FSE List</p>
                    <div className="my_t_c_card">
                      <div className="my_t_col0">
                        <img
                          src={
                            window.location.origin + "/images/profile image.svg"
                          }
                          alt="profile"
                          className="prof_img"
                        />
                      </div>
                      <div className="my_t_col11">
                        <div className="my_t_row3">
                          <p className="my_t_p2">Ateeq</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">FSE</p>
                        </div>
                      </div>
                      <div className="my_t_col12">
                        <div className="my_t_row3">
                          <p className="my_t_p2">fse105</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">FSE Id</p>
                        </div>
                      </div>
                      <div className="my_t_col13">
                        <div className="my_t_row3">
                          <p className="my_t_p4">Bangalore</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">Location</p>
                        </div>
                      </div>
                      <div className="my_t_col14">
                        <div className="my_t_row3">
                          <p className="my_t_p5">Ankita</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">TL name</p>
                        </div>
                      </div>
                      <div className="my_t_col15">
                        <div className="my_t_row3">
                          <p className="my_t_p6">100</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">Leads</p>
                        </div>
                      </div>
                      <div className="my_t_col16">
                        <div className="my_t_row3">
                          <p className="my_t_p8">80</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">QC Conversion</p>
                        </div>
                      </div>
                      <div className="my_t_col18">
                        <div className="my_t_row3">
                          <p className="my_t_p10">03/04</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p11">WR Meet</p>
                        </div>
                      </div>
                      <div className="my_t_col17">
                        <div className="my_t_row3">
                          <p className="my_t_p9">60%</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">Attendance</p>
                        </div>
                      </div>

                      <div className="my_t_col19">
                        <div className="my_t_row4">
                          <input
                            type="button"
                            value="View Roaster"
                            className="add_tl_save_button"
                            onClick={() => handleClick1()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="my_t_c_card">
                      <div className="my_t_col0">
                        <img
                          src={
                            window.location.origin + "/images/profile image.svg"
                          }
                          alt="profile"
                          className="prof_img"
                        />
                      </div>
                      <div className="my_t_col11">
                        <div className="my_t_row3">
                          <p className="my_t_p2">Ateeq</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">FSE</p>
                        </div>
                      </div>
                      <div className="my_t_col12">
                        <div className="my_t_row3">
                          <p className="my_t_p2">fse105</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">FSE Id</p>
                        </div>
                      </div>
                      <div className="my_t_col13">
                        <div className="my_t_row3">
                          <p className="my_t_p4">Bangalore</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">Location</p>
                        </div>
                      </div>
                      <div className="my_t_col14">
                        <div className="my_t_row3">
                          <p className="my_t_p5">Ankita</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">TL name</p>
                        </div>
                      </div>
                      <div className="my_t_col15">
                        <div className="my_t_row3">
                          <p className="my_t_p6">100</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">Leads</p>
                        </div>
                      </div>
                      <div className="my_t_col16">
                        <div className="my_t_row3">
                          <p className="my_t_p8">80</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">QC Conversion</p>
                        </div>
                      </div>
                      <div className="my_t_col18">
                        <div className="my_t_row3">
                          <p className="my_t_p10">03/04</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p11">WR Meet</p>
                        </div>
                      </div>
                      <div className="my_t_col17">
                        <div className="my_t_row3">
                          <p className="my_t_p9">60%</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">Attendance</p>
                        </div>
                      </div>

                      <div className="my_t_col19">
                        <div className="my_t_row4">
                          <input
                            type="button"
                            value="View Roaster"
                            className="add_tl_save_button"
                            onClick={() => handleClick1()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="my_t_c_card">
                      <div className="my_t_col0">
                        <img
                          src={
                            window.location.origin + "/images/profile image.svg"
                          }
                          alt="profile"
                          className="prof_img"
                        />
                      </div>
                      <div className="my_t_col11">
                        <div className="my_t_row3">
                          <p className="my_t_p2">Ateeq</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">FSE</p>
                        </div>
                      </div>
                      <div className="my_t_col12">
                        <div className="my_t_row3">
                          <p className="my_t_p2">fse105</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">FSE Id</p>
                        </div>
                      </div>
                      <div className="my_t_col13">
                        <div className="my_t_row3">
                          <p className="my_t_p4">Bangalore</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">Location</p>
                        </div>
                      </div>
                      <div className="my_t_col14">
                        <div className="my_t_row3">
                          <p className="my_t_p5">Ankita</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p3">TL name</p>
                        </div>
                      </div>
                      <div className="my_t_col15">
                        <div className="my_t_row3">
                          <p className="my_t_p6">100</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">Leads</p>
                        </div>
                      </div>
                      <div className="my_t_col16">
                        <div className="my_t_row3">
                          <p className="my_t_p8">80</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">QC Conversion</p>
                        </div>
                      </div>
                      <div className="my_t_col18">
                        <div className="my_t_row3">
                          <p className="my_t_p10">03/04</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p11">WR Meet</p>
                        </div>
                      </div>
                      <div className="my_t_col17">
                        <div className="my_t_row3">
                          <p className="my_t_p9">60%</p>
                        </div>
                        <div className="my_t_row3">
                          <p className="my_t_p7">Attendance</p>
                        </div>
                      </div>

                      <div className="my_t_col19">
                        <div className="my_t_row4">
                          <input
                            type="button"
                            value="View Roaster"
                            className="add_tl_save_button"
                            onClick={() => handleClick1()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="my_statics_data"
            id="my_statics_data"
            style={{ display: "none" }}
          >
            <div className="my_stats_row1">
              <div className="my_stats_main_card">
                <img
                  src={window.location.origin + "/images/TOTAL LEADS.svg"}
                  alt="profile"
                  className="my_stat_card"
                />
                <div className="my_stats_card1">
                  <p className="my_stats_p1">Total Leads</p>
                  <p className="my_stats_p2">5500</p>
                </div>
              </div>
              <div className="my_stats_main_card1">
                <img
                  src={window.location.origin + "/images/QC PASSED MY STA.svg"}
                  alt="profile"
                  className="my_stat_card1"
                />
                <div className="my_stats_card2">
                  <p className="my_stats_p3">QC Passed</p>
                  <p className="my_stats_p4">5000</p>
                </div>
              </div>
              <div className="my_stats_main_card1">
                <img
                  src={window.location.origin + "/images/redo_my stat.svg"}
                  alt="profile"
                  className="my_stat_card1"
                />
                <div className="my_stats_card2">
                  <p className="my_stats_p3">Redo</p>
                  <p className="my_stats_p4">500</p>
                </div>
              </div>
              <div className="my_stats_main_card1">
                <img
                  src={window.location.origin + "/images/rejected_ my stat.svg"}
                  alt="profile"
                  className="my_stat_card1"
                />
                <div className="my_stats_card2">
                  <p className="my_stats_p3">Rejected</p>
                  <p className="my_stats_p4">200</p>
                </div>
              </div>
            </div>
            <div className="my_stats_row2">
              <div className="my_stats_col1">
                <Chart
                  options={apexBar.options}
                  series={apexBar.series}
                  type="bar"
                  width="100%"
                />
              </div>
              <div className="my_stats_col2">
                <p className="my_stats_p5">Area Leads</p>
                <Chart
                  options={apexPie.options}
                  series={apexPie.series}
                  type="pie"
                  width="350"
                />
              </div>
            </div>
            <div className="my_stats_row3">
              <p className="my_stats_p5">ASM (Area sales Manager) list</p>
            </div>
            <div className="my_stats_row4">
              <div className="my_stats_card">
                <div className="my_s_col0">
                  <img
                    src={window.location.origin + "/images/profile image.svg"}
                    alt="profile"
                    className="prof_img"
                  />
                </div>
                <div className="my_s_col1">
                  <p className="my_s_p5">Aman Singh</p>
                  <p className="my_s_p6">ASM</p>
                </div>
                <div className="my_s_col2">
                  <p className="my_s_p7">BLR-North</p>
                  <p className="my_s_p6">Zone</p>
                </div>
                <div className="my_s_col2">
                  <p className="my_s_p7">Bangalore</p>
                  <p className="my_s_p6">City</p>
                </div>
                <div className="my_s_col2">
                  <p className="my_s_p8">23000</p>
                  <p className="my_s_p6">Points allocated</p>
                </div>
                <div className="my_s_col2">
                  <p className="my_s_p9">18000</p>
                  <p className="my_s_p6">Points achieved</p>
                </div>
                <div className="my_s_col3">
                  <div className="my_stats_row5">
                    <p className="my_s_p10">Team lead name 1</p>
                    <div className="my_statics_progress">
                      <div
                        className="my_statics_progress_bar"
                        role="progressbar"
                        aria-valuenow="70"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "70%" }}
                      ></div>
                    </div>
                  </div>
                  <div className="my_stats_row5">
                    <p className="my_s_p10">Team lead name 2</p>
                    <div className="my_statics_progress">
                      <div
                        className="my_statics_progress_bar"
                        role="progressbar"
                        aria-valuenow="70"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "70%" }}
                      ></div>
                    </div>
                  </div>
                  <div className="my_stats_row5">
                    <p className="my_s_p10">Team lead name 3</p>
                    <div className="my_statics_progress">
                      <div
                        className="my_statics_progress_bar"
                        role="progressbar"
                        aria-valuenow="70"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "70%" }}
                      ></div>
                    </div>
                  </div>
                  <div className="my_stats_row5">
                    <p className="my_s_p10">Team lead name 4</p>
                    <div className="my_statics_progress">
                      <div
                        className="my_statics_progress_bar"
                        role="progressbar"
                        aria-valuenow="70"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "70%" }}
                      ></div>
                    </div>
                  </div>
                  <div className="my_stats_row5">
                    <p className="my_s_p10">Team lead name 5</p>
                    <div className="my_statics_progress">
                      <div
                        className="my_statics_progress_bar"
                        role="progressbar"
                        aria-valuenow="70"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "70%" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my_stats_card">
                <div className="my_s_col0">
                  <img
                    src={window.location.origin + "/images/profile image.svg"}
                    alt="profile"
                    className="prof_img"
                  />
                </div>
                <div className="my_s_col1">
                  <p className="my_s_p5">Aman Singh</p>
                  <p className="my_s_p6">ASM</p>
                </div>
                <div className="my_s_col2">
                  <p className="my_s_p7">BLR-North</p>
                  <p className="my_s_p6">Zone</p>
                </div>
                <div className="my_s_col2">
                  <p className="my_s_p7">Bangalore</p>
                  <p className="my_s_p6">City</p>
                </div>
                <div className="my_s_col2">
                  <p className="my_s_p8">23000</p>
                  <p className="my_s_p6">Points allocated</p>
                </div>
                <div className="my_s_col2">
                  <p className="my_s_p9">18000</p>
                  <p className="my_s_p6">Points achieved</p>
                </div>
                <div className="my_s_col3">
                  <div className="my_stats_row5">
                    <p className="my_s_p10">Team lead name 1</p>
                    <div className="my_statics_progress">
                      <div
                        className="my_statics_progress_bar"
                        role="progressbar"
                        aria-valuenow="70"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "70%" }}
                      ></div>
                    </div>
                  </div>
                  <div className="my_stats_row5">
                    <p className="my_s_p10">Team lead name 2</p>
                    <div className="my_statics_progress">
                      <div
                        className="my_statics_progress_bar"
                        role="progressbar"
                        aria-valuenow="70"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "70%" }}
                      ></div>
                    </div>
                  </div>
                  <div className="my_stats_row5">
                    <p className="my_s_p10">Team lead name 3</p>
                    <div className="my_statics_progress">
                      <div
                        className="my_statics_progress_bar"
                        role="progressbar"
                        aria-valuenow="70"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "70%" }}
                      ></div>
                    </div>
                  </div>
                  <div className="my_stats_row5">
                    <p className="my_s_p10">Team lead name 4</p>
                    <div className="my_statics_progress">
                      <div
                        className="my_statics_progress_bar"
                        role="progressbar"
                        aria-valuenow="70"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "70%" }}
                      ></div>
                    </div>
                  </div>
                  <div className="my_stats_row5">
                    <p className="my_s_p10">Team lead name 5</p>
                    <div className="my_statics_progress">
                      <div
                        className="my_statics_progress_bar"
                        role="progressbar"
                        aria-valuenow="70"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "70%" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my_stats_card">
                <div className="my_s_col0">
                  <img
                    src={window.location.origin + "/images/profile image.svg"}
                    alt="profile"
                    className="prof_img"
                  />
                </div>
                <div className="my_s_col1">
                  <p className="my_s_p5">Aman Singh</p>
                  <p className="my_s_p6">ASM</p>
                </div>
                <div className="my_s_col2">
                  <p className="my_s_p7">BLR-North</p>
                  <p className="my_s_p6">Zone</p>
                </div>
                <div className="my_s_col2">
                  <p className="my_s_p7">Bangalore</p>
                  <p className="my_s_p6">City</p>
                </div>
                <div className="my_s_col2">
                  <p className="my_s_p8">23000</p>
                  <p className="my_s_p6">Points allocated</p>
                </div>
                <div className="my_s_col2">
                  <p className="my_s_p9">18000</p>
                  <p className="my_s_p6">Points achieved</p>
                </div>
                <div className="my_s_col3">
                  <div className="my_stats_row5">
                    <p className="my_s_p10">Team lead name 1</p>
                    <div className="my_statics_progress">
                      <div
                        className="my_statics_progress_bar"
                        role="progressbar"
                        aria-valuenow="70"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "70%" }}
                      ></div>
                    </div>
                  </div>
                  <div className="my_stats_row5">
                    <p className="my_s_p10">Team lead name 2</p>
                    <div className="my_statics_progress">
                      <div
                        className="my_statics_progress_bar"
                        role="progressbar"
                        aria-valuenow="70"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "70%" }}
                      ></div>
                    </div>
                  </div>
                  <div className="my_stats_row5">
                    <p className="my_s_p10">Team lead name 3</p>
                    <div className="my_statics_progress">
                      <div
                        className="my_statics_progress_bar"
                        role="progressbar"
                        aria-valuenow="70"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "70%" }}
                      ></div>
                    </div>
                  </div>

                  <div className="my_stats_row5">
                    <p className="my_s_p10">Team lead name 4</p>
                    <div className="my_statics_progress">
                      <div
                        className="my_statics_progress_bar"
                        role="progressbar"
                        aria-valuenow="70"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: "70%" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="setting_data"
            id="setting_data"
            style={{ display: "none" }}
          >
            <div className="setting_card">
              <div className="setting_row1">
                <p className="setting_p1">Update Training document</p>
                <div className="setting_col1">
                  <img
                    src={window.location.origin + "/images/EDIT.svg"}
                    alt="profile"
                    className="edit_pro"
                  />
                </div>
              </div>
            </div>
            <div className="setting_card">
              <div className="setting_row1">
                <p className="setting_p1">Set up training questions</p>
                <div className="setting_col1">
                  <img
                    src={window.location.origin + "/images/EDIT.svg"}
                    alt="profile"
                    className="edit_pro"
                  />
                </div>
              </div>
            </div>
            {/* <div className="setting_card">
              <div className="setting_row1">
                <p className="setting_p1">Update Trainer</p>
                <div className="setting_col1">
                  <img
                    src={window.location.origin + "/images/EDIT.svg"}
                    alt="profile"
                    className="edit_pro"
                  />
                </div>
              </div>
            </div>
            <div className="setting_card">
              <div className="setting_row1">
                <p className="setting_p1">Setup trainer date</p>
                <div className="setting_col1">
                  <img
                    src={window.location.origin + "/images/EDIT.svg"}
                    alt="profile"
                    className="edit_pro"
                  />
                </div>
              </div>
            </div> */}
            <div className="setting_card">
              <div className="setting_row1">
                <p className="setting_p1">Launch project</p>
                <div className="setting_col1">
                  <img
                    src={window.location.origin + "/images/EDIT.svg"}
                    alt="profile"
                    className="edit_pro"
                  />
                </div>
              </div>
            </div>
            <div className="setting_card">
              <div className="setting_row1">
                <p className="setting_p1">Run payroll</p>
                <div className="setting_col1">
                  <img
                    src={window.location.origin + "/images/EDIT.svg"}
                    alt="profile"
                    className="edit_pro"
                  />
                </div>
              </div>
            </div>
            <div className="setting_card">
              <div className="setting_row1">
                <p className="setting_p1">Upload report</p>
                <div className="setting_col1">
                  <img
                    src={window.location.origin + "/images/EDIT.svg"}
                    alt="profile"
                    className="edit_pro"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d1_col2">
          <div
            className="add_tl_card"
            id="add_tl_data"
            style={{ display: "block" }}
          >
            <div className="d1_row41">
              <div className="d1_number1">1</div>
              <p className="d1_p411">Add TL</p>
            </div>
            <div className="d1_row42">
              <p className="d1_p22">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
                dolores nemo qui maiores exercitationem quos recusandae
                accusantium, eius alias magnam rerum, provident, numquam
                tempora. Facilis porro soluta minima natus corporis!
              </p>
            </div>
            <div className="d1_row43" id="add_tl_buttons">
              <div className="buttons1" onClick={() => handleClick1()}>
                Not now
              </div>
              <div className="buttons2" onClick={() => handleClick2()}>
                <img
                  src={window.location.origin + "/images/add TL.svg"}
                  alt="profile"
                  className="add_img"
                />
                <p className="d1_p42">Add TL</p>
              </div>
            </div>
          </div>
          <div
            className="d1_row4"
            id="add_tl"
            onClick={(id) => handleClick(id)}
            style={{ display: "none" }}
          >
            <div className={!active ? "d1_number" : "d1_number1"}>1</div>
            <p className={!active ? "d1_p41" : "d1_p411"}>Add TL</p>
          </div>
          <div
            className="add_tl_card"
            id="update_traine_data"
            style={!active ? { display: "none" } : { display: "block" }}
          >
            <div className="d1_row41" style={{ display: "flex" }}>
              <div className="d1_number1">2</div>
              <p className="d1_p411">Update Training document</p>
            </div>
            <div className="d1_row42">
              <p className="d1_p22">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
                dolores nemo qui maiores exercitationem quos recusandae
                accusantium, eius alias magnam rerum, provident, numquam
                tempora. Facilis porro soluta minima natus corporis!
              </p>
            </div>
            <div className="update_next_button" id="update_buttons">
              {/* 
              className="d1_row43"
              <div className="buttons1" onClick={() => handleClick7()}>
                Not now
              </div> */}
              <div className="buttons5" onClick={() => handleClick8()}>
                Update document
              </div>
            </div>
          </div>
          <div className="d1_row4" id="update_training">
            <div
              className={
                !active1
                  ? `${!active4 ? "d1_number" : "d1_number2"}`
                  : "d1_number1"
              }
            >
              2
            </div>
            <p
              className={
                !active1 ? `${!active4 ? "d1_p41" : "d1_p412"}` : "d1_p411"
              }
            >
              Update Training document
            </p>
          </div>
          <div
            className="add_tl_card"
            id="setup_traine_data"
            style={{ display: "none" }}
          >
            <div className="d1_row41" style={{ display: "flex" }}>
              <div className="d1_number1">3</div>
              <p className="d1_p411">Setup Training Questions</p>
            </div>
            <div className="d1_row42">
              <p className="d1_p22">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
                dolores nemo qui maiores exercitationem quos recusandae
                accusantium, eius alias magnam rerum, provident, numquam
                tempora. Facilis porro soluta minima natus corporis!
              </p>
            </div>
            <div className="update_next_button" id="set_train_buttons">
              {/* <div className="buttons1" onClick={() => handleClick10()}>
                Not now
              </div> */}
              <div className="buttons2" onClick={() => handleClick11()}>
                <p className="d1_p42">Add questions</p>
              </div>
            </div>
          </div>
          <div className="d1_row4" id="setup_training">
            <div
              className={
                !active2
                  ? `${!active5 ? "d1_number" : "d1_number2"}`
                  : "d1_number1"
              }
            >
              3
            </div>
            <p
              className={
                !active2 ? `${!active5 ? "d1_p41" : "d1_p412"}` : "d1_p411"
              }
            >
              Setup Training Questions
            </p>
          </div>
          {/* <div
            className="add_tl_card"
            id="update_data"
            style={{ display: "none" }}
          >
            <div className="d1_row41">
              <div className="d1_number1">4</div>
              <p className="d1_p411">Update trainer</p>
            </div>
            <div className="d1_row42">
              <p className="d1_p22">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
                dolores nemo qui maiores exercitationem quos recusandae
                accusantium, eius alias magnam rerum, provident, numquam
                tempora. Facilis porro soluta minima natus corporis!
              </p>
            </div>
            <div className="d1_row43" id="update_tainer_buttons">
              <div className="buttons3" onClick={() => handleClick7()}>
                Not now
              </div>
              <div className="buttons4" onClick={() => handleClick8()}>
                Request for trainer
              </div>
            </div>
            <div
              className="update_next_button"
              id="update_tainer_next_buttons"
              style={{ display: "none" }}
            >
              <div className="buttons5" onClick={() => handleClick9()}>
                Next
              </div>
            </div>
          </div>
          <div className="d1_row4" id="update4_trainer">
            <div className={!active3 ? "d1_number" : "d1_number1"}>4</div>
            <p
              className={
                !active3 ? `${!active4 ? "d1_p41" : "d1_p412"}` : "d1_p411"
              }
            >
              Update trainer
            </p>
          </div>
          <div
            className="add_tl_card"
            id="setup_date_data"
            style={{ display: "none" }}
          >
            <div className="d1_row41">
              <div className="d1_number1">5</div>
              <p className="d1_p411">Setup trainer Date</p>
            </div>
            <div className="d1_row42">
              <p className="d1_p22">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
                dolores nemo qui maiores exercitationem quos recusandae
                accusantium, eius alias magnam rerum, provident, numquam
                tempora. Facilis porro soluta minima natus corporis!
              </p>
            </div>
            <div className="d1_row43" id="add_tl_buttons">
              <div className="buttons1" onClick={() => handleClick()}>
                Not now
              </div>
              <div
                className="buttons2"
                onClick={() => handleClick()}
                id="set_date_button"
              >
                Setup date
              </div>
            </div>
          </div>
          <div className="d1_row4" id="setup_date_trainer">
            <div className={!active5 ? "d1_number" : "d1_number1"}>5</div>
            <p
              className={
                !active5 ? `${!active6 ? "d1_p41" : "d1_p412"}` : "d1_p411"
              }
            >
              Setup trainer Date
            </p>
          </div> */}
          <div
            className="add_tl_card"
            id="launch_project_data"
            style={{ display: "none" }}
          >
            <div className="d1_row41" style={{ display: "flex" }}>
              <div className="d1_number1">4</div>
              <p className="d1_p411">Launch project</p>
            </div>
            <div className="d1_row42">
              <p className="d1_p22">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
                dolores nemo qui maiores exercitationem quos recusandae
                accusantium, eius alias magnam rerum, provident, numquam
                tempora. Facilis porro soluta minima natus corporis!
              </p>
            </div>
            <div className="update_next_button" id="update_tainer_next_buttons">
              <div className="buttons5" onClick={() => handleClick9()}>
                Add Date
              </div>
            </div>
          </div>
          <div className="d1_row4" id="launch_project">
            <div className={!active3 ? "d1_number" : "d1_number1"}>4</div>
            <p className={!active3 ? "d1_p41" : "d1_p411"}>Launch project</p>
          </div>
          <div
            className="add_tl_card"
            id="run_payroll_data"
            onClick={() => handleClick22()}
            style={{ display: "none" }}
          >
            <div className="d1_row41" style={{ display: "flex" }}>
              <div className="d1_number1">5</div>
              <p className="d1_p411">Run payroll</p>
            </div>
            <div className="d1_row42">
              <p className="d1_p22">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
                dolores nemo qui maiores exercitationem quos recusandae
                accusantium, eius alias magnam rerum, provident, numquam
                tempora. Facilis porro soluta minima natus corporis!
              </p>
            </div>
            <div className="update_next_button" id="update_tainer_next_buttons">
              <input
                type="button"
                value="Request for payroll"
                className={!active6 ? "up_t_save_button" : "run_pay_button"}
                onClick={() => handleClick21()}
                disabled={active6}
              />
            </div>
          </div>
          <div
            className="d1_row4"
            id="run_payroll"
            onClick={() => handleClick12()}
          >
            <div className={!active8 ? "d1_number" : "d1_number1"}>5</div>
            <p className={!active8 ? "d1_p41" : "d1_p411"}>Run payroll</p>
          </div>
          <div
            className="add_tl_card"
            id="upload_report_data"
            style={{ display: "none" }}
          >
            <div className="d1_row41" style={{ display: "flex" }}>
              <div className="d1_number1">6</div>
              <p className="d1_p411">Upload report</p>
            </div>
            <div className="d1_row42">
              <p className="d1_p22">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
                dolores nemo qui maiores exercitationem quos recusandae
                accusantium, eius alias magnam rerum, provident, numquam
                tempora. Facilis porro soluta minima natus corporis!
              </p>
            </div>
          </div>
          <div className="d1_row4" id="upload_report">
            <div className={!active9 ? "d1_number" : "d1_number1"}>6</div>
            <p className={!active9 ? "d1_p41" : "d1_p411"}>Upload report</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dash1;
