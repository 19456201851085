
  
const leadRegisterInitialState=[]

const leadRegisterReducer=(state=leadRegisterInitialState,action)=>{
    switch(action.type){
      case "LEAD_REGISTER_DATA":{
          return [...action.payload]
      }
      default:{
          return [...state]
      }
  }
}

export default leadRegisterReducer