import axios from "../../config/axios"

const getProjectList = (tl) => {
  return { type: "PROJECT_LIST_DATA", payload: tl };
};

const startGetProjectList = (id,type) => {
  return (dispatch) => {

    const loadData = async () => {
      try {
        if(type===undefined){
          type=1
        }
        const inputData={
          "id": `${id}`,//id "3",
          "type": type
      }
      // console.log(inputData, " inside data")
        const TotalData=await axios.post(`/projects_list`,inputData)
        .then(res=> {
          const dataDispatch = res.data.projects
          dispatch(getProjectList(dataDispatch))  
          // console.log(dataDispatch , " inside proj list proj")
        })
        .catch(err=> {
          console.error(err)
        })
      } catch (err) {
        console.error(err);
      }
    };
    loadData();
  };
};



export { getProjectList, startGetProjectList  };
