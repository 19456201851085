import axios from "../../config/axios"

const getProjectDetaill = (tl) => {
  return { type: "PROJECT_DETAIL_DATA", payload: tl };
};

const startGetProjectDetaill = (sow) => {
  return (dispatch) => {

    const loadData = async () => {
      try {
        const dataList = {
          "sow_id": sow
      }
      // console.log(dataList,"data")

        const TotalData=await axios.post(`/project_details`,dataList)
        .then(res=> {
            // console.log(res.data,"project Detail")
            dispatch(getProjectDetaill(res.data))  
    
        })
        .catch (err=> {
            console.log(err)
        })
      } catch (err) {
        console.error(err);
      }
    };
    loadData();
  };
};



export { getProjectDetaill, startGetProjectDetaill  };
