import axios from "../../config/axios"
import { startGetProjectList } from "./projectList";

const getEmailCheck = (tl) => {
  return { type: "EMAIL_VERIFY", payload: tl };
};

const startGetEmailCheck = (email) => {
  return (dispatch) => {

    const loadData = async () => {
      try {
        const emailData = {
            "email_id": email
        }
        const TotalData=await axios.post(`/check_email`,emailData)
        .then(res=>{
          // console.log(res.data,"inside email checkin")
          dispatch(getEmailCheck(res.data.user)) 
          dispatch(startGetProjectList(res.data.user.id));
        })
        .catch(err=>{
          console.log(err)
        })
      } catch (err) {
        console.error(err);
      }
    };
    loadData();
  };
};



export { getEmailCheck, startGetEmailCheck  };
