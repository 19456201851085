import axios from "../../config/axios"

const getAsmOverView = (tl) => {
  return { type: "ASM_OVERVIEW_DATA", payload: tl };
};

const startGetAsmOverView = (sow) => {
  return (dispatch) => {

    const loadData = async () => {
      try {
        const dataInsert = {
          "sow_id": sow
      }
    //   console.log(dataInsert,"inside sow chart")

      const TotalData=await axios.post(`sow_daily_stats`,dataInsert)
      .then(res=>{
        console.log(res.data)
        const leads= []
        const checkin = []
        const approve = []
        res.data.asm_stats.map(data=>{
          leads.push(data.total_leads)
          checkin.push(data.total_checkin)
          approve.push(data.total_approved_leads)
        })
        const series = [
          {
            name: "Leads",
            data: leads,
          },
          {
            name: "Approved",
            data: approve,
          },
          {
            name: "Checkins",
            data: checkin,
          },
        ]
        // console.log(series,"inside sow chart")
        dispatch(getAsmOverView(series))  
  
  
  
      })
      .catch(err=>{
        console.log(err)
      })
      } catch (err) {
        console.error(err);
      }
    };
    loadData();
  };
};



export { getAsmOverView, startGetAsmOverView  };
