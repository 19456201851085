import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import loggedinReducer from "../reducer/loggedin";
import projectListReducer from "../reducer/projectList";
import myStatsReducer from "../reducer/myStats";
import myTeamReducer from "../reducer/myTeam";
import asmCheckinReducer from "../reducer/asmCheckin";
import leadRegisterReducer from "../reducer/leadRegister";
import totalAttendanceReducer from "../reducer/totalAttendance";
import daywiseOverviewReducer from "../reducer/daywiseOverview";
import asmListReducer from "../reducer/asmList";
import asmDetailReducer from "../reducer/asmDetail";
import fseDetailReducer from "../reducer/fseDetail";
import projectDetailReducer from "../reducer/projectDetail";
import checkEmailReducer from "../reducer/checkEmail";
import fseOverViewReducer from "../reducer/fseOverview";
import asmChartReducer from "../reducer/asmChart";
import calenderReducer from "../reducer/calender";
import tableApiReducer from "../reducer/tableApi";
import allLeadsReducer from "../reducer/allLeads";
import merchantDetailReducer from "../reducer/merchantDetail";
import searchLeadsReducer from "../reducer/searchLeads";
import leadConvStatsReducer from "../reducer/leadConvStats";

const configureStore = () => {
  const store = createStore(
    combineReducers({
      loggedin: loggedinReducer,
      projectList: projectListReducer,
      myStats: myStatsReducer,
      myTeam: myTeamReducer,
      asmCheckin: asmCheckinReducer,
      leadRegister: leadRegisterReducer,
      totalAttendance: totalAttendanceReducer,
      daywiseOverview: daywiseOverviewReducer,
      asmList: asmListReducer,
      asmDetail: asmDetailReducer,
      fseDetail: fseDetailReducer,
      projectDetail: projectDetailReducer,
      checkEmail: checkEmailReducer,
      fseOverview: fseOverViewReducer,
      asmChart: asmChartReducer,
      calender: calenderReducer,
      tableApi: tableApiReducer,
      allLeads: allLeadsReducer,
      merchantDetail: merchantDetailReducer,
      searchLeads: searchLeadsReducer,
      leadConvStats: leadConvStatsReducer
    }),
    applyMiddleware(thunk)
  );
  return store;
};

export default configureStore;
