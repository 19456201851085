import React, { useContext, useEffect } from "react";
import Chart from "react-apexcharts";
import { connect } from "react-redux";

// import axios from "../../config/axios"

import { startGetMyStats } from "../action/myStats";
import { startGetMyTeam } from "../action/myTeam";
import { apexMultiline } from "../../ApexOptions";
import {CmContext} from "./../../App";
// import { useParams } from "react-router";

// import startGetMyStats from "../action/myStats"

import Loader from "../Loader/Loader";

import {
  apexMystatPie,
  apexBar2,
  apexMystatLine,
  // apexMultiline,
  // apexSideBar,
} from "../../ApexOptions";

import "./MyStats.css";
import { startGetAsmCheckin } from "../action/asmCheckin";
import { startGetLeadRegister } from "../action/leadRegister";
import { startGetProjectDetaill } from "../action/projectDetail";
import { startGetTotalAttendance } from "../action/totalAttendance";
import { startGetAsmOverView } from "../action/asmChart";

function MyStats(props) {
  const [context,setContext]=useContext(CmContext);
  useEffect(() => {
    const id = localStorage.getItem("sow_id");
    if (Object.keys(props.myStats).length === 0) {
      props.dispatch(startGetMyStats(id));
      props.dispatch(startGetMyTeam(id));
      props.dispatch(startGetAsmCheckin(id));
      props.dispatch(startGetLeadRegister(id));
      props.dispatch(startGetProjectDetaill(id));
      props.dispatch(startGetTotalAttendance(id));
      props.dispatch(startGetAsmOverView(id));
    }
  }, []);

  useEffect(()=>{
    context.sidebarS=context.sidebarS.fill(true);
    context.sidebarS[2]=false;
    setContext({...context})
  },[])

  const AsmChart = {
    colors: ["#90B8EF", "#5860BA", "#B4FFC2"],
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return (
          val +
          " - " +
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          ""
        );
      },
      position: "top",
      horizontalAlign: "right",
    },
    xaxis: {
      categories: props.leadRegister[1],
    },
    yaxis: {
      title: {
        text: "Total leads",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      enabled: true,
    },
  };

  // console.log(props.leadRegister,"hourly chart")
  const chartPie = {
    colors: ["#9d81fc", "#855bf5", "#673695"],

    chart: {
      width: "100%",
      type: "pie",
    },
    labels: props.asmCheckin[1],
    // theme: {
    //   monochrome: {
    //     enabled: true,
    //   },
    // },
    plotOptions: {
      pie: {
        dataLabels: {
          style: {
            colors: [
              "#358ABF",
              "#267AAF",
              "#1773AD",
              "#2076AC",
              "#2D80B4",
              "#3285B9",
            ],
          },
        },
      },
    },

    legend: {
      show: false,
    },
  };

  const leadChart = {
    colors: ["#9d81fc", "#855bf5", "#673695"],

    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#252733"],
        backgroundColor: "9D81FF",
        padding: "2px 5px",
      },
    },

    xaxis: {
      categories: props.leadRegister[1],
      position: "bottom",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#855bf5",
            colorTo: "#855bf5",
            stops: [0, 100],
            opacityFrom: 100,
            opacityTo: 500,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          return val;
        },
      },
      title: {
        enabled: false,
      },
    },
  };

  const checkinChart = {
    colors: ["#9d81fc", "#855bf5", "#673695"],

    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: props.totalAttendance[1],
    },
  };

  // console.log(props.totalAttendance,"something in chart")
  return (
    <div className="center">
      {Object.keys(props.myStats).length > 0 ? (
        <div className="mystats">
          <div className="mystat_row1">
            <div
              className="mystat_cards"
              style={{
                backgroundImage: `url(${
                  window.location.origin + "/images/svg/tatal_leads.svg"
                })`,
              }}
            >
              <h3 className="mystat_h3">Total Leads</h3>
              <h2 className="mystat_h2">{props.myStats.total_leads}</h2>
              <p className="mystat_p1">+{props.myStats.total_leads_today}</p>
            </div>

            <div
              className="mystat_cards"
              style={{
                backgroundImage: `url(${
                  window.location.origin + "/images/svg/QC_PASSED.svg"
                })`,
              }}
            >
              <h3 className="mystat_h3">QC Approved</h3>
              <h2 className="mystat_h2" style={{ color: "#118026" }}>
                {props.myStats.total_qc_passed}
              </h2>
              <p className="mystat_p1" style={{ background: "#CAEFE5" }}>
                +{props.myStats.total_qc_passed_today}
              </p>
            </div>
            <div
              className="mystat_cards"
              style={{
                backgroundImage: `url(${
                  window.location.origin + "/images/svg/REJECTED.svg"
                })`,
              }}
            >
              <h3 className="mystat_h3">QC Rejected</h3>
              <h2 className="mystat_h2" style={{ color: "#F75D5D" }}>
                {props.myStats.total_qc_rejected}
              </h2>
              <p className="mystat_p1" style={{ background: "#fedbdb" }}>
                +{props.myStats.total_qc_rejected_today}
              </p>
            </div>
            <div
              className="mystat_cards"
              style={{
                backgroundImage: `url(${
                  window.location.origin + "/images/svg/Total_points.svg"
                })`,
              }}
            >
              <h3 className="mystat_h3">Total Checkin</h3>
              <h2
                className="mystat_h2"
                style={{ color: "#FFB100", lineHeight: "46px" }}
              >
                {props.myStats.total_checkin}
              </h2>
              {/* <div className="small_checkin_box">
            <div className="small_xhexkin_xol1">
              <p className="small_checkin_p1">
                FD
              </p>
              <p className="small_checkin_p2">
                {props.myStats.total_fd}
              </p>
            </div>
            <div className="small_xhexkin_xol1">
            <p className="small_checkin_p1">
                HD
              </p>
              <p className="small_checkin_p2">
                {props.myStats.total_hd}
              </p>
            </div>
            <div className="small_xhexkin_xol1">
            <p className="small_checkin_p1">
                Star
              </p>
              <p className="small_checkin_p2">
                {props.myStats.total_star}
              </p>
            </div>
          </div> */}
              <p className="mystat_p1" style={{ background: "#ffddb2" }}>
                +
                {props.myStats.total_checkin_today
                  ? props.myStats.total_checkin_today
                  : 0}
              </p>
            </div>
          </div>
          <div className="mystat_row3">
            <div className="mystat_card">
              {/* <div className="mystat_card_row1">
            <div className="mystat_card1">
              <p className="mystat_p2">ASM-wise checkin</p>
              {props.asmCheckin.length > 0 ? <Chart
                options={chartPie}
                series={props.asmCheckin[0]}
                labels = {props.asmCheckin[1]}
                type="pie"
                width="350"
              />: <Chart
              options={apexMystatPie.options}
              series={apexMystatPie.series}
              type="pie"
              width="350"
            />}
            </div>
            <div className="mystat_card1">
              <p className="mystat_p2">Lead registration</p>
              {props.leadRegister.length > 0 ? <Chart
                options={leadChart}
                series={[props.leadRegister[0]]}
                type="bar"
                height={250}
                // width={400}
              />:<Chart
              options={apexBar2.options}
              series={apexBar2.series}
              type="bar"
              height={250}
              // width={400}
            />}
            </div>
            <div className="mystat_card1" style={{ borderRight: "none" }}>
              <p className="mystat_p2">Total Attendence</p>
              {props.totalAttendance.length > 0 ?
                <Chart
                options={checkinChart}
                series={[props.totalAttendance[0]]}
                type="area"
                height={250}
              />:<Chart
              options={apexMystatLine.options}
              series={apexMystatLine.series}
              type="area"
              height={250}
            />}
            </div>
          </div> */}
              <div className="mystat_card_row1">
                <div className="myTeam_card">
                  <p className="mystat_p2" style={{ marginTop: "15px" }}>
                    Hourly overview
                  </p>

                  {props.leadRegister.length > 0 ? <Chart
                options={AsmChart}
                series={props.leadRegister[0]}
                type="bar"
                height={250}
                // width={400}
              />:<Chart
              options={apexBar2.options}
              series={apexBar2.series}
              type="bar"
              height={250}
              // width={400}
            />}
                </div>
              </div>

              <div className="mystat_card_row1">
                <div className="myTeam_card">
                  <p className="mystat_p2" style={{ marginTop: "15px" }}>
                    Monthly overview
                  </p>

                  {props.asmChart.length > 0 ? (
                    <Chart
                      options={apexMultiline.options}
                      series={props.asmChart}
                      type="line"
                      height={250}
                    />
                  ) : (
                    <Chart
                      options={apexMultiline.options}
                      series={apexMultiline.series}
                      type="line"
                      height={250}
                    />
                  )}
                </div>
                {/* <div className="mystat_card2">
              <p className="mystat_p2" style={{ marginTop: "15px" }}>
                Stagewise Overview
              </p>
              <Chart
                options={apexSideBar.options}
                series={apexSideBar.series}
                type="bar"
                height={250}
              />
            </div> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loader">
          <Loader />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    myStats: state.myStats,
    asmCheckin: state.asmCheckin,
    leadRegister: state.leadRegister,
    totalAttendance: state.totalAttendance,
    asmChart: state.asmChart,
  };
};

export default connect(mapStateToProps)(MyStats);
