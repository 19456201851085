const checkEmail = "https://pabackend.taskmo.co/hr/rm/check_email";
const MAIN_STATS_URL = "https://isp.taskmo.in/hr/stats";
const CM_LIST_URL = "https://pabackend.taskmo.co/hr/rm/temp/cm_list";
const RM_URL = "https://pabackend.taskmo.co/hr/rm";
const ASM_LIST_URL = "https://isp.taskmo.in/hr/asm_list";
const ASM_LIST_URL2 = "https://pabackend.taskmo.co/hr/asm/list";
const ASM_LIST_COUNTS = "https://pabackend.taskmo.co/hr/asm/counts";
const ASM_LIST_CHECKINS = "https://pabackend.taskmo.co/hr/asm/checkins";
// const FSE_LIST_URL = "https://isp.taskmo.in/hr/fse_list";
const FSE_LIST_URL = "https://pabackend.taskmo.co/hr/fse/list";
const ASM_STATS_URL = "https://isp.taskmo.in/hr/asm_stats";
const CM_STATS_URL = "https://isp.taskmo.in/hr/cm_stats";
const CM_URL = "https://pabackend.taskmo.co/hr/cm";
const BASE_URL = "https://hr3.taskmo.com";
const CM_WISE_TODAY_CHECKINS =
  "https://pabackend.taskmo.co/hr/cm/checkin/today";
const CM_WISE_THIS_MONTH_CHECKINS =
  "https://pabackend.taskmo.co/hr/cm/checkin/this_month";
const CM_WISE_TODAY_LEADS = "https://pabackend.taskmo.co/hr/cm/leads/today";
const CM_WISE_THIS_MONTH_LEADS =
  "https://pabackend.taskmo.co/hr/cm/leads/this_month";
const FSE_CHART_URL = "https://pabackend.taskmo.co/hr/fse/chart";
const FSE_WISE_CHECKINS = "https://pabackend.taskmo.co/hr/fse/checkins";
const SEND_FINAL_OFFER_LETTER_URL =
  "https://isp.taskmo.in/fse/send_final_letter";
const TERMINATE_ASM_URL = "http://localhost:8002/v1/hr/asm/terminate";
const MARK_ACTIVE_URL =
  "https://pabackend.taskmo.co/hr/fse/onboardStatus/active";
const MARK_INACTIVE_URL =
  "https://pabackend.taskmo.co/hr/fse/onboardStatus/inactive";
const MARK_ASM_INACTIVE_URL = "https://pabackend.taskmo.co/hr/asm/inactive";
const MARK_ASM_ACTIVE_URL = "https://pabackend.taskmo.co/hr/asm/active";
const FSE_REMAP_URL = "https://pabackend.taskmo.co/hr/remap/fse";
const ASM_REMAP_URL = "https://pabackend.taskmo.co/hr/remap/asm";
const MARK_CM_INACTIVE_URL = "https://pabackend.taskmo.co/hr/cm/inactive";
const MARK_CM_ACTIVE_URL = "https://pabackend.taskmo.co/hr/cm/active";
const CM_stats_new = "https://farming-backend.taskmo.in/cm/stats";
const checkInvalid = (value) => {
  return value == null;
};
function formatAMPM(hhmmss) {
  let [hours, minutes] = hhmmss.split(":");
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}
const getXleads = (value) => {
  if (value < 3) {
    return value;
  } else if (value >= 3 && value < 5) {
    return value - 3;
  } else if (value >= 5) {
    return value - 5;
  }
  return value;
};
export {
  BASE_URL,
  checkEmail,
  MAIN_STATS_URL,
  CM_LIST_URL,
  RM_URL,
  ASM_LIST_URL,
  FSE_LIST_URL,
  ASM_STATS_URL,
  CM_STATS_URL,
  CM_URL,
  CM_WISE_TODAY_CHECKINS,
  CM_WISE_THIS_MONTH_CHECKINS,
  CM_WISE_TODAY_LEADS,
  CM_WISE_THIS_MONTH_LEADS,
  FSE_CHART_URL,
  FSE_WISE_CHECKINS,
  checkInvalid,
  formatAMPM,
  getXleads,
  SEND_FINAL_OFFER_LETTER_URL,
  TERMINATE_ASM_URL,
  ASM_LIST_URL2,
  ASM_LIST_COUNTS,
  MARK_ACTIVE_URL,
  MARK_INACTIVE_URL,
  MARK_ASM_INACTIVE_URL,
  MARK_ASM_ACTIVE_URL,
  ASM_LIST_CHECKINS,
  FSE_REMAP_URL,
  ASM_REMAP_URL,
  MARK_CM_INACTIVE_URL,
  MARK_CM_ACTIVE_URL,
  CM_stats_new,
};
