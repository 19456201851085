import * as React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { CmContext } from "../../../../App";

export default function DatePickerSoi() {
  const [value, setValue] = React.useState(null);
  const [context,setContext]=React.useContext(CmContext);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="Date of Joining"
        value={value}
        onChange={(newValue) => {
          console.log(newValue.toLocaleDateString('en-CA'),"doj selected for sending offer letter");

          setValue(newValue);
          context.soiDate=newValue.toLocaleDateString('en-CA');
          setContext({...context});
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider> 
  );
}
