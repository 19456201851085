  
const myStatsInitialState={}

const myStatsReducer=(state=myStatsInitialState,action)=>{
    switch(action.type){
      case "MY_STATS_DATA":{
          return {...action.payload}
      }
      case "RESET_MY_STATS_DATA" : {
          return {}
      }
      default:{
          return {...state}
      }
  }
}

export default myStatsReducer