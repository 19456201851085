import React, { useContext, useEffect } from 'react';
import "./Calender3.css";
import Calendar from 'calendar-reactjs'
import { CmContext } from '../../App';
export default function Calender3(){
  const [context,setContext]=useContext(CmContext);
  const myDay=(new Date()).getDate();
  useEffect(()=>{
    console.log(context.fseChartApi,'fseChartApi');
    
    context.daysAttendance.forEach((obj,index)=>{
      console.log("day",index);
      if(index+1>myDay){
        context.daysAttendance[index].status="";
      }else{
        context.daysAttendance[index].status="A";
      }
    })
  
    if(context.fseChartApi && context.fseChartApi.length>0){
      context.fseChartApi.forEach(({day,approved:count})=>{
        if(count===0){
          context.daysAttendance[day-1].status="A"
        }else if(count>0 && count <3){
          context.daysAttendance[day-1].status="P"
        }else if(count >= 3 && count < 5){
          context.daysAttendance[day-1].status="H"
        }else if(count>=5){
          context.daysAttendance[day-1].status="F"
        }
      })
    }
    setContext({...context});
  },[context.fseChartApi,context.daysAttendance]);
  return (
    <Calendar
      onCellClick={(val) => console.log(val)}
      month={{
        date: `${new Date().toLocaleDateString('en-CA')}`,
        days: context.daysAttendance
      }}
      emptyCellStyle={{ backgroundColor: 'white' }}
      status={
        {
          "F": {
            labelStyle: { backgroundColor: '#8dcc8d', color: 'black', borderRadius: '8px', padding: '0px 0px 3px 0px' }
          },
          "A": {
            labelStyle: { backgroundColor: '#ff8383', color: 'black', borderRadius: '8px', padding: '0px 0px 3px 0px' }
          },
          "H": {
            labelStyle: { backgroundColor: '#ffd26c', color: 'black', borderRadius: '8px', padding: '0px 0px 3px 0px' }
          },
          "P": {
            labelStyle: { backgroundColor: 'orange', color: 'black', borderRadius: '8px', padding: '0px 0px 3px 0px' }
          }
        }
      }
    />
  );
}