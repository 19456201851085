import Loader from "../Loader/Loader";
import "./ProjectList.css";
import { connect } from "react-redux";
import { useContext, useEffect } from "react";
import { CmContext } from "../../App";
import {projectListApi} from "./../SideBar/ApiCalls";
import {startGetMyStats} from "../action/myStats";
import { startGetMyTeam } from "../action/myTeam";
import { startGetAsmCheckin } from "../action/asmCheckin";
import { startGetLeadRegister } from "../action/leadRegister";
import { startGetProjectDetaill } from "../action/projectDetail";
import { startGetTotalAttendance } from "../action/totalAttendance";
import { startGetAsmOverView } from "../action/asmChart";
import { Link } from "react-router-dom";


function ProjectList(props) {
const [context,setContext]=useContext(CmContext);
useEffect(()=>{
  projectListApi(localStorage.getItem('hr_admin_id'),context,setContext);
  context.sidebarS=context.sidebarS.fill(true);
  context.sidebarS[1]=false;
  setContext({...context})
},[])

const handleView = (e) => {
  props.dispatch(startGetMyStats(e));
  // console.log(id,"button view")
  // console.log(e,"button view")
  localStorage.setItem("sow_id", e);
  props.dispatch(startGetMyTeam(e));
  props.dispatch(startGetAsmCheckin(e));
  props.dispatch(startGetLeadRegister(e));
  props.dispatch(startGetProjectDetaill(e));
  props.dispatch(startGetTotalAttendance(e));
  props.dispatch(startGetAsmOverView(e));
  context.sidebar2=true;
  setContext({...context});

};
  return (
    <>
      {Object.keys(context.projectList).length >= 1 ? (
        <div className="dashboard_total">
          <div className="dash_row2">
            <h3 className="dash_list_head">project list:</h3>
          </div>
          <div className="dash_row3">
            {context.projectList.map((proj) => (
              <div className="dash_card" key={proj.sow_id}>
                <div className="d_row1">
                  <img
                    src={proj.brand_logo
                    }
                    alt="profile"
                    className="company_pro"
                  />
                  <div className="dash_p1">Company name:</div>
                  <div className="dash_h1">{proj.company_name}</div>
                </div>
                <div className="d_row2">
                  <div className="dash_col1">
                    <div className="sub_row">
                      <p className="pro_name">Project name:</p>
                      <p className="pro_name1">{proj.project_title}</p>
                    </div>
                    <div className="sub_row">
                      <p className="pro_name">Start date:</p>
                      <p className="pro_name1">{proj.start_date}</p>
                    </div>
                    <div className="sub_row">
                      <p className="pro_name">End date:</p>
                      <p className="pro_name1">{proj.end_date}</p>
                    </div>
                  </div>
                  <div className="dash_col2">
                    <div className="sub_row">
                      <p className="pro_name">SOW id:</p>
                      <p className="pro_name1">{proj.sow_id}</p>
                    </div>
                    <div className="sub_row">
                      <p className="pro_name">Location:</p>
                      <p className="pro_name1">{proj.city}</p>
                    </div>
                    {/* <div className="sub_row">
                  <p className="pro_name">Job Category</p>
                  <p className="pro_name1">Merchant-onboarding</p>
                </div> */}
                  </div>
                </div>
                {/* <div className="d_row3">
              <h2 className="d_header">JIOMART:</h2>
              <p className="d_p1">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Accusantium cumque, quis saepe aliquam dolore provident veniam
                deserunt minus possimus ducimus nam expedita sed sequi eveniet
                error quam aperiam quaerat voluptas.
              </p>
            </div> */}
                {/* <div className="d_row3">
                  <div className="dash_small_card">
                    <p className="dash_small_p1">Leads</p>
                    <p className="dash_small_p2">{proj.total_leads
                    }</p>
                    <p className="dash_small_p3">+{proj.total_leads_today
                    }</p>
                  </div>
                  <div
                    className="dash_small_card"
                    style={{
                      background: "#FFF9F0",
                      border: "1px solid #cb552833",
                    }}
                  >
                    <p className="dash_small_p1">QC Passed</p>
                    <p className="dash_small_p2" style={{ color: "#FFB100" }}>
                      {proj.total_qc_passed
                      }
                    </p>
                    <p
                      className="dash_small_p3"
                      style={{ background: "#FEB12A34" }}
                    >
                      +{proj.total_qc_passed_today
                      }
                    </p>
                  </div>
                  <div
                    className="dash_small_card"
                    style={{ background: "#EAFAF5" }}
                  >
                    <p className="dash_small_p1">Checkin</p>
                    <p className="dash_small_p2" style={{ color: "#118026" }}>
                      {proj.total_checkin
                      }
                    </p>
                    <p
                      className="dash_small_p3"
                      style={{ background: "#CAEFE5" }}
                    >
                      +{proj.total_checkin_today
                      }
                    </p>
                  </div>
                </div> */}
                <div className="d_row4">
                  <Link to={`/dash/myStats`}>
                  <div
                    className="dash_button"
                    value={proj.sow_id}
                      onClick={(value) => handleView(proj.sow_id)}
                  >
                    <p className="dash_b_p1">view</p>
                    <img
                      src={window.location.origin + "/images/arrow.svg"}
                      alt="profile"
                      className="view_but"
                    />
                  </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : Object.keys(context.projectList).length !== 0 ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <div className="loader_nodata">
          <p>No Data is Found</p>
        </div>
      )}
    </>
  );
}

export default connect()(ProjectList);