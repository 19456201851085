import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import "./App.css";
import { connect } from "react-redux";

import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "./component/Login";
import Dash1 from "./component/Dash1";
import Dash from "./component/Dash";
import FirstDash from "./component/FirstDash/FirstDash"
import AsmList from "./component/AsmList/AsmList";
import { startLogin } from "./component/action/loggedin";
import { useEffect,createContext,useState } from "react";
import { statesInitializer } from "./statesInitializer";

export const CmContext = createContext();


function App(props) {
  const history=useHistory();
  const [context, setContext] = useState({ ...statesInitializer });

  // const [loggedIn2,setLoggedIn2]=useState(false);
   useEffect(()=>{
    if(localStorage.getItem("hr_token")){
      props.dispatch(startLogin(true))
    }else {
      props.dispatch(startLogin(false))

    }
   },[])

  console.log(props.loggedin,"app")
  return (
    <CmContext.Provider value={[context, setContext]}>

      <div className="app">
        {props.loggedin ? (
        // <Switch>
          
          <>

          <Route path="/dash" component={Dash} />
          <Route path="/login">
          <div className="reroutetoDash">
            Already Logged In :{" "} <a href="/dash" className="GtdButton"> Go To Dashboard</a>
          </div>
          </Route>
          {/* <Route path="/login" component={Login} /> */}
          </>
          // {/* <Route path="/home" component={FirstDash}/>
          // <Route path="/dash1" component={Dash1} /> */}
        
          // </Switch>
        ): (
          <>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route path="/login" component={Login} />
          </>
        ) }
        
        
      </div>
    </CmContext.Provider>

  );
}
const mapStateToProps=(state)=>{
  return {
    loggedin:state.loggedin
  }
}

export default connect(mapStateToProps)(App)

