
  
const asmChartInitialState=[]

const asmChartReducer=(state=asmChartInitialState,action)=>{
    switch(action.type){
      case "ASM_OVERVIEW_DATA":{
          return [...action.payload]
      }
      default:{
          return [...state]
      }
  }
}

export default asmChartReducer