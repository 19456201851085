
const searchLeadsInitialState=[]

const searchLeadsReducer=(state=searchLeadsInitialState,action)=>{
    switch(action.type){
        case "SEARCH_LEADS":{
            return [...action.payload]
        }
        default:{
            return [...state]
        }
    }
}

export default searchLeadsReducer