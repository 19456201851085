import axios from "../../config/axios"

const getAsmCheckin = (tl) => {
  return { type: "ASM_CHECKIN_DATA", payload: tl };
};

const startGetAsmCheckin = (id) => {
  return (dispatch) => {

    const loadData = async () => {
      try {
        const dataInsert = {
          "sow_id": id
      }
        const TotalData=await axios.post(`/asm_wise_checkin`,dataInsert)
        .then(res=>{
          console.log(res.data)
          const checkin = []
          const leadName = []
          res.data.asm_checkin.map(data=>{
            checkin.push(data.total_checkin)
            leadName.push(data.asm_name)
          })
          const finalData = [
             checkin , 
            leadName
          ]
          
          dispatch(getAsmCheckin(finalData))  
    
    
    
        })
        .catch(err=>{
          console.log(err)
        })
      } catch (err) {
        console.error(err);
      }
    };
    loadData();
  };
};



export { getAsmCheckin, startGetAsmCheckin  };
