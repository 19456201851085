import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { startResetMyStats } from "../action/myStats";

import "./SideBar.css";
import { startGetProjectList } from "../action/projectList";
import { CmContext } from "./../../App";
import { projectListApi } from "./ApiCalls";
function SideBar(props) {
  const [context, setContext] = useContext(CmContext);
  const history = useHistory();
  const handleClick = (e) => {
    console.log(e, "target hit");
    // if (e==="0"){
    //   // props.dispatch(startResetMyStats())
    // }
    let swap = [];
    for (let i = 0; i < context.sidebarS.length; i++) {
      if (i == e) {
        swap.push(false);
      } else {
        swap.push(true);
      }
    }
    // swap[e]=!swap[e]
    context.sidebarS = [...swap];
    setContext({ ...context });
  };
  return (
    <div className="pa_sidebar">
      <div className="side_row1">
        <img
          src={JSON.parse(localStorage.getItem("profileObj")).imageUrl}
          alt="profile"
          className="side_profile"
          onError={(e) => {
            e.target.src =
              window.location.origin + "/images/profile_default_new.svg";
          }}
        />
        {/* <div className="side_box">
          <h3 className="side_h3">Abhijit M</h3>
          <p className="side_p1">Project Associate</p>
        </div> */}
      </div>
      <div className="side_row2">
        {/* <Link to="/dash"> */}
        <div
          onClick={() => {
            handleClick("0");
            history.push("/dash");
          }}
          className={context.sidebarS[0] ? "side_tabs" : "side_tabs1"}
          // style={{pointerEvents:"none"}}
        >
          Home
        </div>
        {/* </Link> */}
        {/* <Link to="/dash/projectList">
          <div
            onClick={() => {
              // history.push('/dash/projectList');
              // props.dispatch(startGetProjectList(2));
              handleClick("1");
              projectListApi(
                localStorage.getItem("hr_admin_id"),
                context,
                setContext
              );
              context.sidebar2=false;
              setContext({...context})
            }}
            // style={{pointerEvents:"none"}}
            className={context.sidebarS[1] ? "side_tabs" : "side_tabs1"}
          >
            Projects
          </div>
        </Link> */}
        
        {context.sidebar2?<><div
          onClick={() => {
            handleClick("2");
            history.push("/dash/myStats");
          }}
          className={context.sidebarS[2] ? "side_tabs" : "side_tabs1"}
          // style={{pointerEvents:"none"}}
        >
          My Statistics
        </div>
        <div
          onClick={() => {
            handleClick("3");
            history.push("/dash/myTeam");
          }}
          className={context.sidebarS[3] ? "side_tabs" : "side_tabs1"}
          // style={{pointerEvents:"none"}}
        >
          My Team
        </div></>:null}
        {/* <div
          onClick={() => {
            handleClick("4");
            history.push("/dash/tsm_Stats");
          }}
          className={context.sidebarS[4] ? "side_tabs" : "side_tabs1"}

        >
          TSM Stats
        </div> */}
        {/* <Link to="/home/update-doc">
          <div
            onClick={() => handleClick("2")}
            className={context.sidebarS[2] ? "side_tabs" : "side_tabs1"}
          >
            Update Training document
          </div>
        </Link>
        <Link to="/home/setup-training">
          <div
            onClick={() => handleClick("3")}
            className={context.sidebarS[3] ? "side_tabs" : "side_tabs1"}
          >
            Setup training questions
          </div>
        </Link>
        <Link to="/home/launch-proj">
          <div
            onClick={() => handleClick("4")}
            className={context.sidebarS[4] ? "side_tabs" : "side_tabs1"}
          >
            Launch project
          </div>
        </Link>
        <Link to="/home/runpayroll">
          <div
            onClick={() => handleClick("5")}
            className={context.sidebarS[5] ? "side_tabs" : "side_tabs1"}
          >
            Run payroll
          </div>
        </Link>
        <Link to="/home/update-report">
          <div
            onClick={() => handleClick("6")}
            className={context.sidebarS[6] ? "side_tabs" : "side_tabs1"}
          >
            Update report
          </div>
        </Link> */}
      </div>
    </div>
  );
}

export default connect()(SideBar);
