const merchantDetailInitialState={}

const merchantDetailReducer=(state=merchantDetailInitialState,action)=>{
    switch(action.type){
        case "MERCHANT_DETAILS":{
            return {...action.payload}
        }
        default:{
            return {...state}
        }
    }
}

export default merchantDetailReducer