  
const asmListInitialState=[]

const asmListReducer=(state=asmListInitialState,action)=>{
    switch(action.type){
      case "ASM_LIST_DATA":{
          return [...action.payload]
      }
      default:{
          return [...state]
      }
  }
}

export default asmListReducer