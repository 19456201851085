import React, { useState } from "react";
import "./TlList.css";

import { Link } from "react-router-dom";

import Chart from "react-apexcharts";
import { Modal } from "@material-ui/core";

import Calender2 from "../calender2/Calender2";
// import CalenderAttend from "../calender/Calender";
import { apexMultiline, apexBarFse } from "../../ApexOptions";

function TlList() {
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  const handlePop = () => {
    setOpen(true);
    setActive(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const number1 = [];

  for (var i = 1; i <= 31; i++) {
    number1.push(i);
  }
  console.log(number1, "something");
  return (
    <div className="tl_list">
      <div className="dashtl_header">
        <div className="tl_list_row1">
          <Link to="/home">
            <img
              src={window.location.origin + "/images/svg/back_button.svg"}
              alt="profile"
              className="back_butt"
            />
          </Link>
          <div className="track_tl">
            <div className="training_assign">
              <img
                src={
                  window.location.origin + "/images/svg/training_assigned.svg"
                }
                alt="profile"
                className="complete_track"
              />
              <p className="training_p1">Training Assigned</p>
            </div>

            <div className="dash_tl_line"></div>
            <div className="training_assign">
              <img
                src={
                  window.location.origin + "/images/svg/training completed.svg"
                }
                alt="profile"
                className="complete_track"
              />
              <p className="training_p1" style={{ color: "#777777" }}>
                Training Completed
              </p>
            </div>

            <div className="dash_tl_line1"></div>
            <div className="training_assign">
              <img
                src={
                  window.location.origin + "/images/svg/training completed.svg"
                }
                alt="profile"
                className="complete_track"
              />
              <p className="training_p1" style={{ color: "#777777" }}>
                Payment
              </p>
            </div>
          </div>
        </div>

        <div className="tl_list_row1">
          {/* <div className="dashtl_h_col1">
            <img
              src={window.location.origin + "/images/png/profile1.png"}
              alt="profile"
              className="profile_tl"
            />
            <h3 className="tl_list_h3">anjana</h3>
            <p className="dashtl_h_p5">TL</p>
          </div> */}
          <div className="dashtl_h_col2">
            <img
              src={window.location.origin + "/images/png/profile1.png"}
              alt="profile"
              className="profile_tl"
            />
            <div className="dashtl_h_row1"></div>
            <div className="dashtl_h_row2">
              <p className="dashtl_h_p1">Abhijit</p>
              <p className="dashtl_h_p5">ASM</p>
              <p className="dashtl_h_p2">Bangalore</p>
            </div>
          </div>
          <div className="dashtl_h_col3">
            <div className="dashtl_h_cards">
              <p className="dashtl_h_p3">Leads</p>
              <p className="dashtl_h_p4">100</p>
            </div>
            <div className="dashtl_h_cards">
              <p className="dashtl_h_p3">QC Conversion</p>
              <p className="dashtl_h_p4">80</p>
            </div>

            <div className="dashtl_h_cards">
              <p className="dashtl_h_p3">Total Checkin</p>
              <p className="dashtl_h_p4">1345</p>
            </div>
          </div>
          <div className="dashtl_h_col4">
            <img
              src={window.location.origin + "/images/svg/message.svg"}
              alt="profile"
              className="icon_svg"
            />
            <img
              src={window.location.origin + "/images/svg/call.svg"}
              alt="profile"
              className="icon_svg"
            />
          </div>
        </div>
      </div>
      <div className="tl_list_row2">
        <div className="myTeam_card">
          <p className="mystat_p2" style={{ marginTop: "15px" }}>
            Daywise overview
          </p>
          <Chart
            options={apexMultiline.options}
            series={apexMultiline.series}
            type="line"
            height={250}
          />
        </div>
      </div>
      <div className="dashtl1_row1">
        {/* {1 === 0 ? (
          <div className="dash_row4">
            <></>
          </div>
        ) : ( */}
        {/* {props.fseList.map((fse_data) => (         key={fse_data.user_id}*/}
        <div className="dashtl_card">
          <div className="dashtl_card_row1">
            <div className="dashtl1_card_col1">
              <div className="dashtl_small_col">
                <img
                  src={window.location.origin + "/images/png/fse.png"}
                  alt="profile"
                  className="profile_tl"
                />
                {1 !== "NA" ? (
                  <p className="tllist_active">active</p>
                ) : (
                  <p className="tllist_active" style={{color:"red",background:"#ffd9d9"}}>inactive</p>
                )}
              </div>

              <div className="dashtl1_card_col2 ">
                <div className="dashtl_card_row2" style={{ margin: "10px" }}>
                  {/* {1 !== "NA" ? (
                    <img
                      src={window.location.origin + "/images/svg/active.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  ) : (
                    <img
                      src={window.location.origin + "/images/svg/inactive.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  )} */}
                  <p className="dashtl_p6">anjana</p>
                </div>
                <div className="dashtl_card_row3">
                  <p className="dashtl_card_p1">Fse Id:</p>
                  <p className="dashtl_card_p2">1234</p>
                </div>
                <div className="dashtl_card_row3">
                  <p className="dashtl_card_p1">DOJ:</p>
                  <p className="dashtl_card_p2">12/06/2021</p>
                </div>
              </div>
            </div>
            <div className="dashtl_card_col3" onClick={() => handleClick()}>
              <img
                src={window.location.origin + "/images/svg/view_details.svg"}
                alt="profile"
                className="svg_myteam"
              />

              {active ? (
                <div className="dashtl_dropdown">
                  <div className="tl_dropdown">
                    <p className="myteam_drop_p1" onClick={handlePop}>
                      View details
                    </p>
                    <p className="myteam_drop_p1">Download report</p>
                    <p className="myteam_drop_p1">Mark as Inactive</p>
                    <p className="myteam_drop_p1">Call</p>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="dashtl_card_row2">
            <div className="dashtl_smallcard">
              <p className="dashtl_card_p1">Leads</p>
              <p className="dashtl_p4">123</p>
              {1 !== "NA" ? (
                <p className="dashtl_p5">+123</p>
              ) : (
                <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                  0000
                </p>
              )}
            </div>
            <div className="dashtl_smallcard">
              <p className="dashtl_card_p1">QC Approved</p>
              <p className="dashtl_p4">123</p>
              {1 !== "NA" ? (
                <p className="dashtl_p5">+123</p>
              ) : (
                <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                  00
                </p>
              )}
            </div>
            <div className="dashtl_smallcard">
              <p className="dashtl_card_p1">Stars</p>
              <p className="dashtl_p4">123</p>
              {1 !== "NA" ? (
                <p className="dashtl_p5">+123</p>
              ) : (
                <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                  00
                </p>
              )}
            </div>
          </div>
        </div>

        {/* ))} */}

        {/* // )} */}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="model_contain">
          <div className="dashtl_header1" onClick={handleClose}>
            <div className="tl_list_row1">
              <img
                src={window.location.origin + "/images/svg/back_button.svg"}
                alt="profile"
                className="back_butt"
              />
              <div className="track_tl">
                <div className="training_assign">
                  <img
                    src={
                      window.location.origin +
                      "/images/svg/training_assigned.svg"
                    }
                    alt="profile"
                    className="complete_track"
                  />
                  <p className="training_p1">Training Assigned</p>
                </div>

                <div className="dash_tl_line"></div>
                <div className="training_assign">
                  <img
                    src={
                      window.location.origin +
                      "/images/svg/training completed.svg"
                    }
                    alt="profile"
                    className="complete_track"
                  />
                  <p className="training_p1" style={{ color: "#777777" }}>
                    Training Completed
                  </p>
                </div>

                <div className="dash_tl_line1"></div>
                <div className="training_assign">
                  <img
                    src={
                      window.location.origin +
                      "/images/svg/training completed.svg"
                    }
                    alt="profile"
                    className="complete_track"
                  />
                  <p className="training_p1" style={{ color: "#777777" }}>
                    Payment
                  </p>
                </div>
              </div>
            </div>

            <div className="tl_list_row1">
              <div className="dashtl_h_col2">
                <img
                  src={window.location.origin + "/images/png/profile1.png"}
                  alt="profile"
                  className="profile_tl"
                />
                <div className="dashtl_h_row1"></div>
                <div className="dashtl_h_row2">
                  <p className="dashtl_h_p1">Abhijit</p>
                  <p className="dashtl_h_p5">FSE</p>
                  <p className="dashtl_h_p2">Bangalore</p>
                </div>
              </div>
              <div className="dashtl_h_col3">
                <div className="dashtl_h_cards">
                  <p className="dashtl_h_p3">Leads</p>
                  <p className="dashtl_h_p4">100</p>
                </div>
                <div className="dashtl_h_cards">
                  <p className="dashtl_h_p3">QC Approved</p>
                  <p className="dashtl_h_p4">80</p>
                </div>

                <div className="dashtl_h_cards">
                  <p className="dashtl_h_p3">Total Checkins</p>
                  <p className="dashtl_h_p4">1345</p>
                </div>
              </div>
              <div className="dashtl_h_col4">
                <img
                  src={window.location.origin + "/images/svg/message.svg"}
                  alt="profile"
                  className="icon_svg"
                />
                <img
                  src={window.location.origin + "/images/svg/call.svg"}
                  alt="profile"
                  className="icon_svg"
                />
              </div>
            </div>
          </div>
          <div className="container2">
            <div className="bar_container">
              <p className="mystat_p2" style={{ marginTop: "15px" }}>
                Overview
              </p>
              <Chart
                options={apexBarFse.options}
                series={apexBarFse.series}
                type="bar"
                height={350}
                width={2200}
              />
            </div>
            <div className="calender_container">
              {/* <CalenderAttend /> */}
              <Calender2 />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default TlList;
