  
const asmDetailInitialState={}

const asmDetailReducer=(state=asmDetailInitialState,action)=>{
    switch(action.type){
      case "ASM_DETAIL_DATA":{
          return {...action.payload}
      }
      default:{
          return {...state}
      }
  }
}

export default asmDetailReducer