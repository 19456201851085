import axios from "../../config/axios"

const getLeadRegister = (tl) => {
  return { type: "LEAD_REGISTER_DATA", payload: tl };
};

const startGetLeadRegister = (sow) => {
  return (dispatch) => {
    const loadData = async () => {
      try {
        const dataInsert = {
            "sow_id": sow
        }
        const TotalData = await axios
          .post(`daily_track`, dataInsert)
          // dispatch(getFseOverView(TotalData.data.data))
          .then((res) => {
            // console.log(res.data.tracking, "inside total checkin");
            const totalLeads = [];
            const totalCheckin = [];
            const qcApproved=[]
            const totalDates = [];
            res.data.tracking.map((data) => {
              totalLeads.push(data.total_leads);
              totalCheckin.push(data.total_checkin);
              qcApproved.push(data.total_approved_leads)
              totalDates.push(data.time)
            });
            const checkineries = [
                {
                  name: "Total Leads",
                  data:totalLeads ,
                },
                {
                  name: "Approved Leads",
                  data: qcApproved,
                },
                {
                  name: "Checkins",
                  data: totalCheckin,
                }
              ]
            const finalData = [checkineries, totalDates];
            dispatch(getLeadRegister(finalData));
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.error(err);
      }
    };
    loadData();
  };
};



export { getLeadRegister, startGetLeadRegister  };

// const startGetFseOverView = (id,sow) => {
//   return (dispatch) => {
//     const loadData = async () => {
//       try {
//         const dataInsert = {
//             "user_id": id,
//             "sow_id": sow
//         }
//         const TotalData = await axios
//           .post(`fse_lead_chart`, dataInsert)
//           // dispatch(getFseOverView(TotalData.data.data))
//           .then((res) => {
//             // console.log(res.data, "inside total checkin");
//             const totalLeads = [];
//             const totalCheckin = [];
//             const totalDates = [];
//             res.data.fse_chart.map((data) => {
//               totalLeads.push(data.total_leads);
//               totalCheckin.push(data.total_approved);
//               totalDates.push(data.dates)
//             });
//             const checkineries = [
//                 {
//                   name: "Total Leads",
//                   data:totalLeads ,
//                 },
//                 {
//                   name: "Approved Leads",
//                   data: totalCheckin,
//                 },
//               ]
//             const finalData = [checkineries, totalDates];
//             dispatch(getFseOverView(finalData));
//           })
//           .catch((err) => {
//             console.log(err);
//           });
//       } catch (err) {
//         console.error(err);
//       }
//     };
//     loadData();
//   };
// };

// export { getFseOverView, startGetFseOverView };
