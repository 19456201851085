  
const fseDetailInitialState={}

const fseDetailReducer=(state=fseDetailInitialState,action)=>{
    switch(action.type){
      case "FSE_DETAIL_DATA":{
          return {...action.payload}
      }
      default:{
          return {...state}
      }
  }
}

export default fseDetailReducer