
  
const calenderInitialState=[]

const calenderReducer=(state=calenderInitialState,action)=>{
    switch(action.type){
      case "CALENDER_DATA":{
          return [...action.payload]
      }
      default:{
          return [...state]
      }
  }
}

export default calenderReducer