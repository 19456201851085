import React, { useContext } from "react";
import "./style.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import { CmContext } from "../../App";
import { markCmActiveApi, markCmInactiveApi } from "./ApiCalls";
import Swal from "sweetalert2";
// import {
//   leadReportAdminUrl,
//   leadReportClientUrl,
//   downloadCheckinReportUrl,
// } from "../../utils";
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));
export default function TripleDotDropDown({
  admin,
  cm_id,
  setCmId,
  setCmStats,
  data1,
  activeCm,
  inactiveCm,
}) {
  const [context, setContext] = useContext(CmContext);
  const handleMarkingActive = (cm_id) => {
    markCmActiveApi(cm_id)
      .then((r) => {
        if (r.error === false) {
          Swal.fire({
            icon: "success",
            title: "City manager and his team is marked as active",
          });
        } else {
          Swal.fire({
            icon: "error",
            text:
              "message: " +
              JSON.stringify(r) +
              " Unable to mark active. Please try again after some time",
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          text:
            "error: " +
            JSON.stringify(err) +
            " Unable to mark active. Please try again after some time",
        });
      });
  };
  const handleMarkingInactive = (cm_id) => {
    markCmInactiveApi(cm_id)
      .then((r) => {
        if (r.error === false) {
          Swal.fire({
            icon: "success",
            title: "City manager and his team is marked as inactive",
          });
        } else {
          Swal.fire({
            icon: "error",
            text:
              "message: " +
              r.message +
              " Unable to mark inactive. Please try again after some time",
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          text:
            "error: " +
            err +
            " Unable to mark inactive. Please try again after some time",
        });
      });
  };
  // console.log(data1);
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} />
      <Dropdown.Menu size="sm" title="">
        {/* <Dropdown.Header>Options</Dropdown.Header> */}
        {/* <Dropdown.Item >
        Call
        </Dropdown.Item><br/> */}
        <Dropdown.Item
          href={`/dash/asmList/${cm_id}`}
          onClick={() => {
            localStorage.setItem("activeCmId", cm_id);
          }}
        >
          View Team
        </Dropdown.Item>
        {/* <br /> */}
        {/* <Dropdown.Item href={`/dash/cmProfile/${cm_id}`}>
          View profile
        </Dropdown.Item> */}
        {/* <br /> */}
        {/* {activeCm && (
          <>
            <Dropdown.Item onClick={() => {
              handleMarkingInactive(cm_id);
            }}>Mark Inactive</Dropdown.Item>
            <br />
          </>
        )}
        {inactiveCm && (
          <>
            <Dropdown.Item onClick={() => {
              handleMarkingActive(cm_id);
            }}>Mark Active</Dropdown.Item>
            <br />
          </>
        )} */}
        {/* <Dropdown.Item >
        Provision letter
        </Dropdown.Item><br/> */}
        {/* <Dropdown.Item >
        Offer letter
        </Dropdown.Item><br/> */}
        {/* <Dropdown.Item >
        Termination Letter
        </Dropdown.Item><br/> */}
        {/* <Dropdown.Item >
        PIP Mail
        </Dropdown.Item> */}
        {/* <Dropdown.Item href={admin ? leadReportAdminUrl : leadReportClientUrl}>
          Lead Report{" "}
        </Dropdown.Item> */}
        {/* {admin ? (
          <Dropdown.Item href={downloadCheckinReportUrl}>
            Check In Report
          </Dropdown.Item>
        ) : null} */}
        {/* <Dropdown.Item>Upload</Dropdown.Item> */}
      </Dropdown.Menu>
    </Dropdown>
  );
}
//https://stackoverflow.com/questions/60123638/use-three-dots-span-to-trigger-dropdown/60127027#60127027
