  
const tableApiInitialState=[]

const tableApiReducer=(state=tableApiInitialState,action)=>{
    switch(action.type){
        case "PAYMENT_LIST":{
            return [...action.payload]
        }
        default:{
            return [...state]
        }
    }
}

export default tableApiReducer