import React from "react";
import "./Loader.css";

function Loader() {
  return (
    <div class="container">
      <div class="item item-1"></div>
      <div class="item item-2"></div>
      <div class="item item-3"></div>
      <div class="item item-4"></div>
    </div>
  );
}

export default Loader;
