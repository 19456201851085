import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

import { apexMultiline } from "../../ApexOptions";
import Modal from "@material-ui/core/Modal";
import Loader from "../Loader/Loader";

import { connect } from "react-redux";

import "./MyTeam.css";
import { Link } from "react-router-dom";
import { startGetAsmList } from "../action/asmList";
import { startGetAsmDetail } from "../action/asmDetail";
import { startGetDatwiseOverview } from "../action/daywiseOverview";
import { startGetMyTeam } from "../action/myTeam";
import { startGetProjectDetaill } from "../action/projectDetail";
import { startGetMyStats } from "../action/myStats";
import { startGetEmailCheck } from "../action/checkEmail";
import axios from "axios";

function MyTeam(props) {
  const [open, setOpen] = useState(false);
  const [profileImg, setProfileImg] = useState("");
  const [callingName, setCallingName] = useState("");
  const [open1, setOpen1] = useState(false);

  const handlePop = (e) => {
    setOpen(true);
    // setActive(false);
    // console.log(e.target.id,"id")
    const id = e.target.id;
    const sow = localStorage.getItem("sow_id");

    // props.dispatch(startGetFseDetail(id, sow));
    // props.dispatch(startGetFseOverView(id, sow));
    // props.dispatch(startGetCalender(id, sow));
  };
  const handleClick = (e) => {
    // setActive(!active);
    // console.log(e.target.id);
    // if (e.target.id !== "undefined") {
    // const displayDrop = document.getElementById(e.target.id);
    const displayDrop = document.getElementById(e.target.name);
    setProfileImg(e.target.id);
    // console.log(displayDrop, "display");

    if (displayDrop.style.display === "none") {
      displayDrop.style.display = "block";
    } else {
      displayDrop.style.display = "none";
    }
    // }
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  useEffect(() => {
    const email_id = localStorage.getItem("asm_token_email");

    const id = localStorage.getItem("sow_id");
    props.dispatch(startGetEmailCheck(email_id));

    props.dispatch(startGetMyTeam(id));
    props.dispatch(startGetProjectDetaill(id));
    props.dispatch(startGetMyStats(id));
  }, []);

  const handleDrop = (e) => {
    // console.log(e.target.id,"id")
    const dropDisplay = document.getElementById(e.target.id);
    if (dropDisplay.style.display === "none") {
      dropDisplay.style.display = "block";
    } else {
      dropDisplay.style.display = "none";
    }
  };

  const handleOpenProfile = (e) => {
    const id = e.target.id;
    const sow = localStorage.getItem("sow_id");
    props.dispatch(startGetAsmList(id, sow));
    props.dispatch(startGetAsmDetail(id, sow));
    props.dispatch(startGetDatwiseOverview(id, sow));
  };

  const handleAddAsm = () => {
    console.log("inside add asm button");
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCall = async (e) => {
    // console.log(e.target.dataset.name, "calling");

    const number_1 = props.checkEmail.mobile_number;
    const number_2 = e.target.id;
    const dataTotal = {
      number: number_1,
      number_2: number_2,
    };
    console.log(dataTotal,"dataTotal")
    const finalData = await axios
      .post("https://isp.taskmo.in/fse/uber/clicktocall", dataTotal)
      .then((res) => {
        setOpen1(true);
        setCallingName(e.target.dataset.name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log(props.myTeam, "stats list");

  return (
    <div className="mystats">
                  <p className="my_stats_p5">ASM (Area Sales Manager) list</p>

      {/* <div className="myTeam_card">
        <p className="mystat_p2" style={{ marginTop: "15px" }}>
          Monthly overview
        </p>

        {props.asmChart.length > 0 ? <Chart
          options={apexMultiline.options}
          series={props.asmChart}
          type="line"
          height={250}
        /> :
        <Chart
          options={apexMultiline.options}
          series={apexMultiline.series}
          type="line"
          height={250}
        />}
      </div> */}
      {/* <div className="my_team_flex">
        <div className="buttons2">
          <img
            src={window.location.origin + "/images/add TL.svg"}
            alt="profile"
            className="add_img"
          />
          <p className="d1_p42">Add ASM</p>
        </div>
      </div> */}
      {/* {props.myTeam.asm_list && props.myTeam.asm_list.length ? (
        <div className="myteam_container">
          <div className="my_stats_row3">
            <p className="my_stats_p5">ASM (Area sales Manager) list</p>
            <div className="buttons2" onClick={handleAddAsm}>
          <img
            src={window.location.origin + "/images/add TL.svg"}
            alt="profile"
            className="add_img"
          />
          <p className="d1_p42">Add ASM</p>
        </div>
          </div>
          {props.myTeam.asm_list &&
            props.myTeam.asm_list.map((myAsmList) => (
              <div className="myteam_card" key={myAsmList.user_id}>
                <div className="myteam_col0">
                  {myAsmList.profile_image !==
                  "https://isp.taskmo.in/asm_images/null" ? (
                    <img
                      src={myAsmList.profile_image}
                      alt="profile"
                      className="prof_img"
                    />
                  ) : (
                    <img
                      src={window.location.origin + "/images/svg/profile.svg"}
                      alt="profile"
                      className="prof_img"
                    />
                  )}

                  <div className="myteam_col1">
                    <p className="my_s_p5">{myAsmList.asm_name}</p>
                    <p className="my_s_p6">{myAsmList.user_id}</p>
                  </div>
                </div>

                <div className="myteam_col2">
                  <p className="my_s_p6">City</p>

                  <p className="myteam_p3">{myAsmList.asm_city}</p>
                </div>
                <div className="myteam_col2">
                  <p className="my_s_p6">Date of Joining</p>

                  <p className="myteam_p3">
                    {myAsmList.doj === "Invalid date" ? "NA" : myAsmList.doj}
                  </p>
                </div>
                <div className="myteam_col2">
                  <p className="my_s_p6">Total Leads</p>

                  <p className="myteam_p2">{myAsmList.total_leads}</p>
                  <p className="myteam_p1">+{myAsmList.total_leads_today}</p>
                </div>
                <div className="myteam_col2">
                  <p className="my_s_p6">QC Approved</p>

                  <p className="myteam_p2">{myAsmList.total_approved_leads}</p>
                  <p className="myteam_p1">
                    +{myAsmList.total_approved_leads_today}
                  </p>
                </div>
                <div className="myteam_col2">
                  <p className="my_s_p6">Total checkins</p>

                  <p className="myteam_p2">{myAsmList.total_checkin}</p>
                  <p className="myteam_p1">+{myAsmList.total_checkin_today}</p>
                </div>
                <div className="myteam_col2">
            <p className="my_s_p6">Total FSE</p>

            <p className="myteam_p2">180</p>
          </div>
                <div
                  className="myteam_dropdown"
                  id={myAsmList.user_id}
                  style={{ display: "none" }}
                >
                  <Link to={`/home/tl_list/${myAsmList.user_id}`}>
                    <p
                      className="myteam_drop_p1"
                      id={myAsmList.user_id}
                      onClick={handleOpenProfile}
                    >
                      View details
                    </p>
                  </Link>
                  <p className="myteam_drop_p1">Download report</p>
              <p className="myteam_drop_p1">Mark as Inactive</p>
              <p className="myteam_drop_p1">Call</p>
                  <p className="myteam_drop_p1">Rooster</p>
                </div>
                <div className="myteam_col3" onClick={handleDrop}>
                  <img
                    src={
                      window.location.origin + "/images/svg/view_details.svg"
                    }
                    alt="profile"
                    className="svg_myteam"
                    id={myAsmList.user_id}
                  />
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div className="loader">
          <Loader />
        </div>
      )} */}
      <div className="dashtl1_row1">
        
        {/* {1 === 0 ? (
          <div className="dash_row4">
            <></>
          </div>
        ) : ( */}
        {/* {props.fseList.map((fse_data) => (         key={fse_data.user_id}*/}
        {props.myTeam.asm_list && props.myTeam.asm_list.length ?
        props.myTeam.asm_list &&
          props.myTeam.asm_list.map((fseList) => (
            <div className="dashtl_card" key={fseList.user_id}>
              <div className="dashtl_card_row1">
                <div className="dashtl1_card_col1">
                  <div className="dashtl_small_col">
                    {fseList.profile_image !==
                    "https://isp.taskmo.in/fieldon_images/null" ? (
                      <img
                        src={fseList.profile_image}
                        alt="profile"
                        className="profile_tl2"
                      />
                    ) : (
                      <img
                        src={window.location.origin + "/images/svg/profile.svg"}
                        alt="profile"
                        className="profile_tl2"
                      />
                    )}

                    {fseList.onboard_status === "onboarded" ? (
                      <p className="tllist_active">active</p>
                    ) : (
                      <p
                        className="tllist_active"
                        style={{ color: "red", background: "#ffd9d9" }}
                      >
                        inactive
                      </p>
                    )}
                  </div>

                  <div className="dashtl1_card_col2 ">
                    <div
                      className="dashtl_card_row2"
                      style={{ margin: "10px" }}
                    >
                      {/* {1 !== "NA" ? (
                    <img
                      src={window.location.origin + "/images/svg/active.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  ) : (
                    <img
                      src={window.location.origin + "/images/svg/inactive.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  )} */}
                      <p className="dashtl_p6">{fseList.asm_name}</p>
                    </div>
                    <div className="dashtl_card_row3">
                      {/* <p className="dashtl_card_p1">Fse Id:</p> */}
                      <img
                        src={window.location.origin + "/images/fse_id.svg"}
                        alt="profile"
                        className="icon_size"
                      />
                      <p className="dashtl_card_p2">{fseList.user_id}</p>
                    </div>
                    <div className="dashtl_card_row3">
                      {/* <p className="dashtl_card_p1">DOJ:</p> */}
                      <img
                        src={
                          window.location.origin +
                          "/images/calendar.svg"
                        }
                        alt="profile"
                        className="icon_size"
                      />
                      <p className="dashtl_card_p2">{fseList.doj}</p>
                    </div>
                  </div>
                </div>
                <div
                  className="dashtl_dropdown1"
                  id={fseList.user_id}
                  style={{ display: "none" }}
                >
                  {/* <Link to={`/home/tl_list/${fseList.user_id}`}>
                    <p
                      className="myteam_drop_p1"
                      id={fseList.user_id}
                      // onClick={handleOpenProfile}
                      style={{pointerEvents:"none"}}
                    >
                      View details
                    </p>
                  </Link> */}
                  <p className="myteam_drop_p1"> <a href={`https://isp.taskmo.in/pa/${fseList.user_id}/downloadasmreport?sow_id=${localStorage.getItem("sow_id")}`} target="_blank">Download report</a> </p>
                  {/* <p className="myteam_drop_p1">Mark as Inactive</p> */}
                  {/* <p
                    className="myteam_drop_p1"
                    id={fseList.mobile_number}
                    data-name={fseList.asm_name}
                    onClick={handleCall}
                  >
                    Call
                  </p> */}
                  {/* <p className="myteam_drop_p1">Rooster</p> */}
                </div>
                <div className="dashtl_card_col3" onClick={handleDrop}>
                  <img
                    src={
                      window.location.origin + "/images/svg/view_details.svg"
                    }
                    alt="profile"
                    className="svg_myteam"
                    id={fseList.user_id}
                  />
                </div>
              </div>
              <div className="dashtl_card_row2">
                <div className="dashtl_smallcard">
                  <p className="dashtl_card_p1">Leads</p>
                  <p className="dashtl_p4">{fseList.total_leads}</p>
                  {fseList.total_leads_today !== 0 ? (
                    <p className="dashtl_p5">+{fseList.total_leads_today}</p>
                  ) : (
                    <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                      00
                    </p>
                  )}
                </div>
                <div className="dashtl_smallcard">
                  <p className="dashtl_card_p1">Approved</p>
                  <p className="dashtl_p4">{fseList.total_approved_leads}</p>
                  {fseList.total_approved_leads_today !== 0 ? (
                    <p className="dashtl_p5">
                      +{fseList.total_approved_leads_today}
                    </p>
                  ) : (
                    <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                      00
                    </p>
                  )}
                </div>
                <div className="dashtl_smallcard">
                  <p className="dashtl_card_p1">Checkins</p>
                  <p className="dashtl_p4">{fseList.total_checkin}</p>
                  {fseList.total_checkin_today !== 0 ? (
                    <p className="dashtl_p5">+{fseList.total_checkin_today}</p>
                  ) : (
                    <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                      00
                    </p>
                  )}
                </div>
              </div>
            </div>
          )): <>
                    <Loader /> 

          </>}

        {/* ))} */}

        {/* // )} */}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="myteam_popup">
          <div className="add_tl_row1">
            <h1 className="myteam_add_tl">Team Leader List</h1>
          </div>
          <div className="add_tl_card2">
            <div className="myteam_col0">
              <img
                src={window.location.origin + "/images/profile image.svg"}
                alt="profile"
                className="prof_img"
              />
              <div className="myteam_col1">
                <p className="my_s_p5">Aman Singh</p>
                <p className="my_s_p6">ASM ID</p>
              </div>
            </div>

            <div className="myteam_col2">
              <p className="my_s_p6">City</p>

              <p className="myteam_p3">Bangalore</p>
            </div>
            <div className="myteam_col2">
              <p className="my_s_p6">Date of Joining</p>

              <p className="myteam_p3">02/02/2021</p>
            </div>
            <div className="myteam_col2">
              <p className="my_s_p6">Total Leads</p>

              <p className="myteam_p4">23000</p>
              <p className="myteam_p1">+1000</p>
            </div>
            <div className="myteam_col2">
              <p className="my_s_p6">QC Approved</p>

              <p className="myteam_p4">18000</p>
              <p className="myteam_p1">+612</p>
            </div>
            <div className="myteam_col2">
              <p className="my_s_p6">Points Achieved</p>

              <p className="myteam_p4">18000</p>
              <p className="myteam_p1">+7124</p>
            </div>
            <Link to="/home/tl_list">
              <div className="myteam_col4">
                <div className="myteam_button">
                  <p className="dash_b_p1">view</p>
                  <img
                    src={window.location.origin + "/images/arrow.svg"}
                    alt="profile"
                    className="view_but"
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </Modal>

      <Modal
        open={open1}
        onClose={handleClose1}
        // aria-labelledby="simple-modal-title"
        // aria-describedby="simple-modal-description"
        className="modal_call"
      >
        <div className="model_contain">
          <div className="call_box">
            <div
              className="call_ringing"
              style={{
                backgroundImage: `url(${
                  window.location.origin + "/images/svg/animation_500.gif"
                })`,
              }}
            >
              <p className="call_p1"> {callingName}</p>
              {/* <p className="call_p1"> ringing...</p> */}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    myTeam: state.myTeam,
    asmChart: state.asmChart,
    checkEmail: state.checkEmail,
  };
};

export default connect(mapStateToProps)(MyTeam);
