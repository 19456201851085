import axios from "../../config/axios";

const getTotalAttendance = (tl) => {
  return { type: "TOTAL_ATTENDANCE_DATA", payload: tl };
};

const startGetTotalAttendance = (id) => {
  return (dispatch) => {
    const loadData = async () => {
      try {
        const dataInsert = {
          sow_id: id,
        };
        const TotalData = await axios
          .post(`checkin_stats`, dataInsert)
          // dispatch(getTotalAttendance(TotalData.data.data))
          .then((res) => {
            // console.log(res.data, "inside total checkin");
            const checkin = [];
            const dates = [];
            res.data.checkin.map((data) => {
              checkin.push(data.total_checkin);
              dates.push(data.dates);
            });
            const checkineries = {
              name: "checkin",
              data: checkin,
            };
            const finalData = [checkineries, dates];
            dispatch(getTotalAttendance(finalData));
          })
          .catch((err) => {
            console.log(err);
          });
          
      } catch (err) {
        console.error(err);
      }
    };
    loadData();
  };
};

export { getTotalAttendance, startGetTotalAttendance };
