  
const projectDetailInitialState={}

const projectDetailReducer=(state=projectDetailInitialState,action)=>{
    switch(action.type){
      case "PROJECT_DETAIL_DATA":{
          return {...action.payload}
      }
      default:{
          return {...state}
      }
  }
}

export default projectDetailReducer