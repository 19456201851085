import React from "react";
import "./TsmStats.css";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { apexMultiline3 } from "../../ApexOptions";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import TlList from "../TlList/TlList";
import { startGetAsmOverView } from "../action/asmChart";




function TsmStats(props) {
  const [tableList, setTableList] = useState([]);
  const [open2, setOpen2] = useState(false);
  const [asmName, setAsmName] = useState("");

  const handleClose2 = () => {
    setOpen2(false);
  };
  const openChartHandle = (e , asm) => {
    setOpen2(true)
    // console.log(asm,"sow_id")
    props.dispatch(startGetAsmOverView(e)) 
    setAsmName(asm)


  }
  useEffect(() => {
    try {
      const leadData = axios
        .get("https://isp.taskmo.in/pa/tsm_stats")
        .then((res) => {
          console.log(res.data.asm, "data");
          const dataArray = res.data.asm;
          setTableList(dataArray);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  }, []);
  console.log(tableList,"asm")
  return (
    <div className="tsm_container">
      <div className="tsm_row1">
        <div className="col-md-12">
          <div className="tv_dash_row22">
            <div className="col-md-1 border_right1 display_center">brand</div>

            {/* <div className="col-md-2 border_right1 display_center">
              CM Name
            </div> */}
            <div className="col-md-2 border_right1 display_center">
              TSM Name
            </div>
            <div className="col-md-2 border_right1 display_center">
             Number
            </div>
            <div className="col-md-1 border_right1 display_center">
             DOJ
            </div>
            {/* <div className="col-md-1 border_right1 display_center">
             SAD
            </div> */}
            <div className="col-md-2 border_right1 display_center">city</div>
            <div className="col-md-1 border_right1 display_center"> ckn</div>

            <div className="col-md-1 border_right1 display_center">Ld</div>
            <div className="col-md-1 border_right1 display_center">OL</div>
            <div className="col-md-1 border_right1 display_center">pfm</div>
          </div>
        </div>
        {/* <div className="col-md-4">
          <div className="tv_dash_row22">
            <div className="col-md-2 border_right1 display_center">brand</div>

            <div className="col-md-4 border_right1 display_center">
              TSM Name
            </div>
            <div className="col-md-2 border_right1 display_center">city</div>
            <div className="col-md-1 border_right1 display_center"> ckn</div>

            <div className="col-md-1 border_right1 display_center">Ld</div>
            <div className="col-md-1 border_right1 display_center">OL</div>
            <div className="col-md-1 border_right1 display_center">pfm</div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="tv_dash_row22">
            <div className="col-md-2 border_right1 display_center">brand</div>

            <div className="col-md-4 border_right1 display_center">
              TSM Name
            </div>
            <div className="col-md-2 border_right1 display_center">city</div>
            <div className="col-md-1 border_right1 display_center"> ckn</div>

            <div className="col-md-1 border_right1 display_center">Ld</div>
            <div className="col-md-1 border_right1 display_center">OL</div>
            <div className="col-md-1 border_right1 display_center">pfm</div>
          </div>
        </div> */}
      </div>
      <div className="tsm_row2">
        {tableList &&
          tableList.map((tList) => (
            <div className="col-md-12" onClick={()=>openChartHandle(tList.sow_id,tList.full_name)}>
              <div className="tsm_row3">
                <div className="col-md-1 border_right1" style={{
                    display: "flex",
                    justifyContent:"center",
                    alignItems: "center",
                    background: "#fff",
                    height: "35px",
                    marginTop: "2px"
                  }}>
                  <div className="col-md-2 grey_background " style={{
                    display: "flex",
                    justifyContent:"center",
                    alignItems: "center",
                    height: "39px"

                  }}>
                    <img
                      src={tList.brand_logo}
                      alt="profile"
                      className="image_profile2"
                    />
                  </div>
                </div>
                {/* <div
                  className="col-md-2 border_right1"
                  style={{
                    textAlign: "left",
                    display: "flex",
                    // alignItems: "flex-start",
                  }}
                >
                  {tList.full_name}
                </div> */}
                <div
                  className="col-md-2 border_right1 padding_left"
                  style={{
                    textAlign: "left",
                    display: "flex",
                    // alignItems: "flex-start",
                  }}
                >
                  {tList.full_name}
                </div>
                <div className="col-md-2 border_right1 display_center" style={{
                    display: "flex",
                    alignItems: "center",
                  }}>{tList.mobile_number}</div>
                  <div className="col-md-1 border_right1 display_center" style={{
                    display: "flex",
                    alignItems: "center",
                  }}>{tList.doj === "Invalid date" ? "NA":tList.doj}</div>
                  {/* <div className="col-md-1 border_right1 display_center" style={{
                    display: "flex",
                    alignItems: "center",
                  }}>{tList.assign_date === "Invalid date" ? "NA":tList.assign_date}</div> */}

                <div className="col-md-2 border_right1 padding_left" style={{
                    display: "flex",
                    alignItems: "center",
                  }}>{tList.city}</div>
                <div className="col-md-1 border_right1 display_center">
                  {tList.today_checkin}
                </div>

                <div className="col-md-1 border_right1 display_center">
                  {tList.today_leads}
                </div>
                <div className="col-md-1 border_right1 display_center">
                  {tList.total_leads}
                </div>
              <div className="col-md-1 border_right1 display_center"> {tList.performance_score === null ? 0 : tList.performance_score}</div>

              </div>

            </div>
          ))}
      </div>
      <Modal
        open={open2}
        onClose={handleClose2}
        // aria-labelledby="simple-modal-title"
        // aria-describedby="simple-modal-description"
        className="modal_call"
      >
        <div className="model_contain2">
        <div className="mystat_card_row1">
                <div className="myTeam_card11">
                  <p className="mystat_p2" style={{ marginTop: "15px" }}>
                    {`Monthly overview (${asmName})`}
                  </p>

                  {props.asmChart.length && props.asmChart.length > 0 ? (
                    <Chart
                      options={apexMultiline3.options}
                      series={props.asmChart}
                      type="line"
                      height={250}
                    />
                  ) : (
                    <Chart
                      options={apexMultiline3.options}
                      series={apexMultiline3.series}
                      type="line"
                      height={250}
                    />
                  )}
                </div>
                {/* <div className="mystat_card2">
              <p className="mystat_p2" style={{ marginTop: "15px" }}>
                Stagewise Overview
              </p>
              <Chart
                options={apexSideBar.options}
                series={apexSideBar.series}
                type="bar"
                height={250}
              />
            </div> */}
              </div>
        </div>
      </Modal>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    asmChart: state.asmChart,
  };
};

export default connect(mapStateToProps)(TsmStats);

