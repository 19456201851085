  
const myTeamInitialState={}

const myTeamReducer=(state=myTeamInitialState,action)=>{
    switch(action.type){
      case "MY_TEAM_DATA":{
          return {...action.payload}
      }
      default:{
          return {...state}
      }
  }
}

export default myTeamReducer