import React from "react";
import { Route, Switch } from "react-router-dom";

import NavBar from "../NavBar/NavBar";
import SideBar from "../SideBar/SideBar";
import Home from "../home/Home";
import TlList from "../TlList/TlList";
import UpdateTrainingDoc from "../UpdateTrainingDoc/UpdateTrainingDoc";
import SetUpTraining from "../SetUpTraining/SetUpTraining";
import LaunchProj from "../LaunchProj/LaunchProj";
import RunPayroll from "../RunPayroll/RunPayroll";
import UpdateReport from "../UpdateReport/UpdateReport";

import "./FirstDash.css";

function FirstDash() {
  return (
    <div className="first_Dash">
      <div className="first_col1">
        <SideBar />
      </div>
      <div className="first_col2">
        <NavBar />
        <Switch>
          <Route path="/home" exact={true}>
            <Home />
          </Route>
          <Route path="/home/tl_list">
            <TlList />
          </Route>
          <Route path="/home/update-doc">
            <UpdateTrainingDoc />
          </Route>
          <Route path="/home/setup-training">
            <SetUpTraining />
          </Route>
          <Route path="/home/launch-proj">
            <LaunchProj />
          </Route>
          <Route path="/home/runpayroll">
            <RunPayroll />
          </Route>
          <Route path="/home/update-report">
            <UpdateReport />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default FirstDash;
