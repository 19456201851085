  
const allLeadsInitialState=[]

const allLeadsReducer=(state=allLeadsInitialState,action)=>{
    switch(action.type){
      case "ALL_LEADS":{
          return [...action.payload]
      }
      default:{
          return [...state]
      }
  }
}

export default allLeadsReducer