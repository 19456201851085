import { axios2 } from "../../config/axios";
import Swal from "sweetalert2";
export const projectListApi = (rm_admin_id,context,setContext) => {
  axios2
    .get(`/projects?admin_id=${rm_admin_id}`)
    .then((r) => {
      if (!r.data.error) {
        context.projectList = r.data.data;
        setContext({ ...context });
      } else if(r.data.error && r.data.message==="DATA_EMPTY") {
        Swal.fire({
          icon: "warning",
          title: "Regional Manager Not Assigned",
        });
      }
    })
    .catch((e) => {
      Swal.fire({
        icon: "error",
        title: "Server Error",
        text:JSON.stringify({
          e
        }),
      });
    });
};
