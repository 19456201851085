  
const projectListInitialState=[]

const projectListReducer=(state=projectListInitialState,action)=>{
    switch(action.type){
      case "PROJECT_LIST_DATA":{
          return [...action.payload]
      }
      default:{
          return [...state]
      }
  }
}

export default projectListReducer