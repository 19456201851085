  
const leadConvStatsInitialState={}

const leadConvStatsReducer=(state=leadConvStatsInitialState,action)=>{
    switch(action.type){
      case "LEAD_CONVERTION_STATS":{
          return {...action.payload}
      }
      default:{
          return {...state}
      }
  }
}

export default leadConvStatsReducer