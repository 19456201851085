import axios from "../../config/axios"

const getMyTeam = (tl) => {
  return { type: "MY_TEAM_DATA", payload: tl };
};

const startGetMyTeam = (id) => {
  return (dispatch) => {

    const loadData = async () => {
      try {
        const dataSetup = {
          "sow_id": id
      }
        const TotalData=await axios.post(`asm_lists`,dataSetup)
        dispatch(getMyTeam(TotalData.data))  
      } catch (err) {
        console.error(err);
      }
    };
    loadData();
  };
};



export { getMyTeam, startGetMyTeam  };
