import React, { useContext, useEffect, useState } from "react";
import "./AsmList.css";

import { Link, useHistory, useParams } from "react-router-dom";

// import Chart from "react-apexcharts";
// import { Modal } from "@material-ui/core";

// import Calender2 from "../calender2/Calender2";
// import CalenderAttend from "../calender/Calender";
// import { apexMultiline, apexBarAsm } from "../../ApexOptions";
import {
  ASM_LIST_URL,
  checkInvalid,
  //  ASM_STATS_URL,
  CM_STATS_URL,
  TERMINATE_ASM_URL,
  ASM_LIST_COUNTS,
  ASM_LIST_URL2,
  ASM_LIST_CHECKINS,
  CM_stats_new,
} from "../../utils";
import Swal from "sweetalert2";
import { CmContext } from "../../App";
import SendOlModal from "./SendOlModal/SendOlModal";
import InactiveCommentPP from "./InactiveCommentPP";
import ActiveCommentPP from "./activeCommentPP";
import axios from "axios";
// import asmDummyData from "./asmDummyData";
//remove the above import if local api test
import { Spinner } from "reactstrap";
import CmListToRemapAsm from "./CmListToRemapAsm";
function AsmList({ cmId, cmStats, setLoading }) {
  const [context, setContext] = useContext(CmContext);
  const [activeFse, setActiveFse] = useState(true);
  const [openCmList, setOpenCmList] = useState(false);
  const [inactiveFse, setInactiveFse] = useState(false);
  const [leadCountLoader, setLeadCountLoader] = useState(false);
  const [checkinCountLoader, setCheckinCountLoader] = useState(false);
  const [openActiveComment, setOpenActiveComment] = useState(false);
  const [openInactiveComment, setOpenInactiveComment] = useState(false);
  console.log(context, " I am context from asmlist");
  const { cmIdFromParam, cmName, cmCity } = useParams();
  // console.log(cmIdFromParam,"cmIdfromparam")
  const [asmDummyData, setAsmDummyData] = useState([]);
  const [myCmStats, setMyCmStats] = useState({});
  const [active, setActive] = useState([]);
  const [new_stats, setNewstas] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSoiModel, setOpenSoiModel] = useState(false);
  const history = useHistory();
  const handleClick = (index) => {
    active[index] = !active[index];
    setActive([...active]);
  };
  const terminateAsmFunc = (asm_id) => {
    console.log("terminate func call");
    fetch(TERMINATE_ASM_URL, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        asm_id,
      }),
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        } else {
          throw new Error("Unable to terminate, Server not responding ");
        }
      })
      .then((r) => {
        console.log(r, "terminate response");
        Swal.fire({
          icon: "success",
          title: "Terminated",
        });
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          text: "Error" + e,
        });
      });
  };
  console.log(context, "<- remap asm check cm list under asmlist.js ");

  const handlePop = () => {
    setOpen(true);
    setActive(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const number1 = [];

  for (var i = 1; i <= 31; i++) {
    number1.push(i);
  }

  const getCountsAndSet = (arrangeArr) => {
    const asyncFunc = async () => {
      const res1 = await fetch(ASM_LIST_COUNTS + `?cm_id=${cmIdFromParam}`);
      if (res1.ok) {
        const res = await res1.json();
        if (res.error) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: res.error,
          });
        } else {
          Swal.fire({
            icon: "success",
            text: JSON.stringify({
              res,
            }),
          });
          // console.log(res,"ASM LIST COUNTS",arrangeArr,'arrangeArr');
          // arrangeArr.forEach(({asm_id},index)=>{
          //   if(asm_id===res.data[index].fse_onboarding_asm_id){
          //     arrangeArr[index].approved_thismonth=res.data[index].approved;
          //     arrangeArr[index].approved_thismonth=res.data[index].approved;
          //     arrangeArr[index].approved_thismonth=res.data[index].approved;
          //     arrangeArr[index].approved_thismonth=res.data[index].approved;
          //   }
          // })
          // const arrangeArr = res.asm_list.map((obj) => {
          //   return {
          //     asm_id: obj.asm_id,
          //     checkin_thismonth: obj.total_checkin,
          //     checkin_today: obj.total_checkin_today,
          //     leads_thismonth: obj.total_leads,
          //     leads_today: obj.total_leads_today,
          //     approved_thismonth: obj.total_approved,
          //     approved_today: obj.total_approved_today,
          //   };
          // });
          // if(asmDummyData.length>0){
          //  const addedCountsArr=asmDummyData.map()
          // }
          setAsmDummyData([...arrangeArr]);

          // set asmDetails in localStorage
          // const activeCmId = localStorage.getItem("activeCmId");
          // const myAsmList = JSON.parse(localStorage.getItem("asmList"));
          // if (!checkInvalid(myAsmList[activeCmId])) {
          //   myAsmList[activeCmId].details = [...arrangeArr];
          // } else {
          //   myAsmList[activeCmId] = {
          //     details: [...arrangeArr],
          //   };
          // }
          // localStorage.setItem("asmList", JSON.stringify([...myAsmList]));
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: "Unable to Fetch",
        });
      }
    };
    asyncFunc();
  };

  useEffect(() => {
    const postData = {
      cm_id: cmIdFromParam,
    };
    console.log(postData, "tejas");
    axios
      .post(CM_stats_new, postData)
      .then((res) => {
        setNewstas(res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
    const asmList = localStorage.getItem("asmList");
    if (checkInvalid(asmList)) {
      localStorage.setItem("asmList", JSON.stringify([]));
    } else if (
      JSON.parse(asmList).length >= localStorage.getItem("activeCmId") &&
      JSON.parse(asmList)[Number(localStorage.getItem("activeCmId"))]
    ) {
      const data = JSON.parse(asmList)[localStorage.getItem("activeCmId")];
      setMyCmStats({ ...data.stats });
    }
    const asyncFunc = async () => {
      setLoading(true);
      const res1 = await fetch(CM_STATS_URL, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          cm_id: cmIdFromParam,
        }),
      });
      if (res1.ok) {
        const res = await res1.json();
        console.log(res, "cmStats response");
        if (res.error) {
          setLoading(false);
        } else {
          setMyCmStats({ ...res });
          setLoading(false);

          const activeCmId = localStorage.getItem("activeCmId");
          const myAsmList = JSON.parse(localStorage.getItem("asmList"));
          if (!checkInvalid(myAsmList[activeCmId])) {
            myAsmList[activeCmId].stats = { ...res };
          } else {
            myAsmList[activeCmId] = {
              stats: { ...res },
            };
          }

          localStorage.setItem("asmList", JSON.stringify([...myAsmList]));
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: "Unable to Fetch",
        });
      }
    };
    asyncFunc();
  }, []);

  useEffect(() => {
    console.log(cmId, "cmId");
    const asmList = localStorage.getItem("asmList");
    if (checkInvalid(asmList)) {
      localStorage.setItem("asmList", JSON.stringify([]));
    } else if (
      JSON.parse(asmList).length >= localStorage.getItem("activeCmId") &&
      JSON.parse(asmList)[Number(localStorage.getItem("activeCmId"))]
    ) {
      const data = JSON.parse(asmList)[localStorage.getItem("activeCmId")];
      // setAsmStatsApi({ ...data.stats });
      console.log(data.details, "data.details in asmList");
      if (!checkInvalid(data.details)) {
        setAsmDummyData([...data.details]);
        data.details.forEach(() => {
          active.push(false);
        });
      }
    }
    const asyncFunc = async () => {
      setLoading(true);
      // const res1 = await fetch(ASM_LIST_URL, {
      //   method: "POST",
      //   headers: {
      //     "Content-type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     cm_id: cmIdFromParam,
      //   }),
      // });
      const res1 = await fetch(ASM_LIST_URL2 + `?cm_id=${cmIdFromParam}`);
      if (res1.ok) {
        const res = await res1.json();
        if (res.error) {
          setLoading(false);
        } else {
          // console.log(res,"ASM LIST");
          const arrangeArr = res.asm_list.map((obj) => {
            return {
              asm_id: obj.asm_id,
              is_active: obj.onboard_status,
              tl_name: obj.full_name,
              team_size: obj.team_size,
              // team_size:obj.total_fse,
              doj: obj.doj,
              location: obj.city,
              checkin_thismonth: 0,
              checkin_today: 0,
              leads_thismonth: 0,
              leads_today: 0,
              approved_thismonth: 0,
              approved_today: 0,
              onboarded_by: obj.onboarded_by_name,
              // onboarded_on: obj.onboarded_on.substr(0, 10),
              inactivated_by: "",
              profile_image: obj.profile_image,
              mobile_number: obj.mobile_number,
              email_id: obj.email_id,
            };
          });
          setLoading(false);
          setAsmDummyData([...arrangeArr]);
          arrangeArr.forEach(() => {
            active.push(false);
          });
          context.asmList = [...arrangeArr];
          setContext({ ...context });
          // getCountsAndSet(arrangeArr);
          setLeadCountLoader(true);
          fetch(ASM_LIST_COUNTS + `?cm_id=${cmIdFromParam}`)
            .then((r) => r.json())
            .then((count) => {
              console.log(count, "<-counts", arrangeArr, "<-arrangeArr");
              const newArr = arrangeArr.map((obj1) => {
                const myasmId = obj1.asm_id;
                const onlyasmIdFromCount = count.data.map((c) => {
                  return c.asm_id;
                });
                const matchedIndex = onlyasmIdFromCount.indexOf(myasmId);
                console.log(myasmId, "<-myasmid", matchedIndex, "<- matched");
                if (matchedIndex !== -1) {
                  obj1.approved_thismonth = count.data[matchedIndex].approved;
                  obj1.approved_today = count.data[matchedIndex].approved_today;
                  obj1.leads_thismonth = count.data[matchedIndex].total;
                  obj1.leads_today = count.data[matchedIndex].total_today;
                }
                // const approved=count.data[count.data.map((c)=>{
                //   return c.asm_id;
                // }).indexOf(myasmId)]
                // obj1.approved_thismonth=approved;
                return obj1;
              });

              console.log(newArr, "newArr");

              // arrangeArr[0].approved_thismonth=count.data[0].approved;
              setAsmDummyData([...newArr]);
              setLeadCountLoader(false);
              setCheckinCountLoader(true);
              fetch(ASM_LIST_CHECKINS + `?cm_id=${cmIdFromParam}`)
                .then((r) => r.json())
                .then((rCheckin) => {
                  if (!rCheckin.error) {
                    console.log(rCheckin, "<- asm wise checkins");
                    const newArr2 = newArr.map((obj1) => {
                      const myasmId2 = obj1.asm_id;
                      const onlyasmIdFromCount = rCheckin.data.map((c) => {
                        return c.asm_id;
                      });
                      const matchedIndex2 =
                        onlyasmIdFromCount.indexOf(myasmId2);
                      console.log(
                        myasmId2,
                        "<-myasmid2",
                        matchedIndex2,
                        "<- matched2"
                      );
                      if (matchedIndex2 !== -1) {
                        obj1.checkin_thismonth =
                          rCheckin.data[matchedIndex2].this_month_checkins;
                        obj1.checkin_today =
                          rCheckin.data[matchedIndex2].today_checkins;
                      }
                      // const approved=count.data[count.data.map((c)=>{
                      //   return c.asm_id;
                      // }).indexOf(myasmId)]
                      // obj1.approved_thismonth=approved;
                      return obj1;
                    });

                    setCheckinCountLoader(false);
                    setAsmDummyData([...newArr2]);

                    // set asmDetails in localStorage
                    const activeCmId = localStorage.getItem("activeCmId");
                    const myAsmList = JSON.parse(
                      localStorage.getItem("asmList")
                    );
                    if (!checkInvalid(myAsmList[activeCmId])) {
                      myAsmList[activeCmId].details = [...newArr2];
                    } else {
                      myAsmList[activeCmId] = {
                        details: [...newArr2],
                      };
                    }
                    localStorage.setItem(
                      "asmList",
                      JSON.stringify([...myAsmList])
                    );
                  } else {
                    Swal.fire({
                      icon: "error",
                      text:
                        "Error: while fetching checkins asm wise " +
                        JSON.stringify({ response: rCheckin }),
                    });
                    setCheckinCountLoader(false);
                  }
                })
                .catch((e) => {
                  console.log(e, "Error While Fetching Checkin count ");
                  Swal.fire({
                    icon: "error",
                    text:
                      "Error: while fetching checkins asm wise " +
                      JSON.stringify({ error: e }),
                  });
                });
            })
            .catch((e) => {
              setLeadCountLoader(false);
              Swal.fire({
                icon: "error",
                text: "failed!" + JSON.stringify({ error: e }),
              });
            });
        }
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: "Unable to Fetch",
        });
      }
    };
    asyncFunc();
  }, []);

  console.log(number1, "something");
  return (
    <div className="tl_list">
      <InactiveCommentPP
        open={openInactiveComment}
        setOpen={setOpenInactiveComment}
      />
      <ActiveCommentPP
        open={openActiveComment}
        setOpen={setOpenActiveComment}
      />
      <CmListToRemapAsm open={openCmList} setOpen={setOpenCmList} />
      <div className="dashtl_header">
        {/* <div className="tl_list_row1" style={{justifyContent:"flex-start"}}>
          <Link to="/dash">
            <img
              src={window.location.origin + "/images/svg/back_button.svg"}
              alt="profile"
              className="back_butt"
            />
          </Link>
          <div className="track_tl">
            <div className="training_assign">
              <img
                src={
                  window.location.origin + "/images/svg/training_assigned.svg"
                }
                alt="profile"
                className="complete_track"
              />
              <p className="training_p1">Training Assigned</p>
            </div>

            <div className="dash_tl_line"></div>
            <div className="training_assign">
              <img
                src={
                  window.location.origin + "/images/svg/training completed.svg"
                }
                alt="profile"
                className="complete_track"
              />
              <p className="training_p1" style={{ color: "#777777" }}>
                Training Completed
              </p>
            </div>

            <div className="dash_tl_line1"></div>
            <div className="training_assign">
              <img
                src={
                  window.location.origin + "/images/svg/training completed.svg"
                }
                alt="profile"
                className="complete_track"
              />
              <p className="training_p1" style={{ color: "#777777" }}>
                Payment
              </p>
            </div>
          </div>
        </div> */}

        <div className="tl_list_row1">
          {/* <div className="dashtl_h_col1">
            <img
              src={window.location.origin + "/images/png/profile1.png"}
              alt="profile"
              className="profile_tl"
            />
            <h3 className="tl_list_h3">anjana</h3>
            <p className="dashtl_h_p5">TL</p>
          </div> */}
          <div className="dashtl_h_col2">
            <img
              src={window.location.origin + "/images/profile_default_new.svg"}
              // src={`${
              //   Object.keys(myCmStats).length > 2
              //     ? myCmStats.profile_image
              //     : "XXXX"
              // }`}
              alt="profile"
              className="profile_tl"
              onError={(e) => {
                e.target.src =
                  window.location.origin + "/images/profile_default_new.svg";
              }}
            />
            <div className="dashtl_h_row1"></div>
            <div className="dashtl_h_row2">
              <p className="dashtl_h_p1">
                {Object.keys(myCmStats).length > 2 ? myCmStats.cm_name : "XXXX"}
              </p>
              <p className="dashtl_h_p5">City Manager</p>
              <p className="dashtl_h_p2">
                {Object.keys(myCmStats).length > 2
                  ? myCmStats.cm_location
                  : "XXXX"}
              </p>
            </div>
          </div>
          <div className="dashtl_h_col3">
            <div className="dashtl_h_cards">
              <p className="dashtl_h_p3">Checkin</p>
              <p className="dashtl_h_p4">
                {Object.keys(myCmStats).length > 2
                  ? myCmStats.total_checkin
                  : "XXXX"}
              </p>
              <div style={{ color: "black", marginTop: "-15px" }}>
                +
                {Object.keys(myCmStats).length > 2
                  ? myCmStats.total_checkin_today
                  : "XXXX"}{" "}
                Today
              </div>
            </div>
            <div className="dashtl_h_cards">
              <p className="dashtl_h_p3">Leads</p>
              <p className="dashtl_h_p4">
                {Object.keys(myCmStats).length > 2 ? new_stats.total : "XXXX"}
              </p>
              <div style={{ color: "black", marginTop: "-15px" }}>
                +
                {Object.keys(myCmStats).length > 2
                  ? new_stats.total_total
                  : "XXXX"}{" "}
                Today
              </div>
            </div>

            <div className="dashtl_h_cards">
              <p className="dashtl_h_p3">Approved</p>
              <p className="dashtl_h_p4">
                {Object.keys(myCmStats).length > 2
                  ? new_stats.approved
                  : "XXXX"}
              </p>
              <div style={{ color: "black", marginTop: "-15px" }}>
                +
                {Object.keys(myCmStats).length > 2
                  ? new_stats.total_approved
                  : "XXXX"}{" "}
                Today
              </div>
            </div>
            <div className="dashtl_h_cards">
              <p className="dashtl_h_p3">Active</p>
              <p className="dashtl_h_p4">
                {Object.keys(myCmStats).length > 2
                  ? myCmStats.total_asm_count
                  : "XX"}
                :
                {Object.keys(myCmStats).length > 2 ? myCmStats.total_fse : "XX"}
              </p>
              <div style={{ color: "black", marginTop: "-15px" }}>ASM:FSE</div>
            </div>
          </div>
          {/* <div className="dashtl_h_col4">
            <img
              src={window.location.origin + "/images/svg/message.svg"}
              alt="profile"
              className="icon_svg"
            />
            <img
              src={window.location.origin + "/images/svg/call.svg"}
              alt="profile"
              className="icon_svg"
            />
          </div> */}
        </div>
      </div>

      {/* <div className="asmActionBtns">
        <button>Download Report</button>
        <div
          className="button_add"
          style={{ width: "95px", marginRight: "15px" }}
          onClick={() => {
            history.push(`/dash/addAsm/${cmIdFromParam}`);
          }}
        >
          <img
            src={window.location.origin + "/images/svg1/add.svg"}
            alt="ott"
            className="add_cm_image"
          />
          Add ASM
        </div>
      </div> */}
      {/* <div className="container2">
        <div className="bar_container">
          <p className="mystat_p2" style={{ marginTop: "15px" }}>
            Overview
          </p>
          <Chart
            options={apexBarAsm.options}
            series={apexBarAsm.series}
            type="bar"
            height={350}
            width={2200}
          />
        </div>
        <div className="calender_container">
          <Calender2 />
          <div style={{ margin: "20px" }}>Performance View</div>
          <Chart
            options={apexMultiline.options}
            series={apexMultiline.series}
            type="line"
            height={330}
            width={370}
          />
        </div>
      </div>
       */}
      <div className="tl_list_row2">
        <div className="fseListTitle">List of TSM</div>

        <div className="myRow">
          <div
            className={`nav-tab ${activeFse ? "active" : ""}`}
            onClick={() => {
              setActiveFse(true);
              setInactiveFse(false);
            }}
          >
            ACTIVE
          </div>
          <div
            className={`nav-tab ${inactiveFse ? "active" : ""}`}
            onClick={() => {
              setInactiveFse(true);
              setActiveFse(false);
            }}
          >
            INACTIVE
          </div>
          <div
            className={`nav-tab ${!activeFse && !inactiveFse ? "active" : ""}`}
            onClick={() => {
              setInactiveFse(false);
              setActiveFse(false);
            }}
          >
            ALL
          </div>
        </div>
      </div>
      {/* <div className="tl_list_row2">
        <div className="myTeam_card">
          <p className="mystat_p2" style={{ marginTop: "15px" }}>
            Daywise overview
          </p>
          <Chart
            options={apexMultiline.options}
            series={apexMultiline.series}
            type="line"
            height={250}
          />
        </div>
      </div> */}

      <div className="dashtl1_row1">
        {/* {1 === 0 ? (
          <div className="dash_row4">
            <></>
          </div>
        ) : ( */}
        {/* {props.fseList.map((fse_data) => (         key={fse_data.user_id}*/}
        {asmDummyData.map(
          (
            {
              asm_id,
              is_active,
              tl_name,
              team_size,
              doj,
              location,
              checkin_thismonth,
              checkin_today,
              leads_thismonth,
              leads_today,
              approved_thismonth,
              approved_today,
              onboarded_by,
              onboarded_on,
              inactivated_by,
              profile_image,
              mobile_number,
              email_id,
            },
            index
          ) => (
            <>
              {activeFse &&
              (is_active === "onboarded" ||
                is_active === "cooling" ||
                is_active === "none") ? (
                <>
                  <div className="dashtl_card">
                    <div className="dashtl_card_row1">
                      <div className="dashtl1_card_col1">
                        <div className="dashtl_small_col">
                          <img
                            src={`${profile_image}`}
                            alt="profile"
                            className="profile_tl"
                            onError={(e) => {
                              e.target.src =
                                window.location.origin +
                                "/images/profile_default_new.svg";
                            }}
                          />
                          {is_active === "onboarded" ? (
                            <p className="tllist_active">Onboarded</p>
                          ) : null}
                          {is_active === "none" ? (
                            <>
                              <p
                                className="tllist_active"
                                style={{
                                  backgroundColor: "#ebb51b99",
                                  color: "#a55d1d",
                                }}
                              >
                                New
                              </p>
                            </>
                          ) : (
                            <>{null}</>
                          )}
                          {is_active === "hold" ? (
                            <>
                              <p
                                className="tllist_active"
                                style={{ color: "red", background: "#ffd9d9" }}
                              >
                                Hold
                              </p>
                            </>
                          ) : (
                            <>{null}</>
                          )}
                          {is_active === "terminated" ? (
                            <>
                              <p
                                className="tllist_active"
                                style={{ color: "red", background: "#ffd9d9" }}
                              >
                                Terminated
                              </p>
                            </>
                          ) : (
                            <>{null}</>
                          )}
                          {is_active === "inactive" ? (
                            <>
                              <p
                                className="tllist_active"
                                style={{ color: "red", background: "#ffd9d9" }}
                              >
                                Inactive
                              </p>
                            </>
                          ) : (
                            <>{null}</>
                          )}
                        </div>

                        <div className="dashtl1_card_col2 ">
                          <div
                            className="dashtl_card_row2"
                            style={{ margin: "10px" }}
                          >
                            {/* {1 !== "NA" ? (
                    <img
                      src={window.location.origin + "/images/svg/active.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  ) : (
                    <img
                      src={window.location.origin + "/images/svg/inactive.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  )} */}
                            <p className="dashtl_p6">{`${tl_name}`}</p>
                          </div>
                          <div className="dashtl_card_row3_align">
                            <div className="dashtl_card_row3">
                              <p className="dashtl_card_p1">
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/mobile_num.svg"
                                  }
                                  alt="number"
                                />
                              </p>
                              <p className="dashtl_card_p2">{mobile_number}</p>
                            </div>
                            <div className="dashtl_card_row3">
                              <p className="dashtl_card_p1">
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/team_size.svg"
                                  }
                                  alt="team_size"
                                />
                              </p>
                              <p className="dashtl_card_p2">{team_size}</p>
                            </div>
                            <div className="dashtl_card_row3">
                              <p className="dashtl_card_p1">
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/calendar.svg"
                                  }
                                  alt="calendar"
                                />
                              </p>
                              <p className="dashtl_card_p2">{`${doj.substring(
                                8,
                                10
                              )}/${doj.substring(5, 7)}/${doj.substring(
                                0,
                                4
                              )}`}</p>
                            </div>
                            <div className="dashtl_card_row3">
                              <p className="dashtl_card_p1">
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/location.svg"
                                  }
                                  alt="location"
                                />
                              </p>
                              <p
                                className="dashtl_card_p2"
                                style={{ textTransform: "capitalize" }}
                              >
                                {location}
                              </p>
                            </div>
                            <div className="dashtl_card_row3">
                              <p className="dashtl_card_p1">
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/svg/email.svg"
                                  }
                                  alt="location"
                                />
                              </p>
                              <p className="dashtl_card_p2">{email_id}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="dashtl_card_col3"
                        onClick={() => handleClick(index)}
                      >
                        <img
                          src={
                            window.location.origin +
                            "/images/svg/view_details.svg"
                          }
                          alt="profile"
                          className="svg_myteam"
                        />

                        {active[index] ? (
                          <div className="dashtl_dropdown">
                            <div className="tl_dropdown">
                              <p
                                className="myteam_drop_p1"
                                // onClick={handlePop}
                                onClick={() => {
                                  context.activeAsmId =
                                    asmDummyData[index].asm_id;
                                  setContext({ ...context });
                                  localStorage.setItem(
                                    "asm_id",
                                    asmDummyData[index].asm_id
                                  );
                                  setTimeout(() => {
                                    console.log("time pass");
                                  }, 100);
                                  console.log(
                                    context.activeAsmId,
                                    "active asm id"
                                  );
                                  console.log("i am working");
                                  window.scrollTo(0, 0);
                                  history.push(
                                    `/dash/${asmDummyData[index].asm_id}/fseList`
                                  );
                                }}
                              >
                                View Team
                              </p>
                              {/* <p
                                className="myteam_drop_p1"
                                onClick={() => {
                                  window.scrollTo(0, 0);
                                  history.push(
                                    `/dash/asmProfile/${asmDummyData[index].asm_id}`
                                  );
                                }}
                              >
                                View Profile
                              </p> */}
                              {/* <p className="myteam_drop_p1">PIP Mail</p> */}
                              {/* <p
                                className="myteam_drop_p1"
                                onClick={() => {
                                  // handleFseRemap(fse_id);
                                  setOpenCmList(true);
                                  context.activeAsmId =
                                    asmDummyData[index].asm_id;
                                  setContext({ ...context });
                                }}
                              >
                                Remap
                              </p> */}
                              {/* <p
                                className="myteam_drop_p1"
                                onClick={() => {
                                  setOpenSoiModel(true);
                                  context.activeAsmId =
                                    asmDummyData[index].asm_id;
                                  setContext({ ...context });
                                }}
                              >
                                Send final offer letter
                              </p> */}
                              {/* <p
                                className="myteam_drop_p1"
                                onClick={() => {
                                  context.updateOnboardStatusData = {
                                    fse_user_id: asm_id,
                                    hr_admin_id:
                                      localStorage.getItem("hr_admin_id"),
                                    action_type: "active",
                                    comment: "",
                                  };
                                  setContext({ ...context });
                                  setOpenActiveComment(true);
                                }}
                              >
                                Mark Active
                              </p> */}
                              {/* <p
                                className="myteam_drop_p1"
                                style={{
                                  backgroundColor: "rgba(255, 0, 0,0.2)",
                                  color: "black",
                                }}
                                onClick={() => {
                                  context.updateOnboardStatusData = {
                                    fse_user_id: asm_id,
                                    hr_admin_id:
                                      localStorage.getItem("hr_admin_id"),
                                    action_type: "inactive",
                                    comment: "",
                                  };
                                  setContext({ ...context });
                                  setOpenInactiveComment(true);
                                  // updateOnboardStatus(fse_id,
                                  //   localStorage.getItem("hr_admin_id"),
                                  //   inactiveComment,
                                  //   'inactive')
                                }}
                              >
                                Mark Inactive
                              </p> */}
                              {/* <p
                          className="myteam_drop_p1"
                          style={{
                            backgroundColor: "rgba(255, 0, 0,0.2)",
                            color: "black",
                          }}
                          onClick={() => {
                            terminateAsmFunc(asmDummyData[index].asm_id);
                          }}
                        >
                          Terminate
                        </p> */}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <div className="dashtl_card_row2">
                      <div className="dashtl_smallcard">
                        <div className="with-loader">
                          {leadCountLoader ? (
                            <Spinner type="grow" color="primary" />
                          ) : null}

                          <p className="dashtl_card_p1">Leads</p>
                        </div>
                        <p className="dashtl_p4">{leads_thismonth}</p>
                        {leads_today !== "0" ? (
                          <p className="dashtl_p5">+{leads_today}</p>
                        ) : (
                          <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                            00
                          </p>
                        )}
                      </div>
                      <div className="dashtl_smallcard">
                        <div className="with-loader">
                          {leadCountLoader ? (
                            <Spinner type="grow" color="primary" />
                          ) : null}
                          <p className="dashtl_card_p1">Approved</p>
                        </div>

                        <p className="dashtl_p4">{approved_thismonth}</p>
                        {approved_today !== "0" ? (
                          <p className="dashtl_p5">+{approved_today}</p>
                        ) : (
                          <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                            00
                          </p>
                        )}
                      </div>
                      <div className="dashtl_smallcard">
                        <div className="with-loader">
                          {checkinCountLoader ? (
                            <Spinner type="grow" color="primary" />
                          ) : null}
                          <p className="dashtl_card_p1">Checkins</p>
                        </div>

                        <p className="dashtl_p4">{checkin_thismonth}</p>
                        {checkin_today !== "0" ? (
                          <p className="dashtl_p5">+{checkin_today}</p>
                        ) : (
                          <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                            00
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="my-card-footer">
                      {is_active !== "onboarded" ? null : (
                        <div>
                          Onboarded by: {onboarded_by} &nbsp; &nbsp;{" "}
                          {onboarded_on} &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                        </div>
                      )}
                      {/* <div>
                  {inactivated_by !== "" ? (
                    <>
                      <span style={{ color: "red" }}>Inactivated by :</span>{" "}
                      {inactivated_by}
                    </>
                  ) : (
                    <></>
                  )}
                </div> */}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {inactiveFse &&
                  is_active !== "onboarded" &&
                  is_active !== "cooling" &&
                  is_active !== "none" ? (
                    <>
                      <div className="dashtl_card">
                        <div className="dashtl_card_row1">
                          <div className="dashtl1_card_col1">
                            <div className="dashtl_small_col">
                              <img
                                src={`${profile_image}`}
                                alt="profile"
                                className="profile_tl"
                                onError={(e) => {
                                  e.target.src =
                                    window.location.origin +
                                    "/images/profile_default_new.svg";
                                }}
                              />
                              {is_active === "onboarded" ? (
                                <p className="tllist_active">Onboarded</p>
                              ) : null}
                              {is_active === "none" ? (
                                <>
                                  <p
                                    className="tllist_active"
                                    style={{
                                      backgroundColor: "#ebb51b99",
                                      color: "#a55d1d",
                                    }}
                                  >
                                    New
                                  </p>
                                </>
                              ) : (
                                <>{null}</>
                              )}
                              {is_active === "hold" ? (
                                <>
                                  <p
                                    className="tllist_active"
                                    style={{
                                      color: "red",
                                      background: "#ffd9d9",
                                    }}
                                  >
                                    Hold
                                  </p>
                                </>
                              ) : (
                                <>{null}</>
                              )}
                              {is_active === "terminated" ? (
                                <>
                                  <p
                                    className="tllist_active"
                                    style={{
                                      color: "red",
                                      background: "#ffd9d9",
                                    }}
                                  >
                                    Terminated
                                  </p>
                                </>
                              ) : (
                                <>{null}</>
                              )}
                              {is_active === "inactive" ? (
                                <>
                                  <p
                                    className="tllist_active"
                                    style={{
                                      color: "red",
                                      background: "#ffd9d9",
                                    }}
                                  >
                                    Inactive
                                  </p>
                                </>
                              ) : (
                                <>{null}</>
                              )}
                            </div>

                            <div className="dashtl1_card_col2 ">
                              <div
                                className="dashtl_card_row2"
                                style={{ margin: "10px" }}
                              >
                                {/* {1 !== "NA" ? (
                    <img
                      src={window.location.origin + "/images/svg/active.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  ) : (
                    <img
                      src={window.location.origin + "/images/svg/inactive.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  )} */}
                                <p className="dashtl_p6">{`${tl_name}`}</p>
                              </div>
                              <div className="dashtl_card_row3_align">
                                <div className="dashtl_card_row3">
                                  <p className="dashtl_card_p1">
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/mobile_num.svg"
                                      }
                                      alt="number"
                                    />
                                  </p>
                                  <p className="dashtl_card_p2">
                                    {mobile_number}
                                  </p>
                                </div>
                                <div className="dashtl_card_row3">
                                  <p className="dashtl_card_p1">
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/team_size.svg"
                                      }
                                      alt="team_size"
                                    />
                                  </p>
                                  <p className="dashtl_card_p2">{team_size}</p>
                                </div>
                                <div className="dashtl_card_row3">
                                  <p className="dashtl_card_p1">
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/calendar.svg"
                                      }
                                      alt="calendar"
                                    />
                                  </p>
                                  <p className="dashtl_card_p2">{`${doj.substring(
                                    8,
                                    10
                                  )}/${doj.substring(5, 7)}/${doj.substring(
                                    0,
                                    4
                                  )}`}</p>
                                </div>
                                <div className="dashtl_card_row3">
                                  <p className="dashtl_card_p1">
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/location.svg"
                                      }
                                      alt="location"
                                    />
                                  </p>
                                  <p
                                    className="dashtl_card_p2"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {location}
                                  </p>
                                </div>
                                <div className="dashtl_card_row3">
                                  <p className="dashtl_card_p1">
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/svg/email.svg"
                                      }
                                      alt="location"
                                    />
                                  </p>
                                  <p className="dashtl_card_p2">{email_id}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="dashtl_card_col3"
                            onClick={() => handleClick(index)}
                          >
                            <img
                              src={
                                window.location.origin +
                                "/images/svg/view_details.svg"
                              }
                              alt="profile"
                              className="svg_myteam"
                            />

                            {active[index] ? (
                              <div className="dashtl_dropdown">
                                <div className="tl_dropdown">
                                  <p
                                    className="myteam_drop_p1"
                                    // onClick={handlePop}
                                    onClick={() => {
                                      context.activeAsmId =
                                        asmDummyData[index].asm_id;
                                      setContext({ ...context });
                                      localStorage.setItem(
                                        "asm_id",
                                        asmDummyData[index].asm_id
                                      );
                                      setTimeout(() => {
                                        console.log("time pass");
                                      }, 100);
                                      console.log(
                                        context.activeAsmId,
                                        "active asm id"
                                      );
                                      console.log("i am working");
                                      window.scrollTo(0, 0);
                                      history.push(
                                        `/dash/${asmDummyData[index].asm_id}/fseList`
                                      );
                                    }}
                                  >
                                    View Team
                                  </p>
                                  {/* <p
                                    className="myteam_drop_p1"
                                    onClick={() => {
                                      window.scrollTo(0, 0);
                                      history.push(
                                        `/dash/asmProfile/${asmDummyData[index].asm_id}`
                                      );
                                    }}
                                  >
                                    View Profile
                                  </p> */}
                                  {/* <p className="myteam_drop_p1">PIP Mail</p> */}
                                  {/* <p
                                    className="myteam_drop_p1"
                                    onClick={() => {
                                      setOpenSoiModel(true);
                                      context.activeAsmId =
                                        asmDummyData[index].asm_id;
                                      setContext({ ...context });
                                    }}
                                  >
                                    Send final offer letter
                                  </p> */}
                                  {/* <p
                                    className="myteam_drop_p1"
                                    style={{
                                      backgroundColor: "rgba(0, 255, 0,0.2)",
                                      color: "black",
                                    }}
                                    onClick={() => {
                                      context.updateOnboardStatusData = {
                                        fse_user_id: asm_id,
                                        hr_admin_id:
                                          localStorage.getItem("hr_admin_id"),
                                        action_type: "active",
                                        comment: "",
                                      };
                                      setContext({ ...context });
                                      setOpenActiveComment(true);
                                    }}
                                  >
                                    Mark Active
                                  </p> */}
                                  {/* <p
                                    className="myteam_drop_p1"
                                    style={{
                                      backgroundColor: "rgba(255, 0, 0,0.2)",
                                      color: "black",
                                    }}
                                    onClick={() => {
                                      context.updateOnboardStatusData = {
                                        fse_user_id: asm_id,
                                        hr_admin_id:
                                          localStorage.getItem("hr_admin_id"),
                                        action_type: "inactive",
                                        comment: "",
                                      };
                                      setContext({ ...context });
                                      setOpenInactiveComment(true);
                                      // updateOnboardStatus(fse_id,
                                      //   localStorage.getItem("hr_admin_id"),
                                      //   inactiveComment,
                                      //   'inactive')
                                    }}
                                  >
                                    Mark Inactive
                                  </p> */}
                                  {/* <p
                          className="myteam_drop_p1"
                          style={{
                            backgroundColor: "rgba(255, 0, 0,0.2)",
                            color: "black",
                          }}
                          onClick={() => {
                            terminateAsmFunc(asmDummyData[index].asm_id);
                          }}
                        >
                          Terminate
                        </p> */}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>

                        <div className="dashtl_card_row2">
                          <div className="dashtl_smallcard">
                            <div className="with-loader">
                              {leadCountLoader ? (
                                <Spinner type="grow" color="primary" />
                              ) : null}

                              <p className="dashtl_card_p1">Leads</p>
                            </div>
                            <p className="dashtl_p4">{leads_thismonth}</p>
                            {leads_today !== "0" ? (
                              <p className="dashtl_p5">+{leads_today}</p>
                            ) : (
                              <p
                                className="dashtl_p5"
                                style={{ color: "#FF3B3B" }}
                              >
                                00
                              </p>
                            )}
                          </div>
                          <div className="dashtl_smallcard">
                            <div className="with-loader">
                              {leadCountLoader ? (
                                <Spinner type="grow" color="primary" />
                              ) : null}
                              <p className="dashtl_card_p1">Approved</p>
                            </div>

                            <p className="dashtl_p4">{approved_thismonth}</p>
                            {approved_today !== "0" ? (
                              <p className="dashtl_p5">+{approved_today}</p>
                            ) : (
                              <p
                                className="dashtl_p5"
                                style={{ color: "#FF3B3B" }}
                              >
                                00
                              </p>
                            )}
                          </div>
                          <div className="dashtl_smallcard">
                            <div className="with-loader">
                              {checkinCountLoader ? (
                                <Spinner type="grow" color="primary" />
                              ) : null}
                              <p className="dashtl_card_p1">Checkins</p>
                            </div>

                            <p className="dashtl_p4">{checkin_thismonth}</p>
                            {checkin_today !== "0" ? (
                              <p className="dashtl_p5">+{checkin_today}</p>
                            ) : (
                              <p
                                className="dashtl_p5"
                                style={{ color: "#FF3B3B" }}
                              >
                                00
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="my-card-footer">
                          {is_active !== "onboarded" ? null : (
                            <div>
                              Onboarded by: {onboarded_by} &nbsp; &nbsp;{" "}
                              {onboarded_on} &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                            </div>
                          )}
                          {/* <div>
                  {inactivated_by !== "" ? (
                    <>
                      <span style={{ color: "red" }}>Inactivated by :</span>{" "}
                      {inactivated_by}
                    </>
                  ) : (
                    <></>
                  )}
                </div> */}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {!activeFse && !inactiveFse ? (
                        <>
                          <div className="dashtl_card">
                            <div className="dashtl_card_row1">
                              <div className="dashtl1_card_col1">
                                <div className="dashtl_small_col">
                                  <img
                                    src={`${profile_image}`}
                                    alt="profile"
                                    className="profile_tl"
                                    onError={(e) => {
                                      e.target.src =
                                        window.location.origin +
                                        "/images/profile_default_new.svg";
                                    }}
                                  />
                                  {is_active === "onboarded" ? (
                                    <p className="tllist_active">Onboarded</p>
                                  ) : null}
                                  {is_active === "none" ? (
                                    <>
                                      <p
                                        className="tllist_active"
                                        style={{
                                          backgroundColor: "#ebb51b99",
                                          color: "#a55d1d",
                                        }}
                                      >
                                        New
                                      </p>
                                    </>
                                  ) : (
                                    <>{null}</>
                                  )}
                                  {is_active === "hold" ? (
                                    <>
                                      <p
                                        className="tllist_active"
                                        style={{
                                          color: "red",
                                          background: "#ffd9d9",
                                        }}
                                      >
                                        Hold
                                      </p>
                                    </>
                                  ) : (
                                    <>{null}</>
                                  )}
                                  {is_active === "terminated" ? (
                                    <>
                                      <p
                                        className="tllist_active"
                                        style={{
                                          color: "red",
                                          background: "#ffd9d9",
                                        }}
                                      >
                                        Terminated
                                      </p>
                                    </>
                                  ) : (
                                    <>{null}</>
                                  )}
                                  {is_active === "inactive" ? (
                                    <>
                                      <p
                                        className="tllist_active"
                                        style={{
                                          color: "red",
                                          background: "#ffd9d9",
                                        }}
                                      >
                                        Inactive
                                      </p>
                                    </>
                                  ) : (
                                    <>{null}</>
                                  )}
                                </div>

                                <div className="dashtl1_card_col2 ">
                                  <div
                                    className="dashtl_card_row2"
                                    style={{ margin: "10px" }}
                                  >
                                    {/* {1 !== "NA" ? (
                    <img
                      src={window.location.origin + "/images/svg/active.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  ) : (
                    <img
                      src={window.location.origin + "/images/svg/inactive.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  )} */}
                                    <p className="dashtl_p6">{`${tl_name}`}</p>
                                  </div>
                                  <div className="dashtl_card_row3_align">
                                    <div className="dashtl_card_row3">
                                      <p className="dashtl_card_p1">
                                        <img
                                          src={
                                            window.location.origin +
                                            "/images/mobile_num.svg"
                                          }
                                          alt="number"
                                        />
                                      </p>
                                      <p className="dashtl_card_p2">
                                        {mobile_number}
                                      </p>
                                    </div>
                                    <div className="dashtl_card_row3">
                                      <p className="dashtl_card_p1">
                                        <img
                                          src={
                                            window.location.origin +
                                            "/images/team_size.svg"
                                          }
                                          alt="team_size"
                                        />
                                      </p>
                                      <p className="dashtl_card_p2">
                                        {team_size}
                                      </p>
                                    </div>
                                    <div className="dashtl_card_row3">
                                      <p className="dashtl_card_p1">
                                        <img
                                          src={
                                            window.location.origin +
                                            "/images/calendar.svg"
                                          }
                                          alt="calendar"
                                        />
                                      </p>
                                      <p className="dashtl_card_p2">{`${doj.substring(
                                        8,
                                        10
                                      )}/${doj.substring(5, 7)}/${doj.substring(
                                        0,
                                        4
                                      )}`}</p>
                                    </div>
                                    <div className="dashtl_card_row3">
                                      <p className="dashtl_card_p1">
                                        <img
                                          src={
                                            window.location.origin +
                                            "/images/location.svg"
                                          }
                                          alt="location"
                                        />
                                      </p>
                                      <p
                                        className="dashtl_card_p2"
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {location}
                                      </p>
                                    </div>
                                    <div className="dashtl_card_row3">
                                      <p className="dashtl_card_p1">
                                        <img
                                          src={
                                            window.location.origin +
                                            "/images/svg/email.svg"
                                          }
                                          alt="location"
                                        />
                                      </p>
                                      <p className="dashtl_card_p2">
                                        {email_id}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="dashtl_card_col3"
                                onClick={() => handleClick(index)}
                              >
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/svg/view_details.svg"
                                  }
                                  alt="profile"
                                  className="svg_myteam"
                                />

                                {active[index] ? (
                                  <div className="dashtl_dropdown">
                                    <div className="tl_dropdown">
                                      <p
                                        className="myteam_drop_p1"
                                        // onClick={handlePop}
                                        onClick={() => {
                                          context.activeAsmId =
                                            asmDummyData[index].asm_id;
                                          setContext({ ...context });
                                          localStorage.setItem(
                                            "asm_id",
                                            asmDummyData[index].asm_id
                                          );
                                          setTimeout(() => {
                                            console.log("time pass");
                                          }, 100);
                                          console.log(
                                            context.activeAsmId,
                                            "active asm id"
                                          );
                                          console.log("i am working");
                                          window.scrollTo(0, 0);
                                          history.push(
                                            `/dash/${asmDummyData[index].asm_id}/fseList`
                                          );
                                        }}
                                      >
                                        View Team
                                      </p>
                                      {/* <p
                                        className="myteam_drop_p1"
                                        onClick={() => {
                                          window.scrollTo(0, 0);
                                          history.push(
                                            `/dash/asmProfile/${asmDummyData[index].asm_id}`
                                          );
                                        }}
                                      >
                                        View Profile
                                      </p> */}
                                      {/* <p className="myteam_drop_p1">PIP Mail</p> */}
                                      {/* <p
                                    className="myteam_drop_p1"
                                    onClick={() => {
                                      setOpenSoiModel(true);
                                      context.activeAsmId =
                                        asmDummyData[index].asm_id;
                                      setContext({ ...context });
                                    }}
                                  >
                                    Send final offer letter
                                  </p> */}
                                      {/* <p
                                    className="myteam_drop_p1"
                                    onClick={() => {
                                      context.updateOnboardStatusData = {
                                        fse_user_id: asm_id,
                                        hr_admin_id:
                                          localStorage.getItem("hr_admin_id"),
                                        action_type: "active",
                                        comment: "",
                                      };
                                      setContext({ ...context });
                                      setOpenActiveComment(true);
                                    }}
                                  >
                                    Mark Active
                                  </p> */}
                                      {/* <p
                                    className="myteam_drop_p1"
                                    style={{
                                      backgroundColor: "rgba(255, 0, 0,0.2)",
                                      color: "black",
                                    }}
                                    onClick={() => {
                                      context.updateOnboardStatusData = {
                                        fse_user_id: asm_id,
                                        hr_admin_id:
                                          localStorage.getItem("hr_admin_id"),
                                        action_type: "inactive",
                                        comment: "",
                                      };
                                      setContext({ ...context });
                                      setOpenInactiveComment(true);
                                      // updateOnboardStatus(fse_id,
                                      //   localStorage.getItem("hr_admin_id"),
                                      //   inactiveComment,
                                      //   'inactive')
                                    }}
                                  >
                                    Mark Inactive
                                  </p> */}
                                      {/* <p
                          className="myteam_drop_p1"
                          style={{
                            backgroundColor: "rgba(255, 0, 0,0.2)",
                            color: "black",
                          }}
                          onClick={() => {
                            terminateAsmFunc(asmDummyData[index].asm_id);
                          }}
                        >
                          Terminate
                        </p> */}
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>

                            <div className="dashtl_card_row2">
                              <div className="dashtl_smallcard">
                                <div className="with-loader">
                                  {leadCountLoader ? (
                                    <Spinner type="grow" color="primary" />
                                  ) : null}

                                  <p className="dashtl_card_p1">Leads</p>
                                </div>
                                <p className="dashtl_p4">{leads_thismonth}</p>
                                {leads_today !== "0" ? (
                                  <p className="dashtl_p5">+{leads_today}</p>
                                ) : (
                                  <p
                                    className="dashtl_p5"
                                    style={{ color: "#FF3B3B" }}
                                  >
                                    00
                                  </p>
                                )}
                              </div>
                              <div className="dashtl_smallcard">
                                <div className="with-loader">
                                  {leadCountLoader ? (
                                    <Spinner type="grow" color="primary" />
                                  ) : null}
                                  <p className="dashtl_card_p1">Approved</p>
                                </div>

                                <p className="dashtl_p4">
                                  {approved_thismonth}
                                </p>
                                {approved_today !== "0" ? (
                                  <p className="dashtl_p5">+{approved_today}</p>
                                ) : (
                                  <p
                                    className="dashtl_p5"
                                    style={{ color: "#FF3B3B" }}
                                  >
                                    00
                                  </p>
                                )}
                              </div>
                              <div className="dashtl_smallcard">
                                <div className="with-loader">
                                  {checkinCountLoader ? (
                                    <Spinner type="grow" color="primary" />
                                  ) : null}
                                  <p className="dashtl_card_p1">Checkins</p>
                                </div>

                                <p className="dashtl_p4">{checkin_thismonth}</p>
                                {checkin_today !== "0" ? (
                                  <p className="dashtl_p5">+{checkin_today}</p>
                                ) : (
                                  <p
                                    className="dashtl_p5"
                                    style={{ color: "#FF3B3B" }}
                                  >
                                    00
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="my-card-footer">
                              {is_active !== "onboarded" ? null : (
                                <div>
                                  Onboarded by: {onboarded_by} &nbsp; &nbsp;{" "}
                                  {onboarded_on} &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                                </div>
                              )}
                              {/* <div>
                  {inactivated_by !== "" ? (
                    <>
                      <span style={{ color: "red" }}>Inactivated by :</span>{" "}
                      {inactivated_by}
                    </>
                  ) : (
                    <></>
                  )}
                </div> */}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )
        )}
        {/* <div className="dashtl_card">
          <div className="dashtl_card_row1">
            <div className="dashtl1_card_col1">
              <div className="dashtl_small_col">
                <img
                  src={window.location.origin + "/images/png/fse.png"}
                  alt="profile"
                  className="profile_tl"
                />
                {1 !== "NA" ? (
                  <p className="tllist_active">active</p>
                ) : (
                  <p
                    className="tllist_active"
                    style={{ color: "red", background: "#ffd9d9" }}
                  >
                    inactive
                  </p>
                )}
              </div>

              <div className="dashtl1_card_col2 ">
                <div className="dashtl_card_row2" style={{ margin: "10px" }}>
                  {1 !== "NA" ? (
                    <img
                      src={window.location.origin + "/images/svg/active.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  ) : (
                    <img
                      src={window.location.origin + "/images/svg/inactive.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  )}
                  <p className="dashtl_p6">anjana</p>
                </div>
                <div className="dashtl_card_row3">
                  <p className="dashtl_card_p1">Team Size:</p>
                  <p className="dashtl_card_p2">1234</p>
                </div>
                <div className="dashtl_card_row3">
                  <p className="dashtl_card_p1">DOJ:</p>
                  <p className="dashtl_card_p2">12/06/2021</p>
                </div>
                <div className="dashtl_card_row3">
                  <p className="dashtl_card_p1">Lodcation:</p>
                  <p className="dashtl_card_p2">Bangalore</p>
                </div>
              </div>
            </div>
            <div
              className="dashtl_card_col3"

            >
              <img
                src={window.location.origin + "/images/svg/view_details.svg"}
                alt="profile"
                className="svg_myteam"
              />

              {

                active.length < 0 ? ( 
                  <div className="dashtl_dropdown">
                    <div className="tl_dropdown">
                      <p className="myteam_drop_p1" onClick={handlePop}>
                        View details
                      </p>
                      <p className="myteam_drop_p1">Download report</p>
                      <p className="myteam_drop_p1">Mark as Inactive</p>
                      <p className="myteam_drop_p1">Call</p>
                    </div>
                  </div>
                ) : (
                  <></>
                )
              }
            </div>
          </div>

          <div className="dashtl_card_row2">
            <div className="dashtl_smallcard">
              <p className="dashtl_card_p1">Checkins</p>
              <p className="dashtl_p4">123</p>
              {1 !== "NA" ? (
                <p className="dashtl_p5">+123</p>
              ) : (
                <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                  0000
                </p>
              )}
            </div>
            <div className="dashtl_smallcard">
              <p className="dashtl_card_p1">Leads</p>
              <p className="dashtl_p4">123</p>
              {1 !== "NA" ? (
                <p className="dashtl_p5">+123</p>
              ) : (
                <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                  00
                </p>
              )}
            </div>
            <div className="dashtl_smallcard">
              <p className="dashtl_card_p1">Approved</p>
              <p className="dashtl_p4">123</p>
              {1 !== "NA" ? (
                <p className="dashtl_p5">+123</p>
              ) : (
                <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                  00
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="dashtl_card">
          <div className="dashtl_card_row1">
            <div className="dashtl1_card_col1">
              <div className="dashtl_small_col">
                <img
                  src={window.location.origin + "/images/png/fse.png"}
                  alt="profile"
                  className="profile_tl"
                />
                {1 !== "NA" ? (
                  <p className="tllist_active">active</p>
                ) : (
                  <p
                    className="tllist_active"
                    style={{ color: "red", background: "#ffd9d9" }}
                  >
                    inactive
                  </p>
                )}
              </div>

              <div className="dashtl1_card_col2 ">
                <div className="dashtl_card_row2" style={{ margin: "10px" }}>
                  {1 !== "NA" ? (
                    <img
                      src={window.location.origin + "/images/svg/active.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  ) : (
                    <img
                      src={window.location.origin + "/images/svg/inactive.svg"}
                      alt="profile"
                      className="dash_active"
                    />
                  )}
                  <p className="dashtl_p6">anjana</p>
                </div>
                <div className="dashtl_card_row3">
                  <p className="dashtl_card_p1">Fse Id:</p>
                  <p className="dashtl_card_p2">1234</p>
                </div>
                <div className="dashtl_card_row3">
                  <p className="dashtl_card_p1">DOJ:</p>
                  <p className="dashtl_card_p2">12/06/2021</p>
                </div>
              </div>
            </div>
            <div
              className="dashtl_card_col3"
              
            >
              <img
                src={window.location.origin + "/images/svg/view_details.svg"}
                alt="profile"
                className="svg_myteam"
              />

              {
                
                active.length < 0 ? ( 
                  <div className="dashtl_dropdown">
                    <div className="tl_dropdown">
                      <p className="myteam_drop_p1" onClick={handlePop}>
                        View details
                      </p>
                      <p className="myteam_drop_p1">Download report</p>
                      <p className="myteam_drop_p1">Mark as Inactive</p>
                      <p className="myteam_drop_p1">Call</p>
                    </div>
                  </div>
                ) : (
                  <></>
                )
              }
            </div>
          </div>

          <div className="dashtl_card_row2">
            <div className="dashtl_smallcard">
              <p className="dashtl_card_p1">Leads</p>
              <p className="dashtl_p4">123</p>
              {1 !== "NA" ? (
                <p className="dashtl_p5">+123</p>
              ) : (
                <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                  0000
                </p>
              )}
            </div>
            <div className="dashtl_smallcard">
              <p className="dashtl_card_p1">QC Approved</p>
              <p className="dashtl_p4">123</p>
              {1 !== "NA" ? (
                <p className="dashtl_p5">+123</p>
              ) : (
                <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                  00
                </p>
              )}
            </div>
            <div className="dashtl_smallcard">
              <p className="dashtl_card_p1">Stars</p>
              <p className="dashtl_p4">123</p>
              {1 !== "NA" ? (
                <p className="dashtl_p5">+123</p>
              ) : (
                <p className="dashtl_p5" style={{ color: "#FF3B3B" }}>
                  00
                </p>
              )}
            </div>
          </div>
        </div>
 */}

        {/* ))} */}

        {/* // )} */}
      </div>
      <SendOlModal open={openSoiModel} setOpen={setOpenSoiModel} />
    </div>
  );
}

export default AsmList;
