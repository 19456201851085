import axios from "../../config/axios"

const getMyStats = (tl) => {
  return { type: "MY_STATS_DATA", payload: tl };
};

const startGetMyStats = (id) => {
  return (dispatch) => {

    const loadData = async () => {
      try {
        // console.log(id,"data")
        const dataContent = {
          "sow_id": id
        }
        const TotalData=await axios.post(`/myStats`,dataContent)
        dispatch(getMyStats(TotalData.data))  
        // console.log(TotalData.data,"my stats")
      } catch (err) {
        console.error(err);
      }
    };
    loadData();
  };
};

const resetMyStats = () => {
  return { type: "RESET_MY_STATS_DATA" };
  
};

const startResetMyStats = () => {
  return (dispatch) => {
    console.log("celar_id_redux")

    const loadData = async () => {
      try {
        dispatch(resetMyStats())
        console.log("pass")
        
      } catch (err) {
        console.error(err);
      }
    };
    loadData();
  };
}






export { getMyStats, startGetMyStats ,resetMyStats ,startResetMyStats };
