  
const checkEmailInitialState={}

const checkEmailReducer=(state=checkEmailInitialState,action)=>{
    switch(action.type){
      case "EMAIL_VERIFY":{
          return {...action.payload}
      }
      default:{
          return {...state}
      }
  }
}

export default checkEmailReducer