
  
const daywiseOverviewInitialState=[]

const daywiseOverviewReducer=(state=daywiseOverviewInitialState,action)=>{
    switch(action.type){
      case "DAYWISE_OVERVIEW_DATA":{
          return [...action.payload]
      }
      default:{
          return [...state]
      }
  }
}

export default daywiseOverviewReducer