import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { updateOnboardStatus } from "./ApiCalls";

import {CmContext} from "./../../App";
import Swal from "sweetalert2";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function InactiveCommentPP({ open, setOpen }) {
  //   const handleOpen = () => setOpen(true);
  const [context,setContext]=React.useContext(CmContext);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enter Comment
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <textarea
              style={{ width: "100%" }}
              onChange={(e) => {
                console.log(e.target.value);
                context.updateOnboardStatusData.comment=e.target.value;
                setContext({...context});
              }}
            ></textarea>
          </Typography>
          <button
            onClick={() => {
              if(context.updateOnboardStatusData.comment.length<1){
                handleClose();
                Swal.fire({
                  icon:"warning",
                  title:"Empty Comment"
                })
              }else{
                handleClose();
                console.log("inactive comment popup" , context.updateOnboardStatusData)
                updateOnboardStatus(
                  context.updateOnboardStatusData.fse_user_id,
                  context.updateOnboardStatusData.hr_admin_id,
                  context.updateOnboardStatusData.comment,
                  context.updateOnboardStatusData.action_type
                );
              }
            }}
          >
            Save
          </button>
        </Box>
      </Modal>
    </div>
  );
}
