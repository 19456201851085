  
const asmCheckinInitialState=[]

const asmCheckinReducer=(state=asmCheckinInitialState,action)=>{
    switch(action.type){
      case "ASM_CHECKIN_DATA":{
          return [...action.payload]
      }
      default:{
          return [...state]
      }
  }
}

export default asmCheckinReducer