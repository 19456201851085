
  
const fseOverViewInitialState=[]

const fseOverViewReducer=(state=fseOverViewInitialState,action)=>{
    switch(action.type){
      case "FSE_OVERVIEW":{
          return [...action.payload]
      }
      default:{
          return [...state]
      }
  }
}

export default fseOverViewReducer