import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./CmProfile.css";
import axios from "axios";
import stateCity from "../dataset/state_city";
import { checkInvalid, RM_URL } from "../../utils";
import Swal from "sweetalert2";

function CmProfile() {
  const { cmIdForVp } = useParams();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const states = stateCity.map((obj) => obj.state);
  const uniqueStates = [...new Set(states)];
  const sortedStates = uniqueStates.sort();
  const [rmList, setRmList] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const cmId = {
          cm_id: cmIdForVp,
        };
        console.log(cmIdForVp, "cm id data");
        const cmData = await axios
          .post("https://isp.taskmo.in/hr/view_cm_profile", cmId)
          
          .then((res) => {
            console.log(res.data, "success");
            setFormData(res.data.cm_details);
          })
          .catch((err) => {
            console.log(err);
          })
          ;
        // formData.cm_id=cmIdForVp;
        // formData["cm_id"]=[cmIdForVp].toString()
        // setFormData({...formData});

        const res1 = await fetch(RM_URL);
        const res = await res1.json();
        console.log(res);
        if (res.error) {
        } else {
          setRmList([...res.data]);
        }
      } catch (err) {
        console.log(err);
      }
    };
    loadData();
  }, []);
  console.log(formData, "formData ");

  const saveChange = async () => {
    try {
      console.log("something");
      formData.cm_id = cmIdForVp;

      const regex = new RegExp("https://isp.taskmo.in/cm_images/");
      Object.keys(formData).forEach((key) => {
        if (regex.test(formData[key])) {
          formData[key] = formData[key].replace(
            "https://isp.taskmo.in/cm_images/",
            ""
          );
        }
      });
      setFormData({ ...formData });
      setTimeout(() => {
        console.log(formData, "after rearrange");
        const editData = axios
          .post("https://isp.taskmo.in/hr/edit_cm", formData)
          .then((res) => {
            console.log(res.data, "success edit");
            Swal.fire({
              icon: "success",
              title: "saved",
              text: ` Image ${res.data.path}`,
              timer: 700,
              showConfirmButton: false,
              showCancelButton: false,
            });
            setTimeout(() => {
              window.location.reload();
            }, 600);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 500);
    } catch (err) {
      console.log(err);
    }
  };
  const uploadImage = async (e) => {
    try {
      // console.log(e.target.value, "inside adhar front");
      // const axiosData = {
      //   "type" : e.target.name,
      //   "image" : e.target.value
      // }
      const axiosData = new FormData();

      axiosData.append("image", e.target.files[0]);
      axiosData.append("type", `${e.target.name}`);
      // console.log(axiosData, "axiosData,inside");

      const uploadData = await axios
        .post("https://isp.taskmo.in/hr/upload_image", axiosData)
        .then((res) => {
          console.log(res.data.type, "successful");
          console.log(res.data, "successful response");
          formData[e.target.name] = `${res.data.path}`;

          if (res.data.type === "profile_image") {
            formData[
              "profileImage"
            ] = `https://isp.taskmo.in/cm_images/${res.data.path}`;
          }
          if (res.data.type === "aadhar_front_image") {
            formData[
              "aadharFrontImage"
            ] = `https://isp.taskmo.in/cm_images/${res.data.path}`;
          }
          if (res.data.type === "aadhar_back_image") {
            formData[
              "aadharBackImage"
            ] = `https://isp.taskmo.in/cm_images/${res.data.path}`;
          }
          if (res.data.type === "dl_front_image") {
            formData[
              "dlFrontImage"
            ] = `https://isp.taskmo.in/cm_images/${res.data.path}`;
          }
          if (res.data.type === "dl_back_image") {
            formData[
              "dlBackImage"
            ] = `https://isp.taskmo.in/cm_images/${res.data.path}`;
          }
          if (res.data.type === "bank_statement_image") {
            formData[
              "bankStatementImage"
            ] = `https://isp.taskmo.in/cm_images/${res.data.path}`;
          }
          if (res.data.type === "pan_image") {
            formData[
              "panImage"
            ] = `https://isp.taskmo.in/cm_images/${res.data.path}`;
          }
          setFormData({ ...formData });
          Swal.fire({
            icon: "success",
            title: "Uploaded",
            text: ` Image ${res.data.path}`,
            timer: 700,
            showConfirmButton: false,
            showCancelButton: false,
          });
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Upload Error",
            text: ` Image uploading failed try again`,
            timer: 700,
            showConfirmButton: false,
            showCancelButton: false,
          });
        });
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Upload Error",
        text: ` Image uploading failed try again`,
        timer: 700,
        showConfirmButton: false,
        showCancelButton: false,
      });
    }
  };
  const ImageUpload = (e) => {
    const fileSelect = document.getElementById("fileSelect"),
      fileElem = document.getElementById("fileElem");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem) {
          fileElem.click();
          // setFormData()
        }
      },
      false
    );
  };
  const ImageUpload1 = (e) => {
    const fileSelect = document.getElementById("fileSelect1"),
      fileElem = document.getElementById("fileElem1");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem) {
          fileElem.click();
          // setFormData()
        }
      },
      false
    );
  };
  const ImageUpload2 = (e) => {
    const fileSelect = document.getElementById("fileSelect2"),
      fileElem = document.getElementById("fileElem2");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem) {
          fileElem.click();
          // setFormData()
        }
      },
      false
    );
  };
  const ImageUpload3 = (e) => {
    const fileSelect = document.getElementById("fileSelect3"),
      fileElem = document.getElementById("fileElem3");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem) {
          fileElem.click();
          // setFormData()
        }
      },
      false
    );
  };
  const ImageUpload4 = (e) => {
    const fileSelect = document.getElementById("fileSelect4"),
      fileElem = document.getElementById("fileElem4");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem) {
          fileElem.click();
          // setFormData()
        }
      },
      false
    );
  };
  const ImageUpload5 = (e) => {
    const fileSelect = document.getElementById("fileSelect5"),
      fileElem = document.getElementById("fileElem5");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem) {
          fileElem.click();
          // setFormData()
        }
      },
      false
    );
  };
  const ImageUpload6 = (e) => {
    const fileSelect = document.getElementById("fileSelect6"),
      fileElem = document.getElementById("fileElem6");
    fileSelect.addEventListener(
      "click",
      function (e) {
        if (fileElem) {
          fileElem.click();
          // setFormData()
        }
      },
      false
    );
  };

  const handleOnboard = async () => {
    try {
      const hrId = localStorage.getItem("hr_admin_id");
      const onboardData = {
        cm_id: cmIdForVp,
        onboarded_by: hrId,
      };
      console.log(onboardData, "hr_id");
      const onboardActive = await axios
        .post("https://isp.taskmo.in/hr/onboard_cm", onboardData)
        .then((res) => {
          console.log(res.data, "success");
          Swal.fire({
            icon: "success",
            title: "Onboarded",
            showConfirmButton: false,
            timer: 700,
          });
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className="addcm-container"
      // style={{
      //   pointerEvents: `${
      //     Object.keys(formData).length > 2 &&
      //     formData.onboard_status !== "onboarded" ? (
      //       "all"
      //     ) : "none"
      //   }`,
      // }}
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col mt-2">
            <p class="heading-city-manager">City Manager Profile &#x3A;</p>
          </div>
        </div>
        <div class="row">
          <div className="col-2 mt-5">
            <input
              type="file"
              id="fileElem6"
              accept="image/*"
              style={{ display: "none" }}
              name="profile_image"
              // value={formData.profileImage}
              onChange={(value) => {
                uploadImage(value);
              }}
            />
            <div
              className="documents-box"
              id="fileSelect6"
              onClick={ImageUpload6}
            >
              <div className="pic-container">
                <img
                  src={
                    !formData.profileImage
                      ? formData.profile_image
                      : formData.profileImage
                  }
                  alt="profileImage"
                  className="image_upload_profile"
                />
              </div>
            </div>
          </div>
          <div className="col-10 border-form">
            <div className="form-container">
              <form className="form-details">
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Name">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder={formData.full_name}
                    onChange={(e) => {
                      formData.full_name = e.target.value.trim();
                      setFormData({ ...formData });
                    }}
                  />
                </div>
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Contact number">
                    Contact number
                  </label>
                  <input
                    type="number"
                    className="form-control property-box"
                    placeholder={formData.mobile_number}
                    onChange={(e) => {
                      formData.mobile_number = e.target.value.trim();
                      setFormData({ ...formData });
                    }}
                  />
                </div>
                {/* ssecond input row */}
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Email ID">
                    Email ID
                  </label>
                  <input
                    type="email"
                    className="form-control property-box"
                    placeholder={formData.email_id}
                    onChange={(e) => {
                      formData.email_id = e.target.value.trim();
                      setFormData({ ...formData });
                    }}
                  />
                </div>
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Choose your State">
                    Choose your State
                  </label>
                  <select
                    className="form-control property-box"
                    // placeholder="State"
                    onChange={(e) => {
                      formData.state = e.target.value;
                      setFormData({ ...formData });
                    }}
                  >
                    <option
                      className="selectPlaceholder"
                      value=""
                      disabled
                      selected
                      hidden
                    >
                      {formData.state}
                    </option>
                    {sortedStates.map((el, idx) => {
                      return (
                        <option key={idx} value={el}>
                          {el}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {/* third input row */}
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor=" Choose your City">
                    Choose your City
                  </label>
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder={formData.city}
                    onChange={(e) => {
                      formData.city = e.target.value;
                      setFormData({ ...formData });
                    }}
                  />
                </div>
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Pincode">
                    Pincode
                  </label>
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder={formData.pincode}
                    onChange={(e) => {
                      formData.pincode = e.target.value;
                      setFormData({ ...formData });
                    }}
                  />
                </div>
                {/* fourth input row */}
                {/* <div className="col-6 input-field">
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder="Date of onboarding"
                  />
                </div> */}
                
                {/* fifth inpu row */}
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Aadhar Number">
                    Aadhar Number
                  </label>
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder={formData.aadhar_number}
                    onChange={(e) => {
                      formData.aadhar_number = e.target.value.trim();
                      setFormData({ ...formData });
                    }}
                  />
                </div>
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="PAN Number">
                    PAN Number
                  </label>
                  <input
                    type="text"
                    className="form-control property-box"
                    placeholder={formData.pan_number}
                    onChange={(e) => {
                      formData.pan_number = e.target.value.trim();
                      setFormData({ ...formData });
                    }}
                  />
                </div>
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Date of Joining">
                    Date of Joining (dd/mm/yyyy)
                  </label>
                  <input
                    type="text"
                    className="form-control property-box"
                    // placeholder={formData.doj}
                    // onChange={(e) => {
                    //   formData.doj = e.target.value.trim();
                    //   setFormData({ ...formData });
                    // }}
                    placeholder={`${checkInvalid(formData.doj)?``:`${formData.doj.toLowerCase().trim()=="invalid date"?"dd/mm/yyyy":`${formData.doj.substring(
                      8,
                      10
                    )}/${formData.doj.substring(5, 7)}/${formData.doj.substring(
                      0,
                      4
                    )}`}`}`}
                    onChange={(e) => {
                      // formData.doj = e.target.value.trim();
                      const myDate= e.target.value.trim();
                      const arrangedDate=`${myDate.substring(6,10)}-${myDate.substring(3,5)}-${myDate.substring(0,2)}`;
                      formData.doj=arrangedDate;
                      setFormData({ ...formData });
                    }}
                  />
                </div>
                {/* Sixth input row */}
                <div className="col-6 input-field">
                  <label className="cm_label" htmlFor="Regional manager">
                    Regional manager
                  </label>
                  <select
                    list="datalistOptions"
                    id="exampleDataList"
                    type="text"
                    className="form-control property-box"
                    placeholder={formData.rm_name}
                    onChange={(e) => {
                      if (e.target.value) {
                        const id = e.target.value;
                        formData.rm_id = id;
                        setFormData({ ...formData });
                      }
                      setTimeout(() => {
                        console.log(formData);
                      }, 500);
                    }}
                  >
                    <option selected disabled>
                      {formData.rm_name}
                    </option>
                    {rmList.map((obj, idx) => {
                      return (
                        <option value={obj.rm_id} key={obj.rm_id}>
                          {obj.rm_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </form>
            </div>
            {/* Uploading Images */}
            <div className="row mt-3 border-form mt-5">
              <div className="border-box1">
                <p className="need-opacity mt-3">Aadhar Images</p>
                <div className="documents-box">
                  <div>
                    <input
                      type="file"
                      id="fileElem"
                      accept="image/*"
                      style={{ display: "none" }}
                      name="aadhar_front_image"
                      // value={formData.aadharFrontImage}
                      onChange={(value) => {
                        uploadImage(value);
                      }}
                    />

                    <div
                      className="addcm_flex_col"
                      id="fileSelect"
                      onClick={ImageUpload}
                    >
                      <p>Aadhar front image</p>
                      <div className={`myRowCenter`}>
                        <img
                          src={
                            !formData.aadharFrontImage
                              ? formData.aadhar_front_image
                              : formData.aadharFrontImage
                          }
                          alt="aadharFront"
                          className="image_upload_api"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      type="file"
                      id="fileElem1"
                      accept="image/*"
                      style={{ display: "none" }}
                      name="aadhar_back_image"
                      // value={formData.aadharBackImage}
                      onChange={(value) => {
                        uploadImage(value);
                      }}
                    />

                    <div
                      className="addcm_flex_col"
                      id="fileSelect1"
                      onClick={ImageUpload1}
                    >
                      <p>Aadhar back image</p>
                      <div className={`myRowCenter `}>
                        <img
                          src={
                            !formData.aadharBackImage
                              ? formData.aadhar_back_image
                              : formData.aadharBackImage
                          }
                          alt="aadharBack"
                          className="image_upload_api"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3 border-form mt-5">
              <div className="border-box1">
                <p className="need-opacity mt-3">DL Images</p>
                <div className="documents-box">
                  <div>
                    <input
                      type="file"
                      id="fileElem2"
                      accept="image/*"
                      style={{ display: "none" }}
                      name="dl_front_image"
                      // value={formData.dlFrontImage}
                      onChange={(value) => {
                        uploadImage(value);
                      }}
                    />

                    <div
                      className="addcm_flex_col"
                      id="fileSelect2"
                      onClick={ImageUpload2}
                    >
                      <p>DL front image</p>
                      <div className={`myRowCenter `}>
                        <img
                          src={
                            !formData.dlFrontImage
                              ? formData.dl_front_image
                              : formData.dlFrontImage
                          }
                          alt="dlFront"
                          className="image_upload_api"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      type="file"
                      id="fileElem3"
                      accept="image/*"
                      style={{ display: "none" }}
                      name="dl_back_image"
                      // value={formData.dlBackImage}
                      onChange={(value) => {
                        uploadImage(value);
                      }}
                    />

                    <div
                      className="addcm_flex_col"
                      id="fileSelect3"
                      onClick={ImageUpload3}
                    >
                      <p>DL back image</p>
                      <div className={`myRowCenter `}>
                        <img
                          src={
                            !formData.dlBackImage
                              ? formData.dl_back_image
                              : formData.dlBackImage
                          }
                          alt="dl_back"
                          className="image_upload_api"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3 border-form mt-5">
              <div className="border-box1">
                <p className="need-opacity mt-3">Bank Statement And PAN</p>
                <div className="documents-box">
                  <div>
                    <input
                      type="file"
                      id="fileElem4"
                      accept="image/*"
                      style={{ display: "none" }}
                      name="bank_statement_image"
                      // value={formData.bankImage}
                      onChange={(value) => {
                        uploadImage(value);
                      }}
                    />

                    <div
                      className="addcm_flex_col"
                      id="fileSelect4"
                      onClick={ImageUpload4}
                    >
                      <p>Bank Statement</p>
                      <div className={`myRowCenter `}>
                        <img
                          src={
                            !formData.bankStatementImage
                              ? formData.bank_statement_image
                              : formData.bankStatementImage
                          }
                          alt="bankStatement"
                          className="image_upload_api"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      type="file"
                      id="fileElem5"
                      accept="image/*"
                      style={{ display: "none" }}
                      name="pan_image"
                      // value={formData.panImage}
                      onChange={(value) => {
                        uploadImage(value);
                      }}
                    />

                    <div
                      className="addcm_flex_col"
                      id="fileSelect5"
                      onClick={ImageUpload5}
                    >
                      <p>PAN</p>
                      <div className={`myRowCenter `}>
                        <img
                          src={
                            !formData.panImage
                              ? formData.pan_image
                              : formData.panImage
                          }
                          alt="panImage"
                          className="image_upload_api"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="asm_button_row">
            <div className="asm_button_col1">
              {Object.keys(formData).length > 2 &&
              formData.onboard_status !== "onboarded" ? (
                <div className="form_submit_button" id="show_onboard">
                  <button onClick={handleOnboard}>Onboard</button>
                </div>
              ) : null}
            </div>
            <div className="asm_button_col2">
              <button onClick={saveChange}>save change</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CmProfile;
